import Pagination from '@mui/material/Pagination'

const PagnationComponent = ({ page, pages, func }) => {
    return (
        pages > 1 && (
            <div className="pagination-container">
                <Pagination
                    page={page}
                    count={pages}
                    color="primary"
                    onChange={(event, value) => func(value)}
                />
            </div>
        )
    )
}

export default PagnationComponent
