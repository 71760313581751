import React, { useCallback, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Paper from '@mui/material/Paper'
import EditIcon from '@mui/icons-material/Edit'
import DeleteForeverIcon from '@mui/icons-material/DeleteForever'
import { styled } from '@mui/material/styles'
import { roleListId, roleType } from '../../config/roleList'
import { deleteProductStyle } from '../../store/actions/productStyleAction'
import { DELETE_CONFIRM } from '../../constants/form'
import BasicModal from '../modal/BasicModal'
import Edit from './Edit'
import { checkUserRole } from '../../common/checkUserRole'

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
}))

const ProductStyleList = ({ productStyle }) => {
    const dispatch = useDispatch()
    const { userInfo } = useSelector((state) => state.auth)
    const { roleList } = useSelector((state) => state.user)
    const [editOpen, setEditOpen] = useState(false)
    const handleDelete = useCallback(
        (id) => {
            if (window.confirm(DELETE_CONFIRM)) {
                dispatch(deleteProductStyle(id))
            }
        },
        [dispatch]
    )
    return (
        <>
            <BasicModal open={editOpen} setOpen={setEditOpen}>
                <Edit id={productStyle.id} setOpen={setEditOpen} />
            </BasicModal>
            <Item>
                <div
                    style={{
                        display: 'flex',

                        alignItems: 'center',
                    }}
                >
                    <span style={{ marginRight: '20px' }}>
                        {productStyle?.name}
                    </span>
                    <EditIcon
                        onClick={() =>
                            checkUserRole(
                                userInfo,
                                roleList,
                                roleListId.PRODUCT_STYLE,
                                roleType.EDIT
                            )
                                ? setEditOpen(true)
                                : alert('Not Permit User')
                        }
                        style={{
                            marginRight: '20px',
                            cursor: 'pointer',
                            fontSize: '15px',
                        }}
                    />
                    <DeleteForeverIcon
                        onClick={() =>
                            checkUserRole(
                                userInfo,
                                roleList,
                                roleListId.PRODUCT_STYLE,
                                roleType.DELETE
                            )
                                ? handleDelete(productStyle.id)
                                : alert('Not Permit User')
                        }
                        style={{
                            color: 'red',
                            cursor: 'pointer',
                            fontSize: '15px',
                        }}
                    />
                </div>
                <div
                    style={{
                        textAlign: 'left',
                        marginTop: '10px',
                        fontSize: '10px',
                        color: 'grey',
                    }}
                >
                    {productStyle?.description}
                </div>
            </Item>
        </>
    )
}

export default ProductStyleList
