import { useEffect } from 'react'
import { Button, Grid, TextField } from '@mui/material'
import { useState } from 'react'
import { useDispatch } from 'react-redux'
import { SEARCH_SHIPMENTS } from '../../store/types'

const SearchShipment = ({ displayKeyword }) => {
    const dispatch = useDispatch()
    const [keyword, setKeyword] = useState('')

    useEffect(() => {
        setKeyword(displayKeyword ?? '')
    }, [displayKeyword])
    const handleSubmit = (e) => {
        e.preventDefault()
        dispatch({
            type: SEARCH_SHIPMENTS,
            payload: {
                keyword,
            },
        })
    }
    return (
        <div className="search-container">
            <form onSubmit={handleSubmit} noValidate autoComplete="off">
                <Grid container justify="center" alignItems="center">
                    <Grid item xs={6}>
                        <TextField
                            // variant="outlined"
                            fullWidth
                            size="small"
                            id="keyword"
                            label="Search..."
                            value={keyword}
                            onChange={(e) => setKeyword(e.target.value)}
                        />
                    </Grid>
                    <Grid item style={{ marginLeft: '10px' }}>
                        <Button
                            variant="outlined"
                            color="primary"
                            type="submit"
                        >
                            Search
                        </Button>
                    </Grid>
                </Grid>
            </form>
        </div>
    )
}

export default SearchShipment
