import { useEffect, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import Layout from '../../components/Layout'
import { CHANGE_PURCHASE_ORDERS_PAGE } from '../../store/types'
import PagnationComponent from '../../components/PagnationComponent'
import { Button, Container, Typography } from '@mui/material'
import { checkUserRole } from '../../common/checkUserRole'
import { roleListId, roleType } from '../../config/roleList'
import SearchPurchaseOrder from '../../components/purchaseOrder/SearchPurchaseOrder'
import PurchaseOrderInfos from '../../components/purchaseOrder/PurchaseOrderInfos'
import {
    getAllPurchaseOrderSuppliers,
    getAllPurchaseOrders,
} from '../../store/actions/purchaseOrderAction'

const PurchaseOrders = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const purchaseOrderState = useSelector((state) => state.purchaseOrder)
    const { purchaseOrders, pages, page, count, keyword } = purchaseOrderState
    const { userInfo } = useSelector((state) => state.auth)
    const { roleList } = useSelector((state) => state.user)

    const pageNumber = page || 1
    useEffect(() => {
        dispatch(getAllPurchaseOrders(keyword, pageNumber))
    }, [dispatch, keyword, pageNumber])

    useEffect(() => {
        dispatch(getAllPurchaseOrderSuppliers())
    }, [dispatch])

    const changePage = useCallback(
        (page) => {
            dispatch({ type: CHANGE_PURCHASE_ORDERS_PAGE, payload: page })
        },
        [dispatch]
    )
    return (
        <Layout>
            <Container>
                <Button
                    color="info"
                    variant="outlined"
                    onClick={() =>
                        checkUserRole(
                            userInfo,
                            roleList,
                            roleListId.PURCHASE_ORDER,
                            roleType.CREATE
                        )
                            ? navigate(`/addpurchaseorder`)
                            : alert('Not Permit User')
                    }
                >
                    Add New Purchase Order
                </Button>
                <SearchPurchaseOrder displayKeyword={keyword} />
                <Typography
                    variant="body2"
                    gutterBottom
                    style={{ paddingBottom: '10px', paddingTop: '10px' }}
                >
                    Count: {count}
                </Typography>
                {purchaseOrders && (
                    <PurchaseOrderInfos purchaseOrders={purchaseOrders} />
                )}
                <PagnationComponent
                    page={pageNumber}
                    pages={pages}
                    func={changePage}
                />
            </Container>
        </Layout>
    )
}

export default PurchaseOrders
