import {
    CHANGE_DEFECTIVE_ITEMS_PAGE,
    GET_ALL_DEFECTIVE_ITEMS,
    GET_ALL_DEFECTIVE_ITEM_SUPPLIERS,
    GET_DEFECTIVE_ITEM_SEARCH_ITEMS,
    SEARCH_DEFECTIVE_ITEMS,
} from '../types'

const initialState = {
    defectiveItems: null,
    searchItems: [],
    suppliers: [],
    pages: '',
    page: '',
    count: '',
    keyword: '',
}

export default function defectiveItemReducer(state = initialState, action) {
    const { type, payload } = action
    switch (type) {
        case GET_ALL_DEFECTIVE_ITEMS:
            return {
                ...state,
                defectiveItems: payload.defectiveItems,
                pages: payload.pages,
                page: payload.page,
                count: payload.count,
                keyword: payload.keyword,
            }
        case SEARCH_DEFECTIVE_ITEMS:
            return {
                ...state,
                keyword: payload.keyword,
                page: 1,
            }
        case CHANGE_DEFECTIVE_ITEMS_PAGE:
            return {
                ...state,
                page: payload,
            }
        case GET_DEFECTIVE_ITEM_SEARCH_ITEMS:
            return {
                ...state,
                searchItems: payload,
            }
        case GET_ALL_DEFECTIVE_ITEM_SUPPLIERS:
            return {
                ...state,
                suppliers: payload,
            }
        default:
            return state
    }
}
