import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import FormGroup from '@mui/material/FormGroup'
import FormControlLabel from '@mui/material/FormControlLabel'
import Switch from '@mui/material/Switch'
import { Container } from '@mui/material'
import Layout from '../../components/Layout'
import { getItemAnalyticsAction } from '../../store/actions/itemAction'
import ItemDataList from '../../components/item/ItemDataList'

const SearchComponent = ({
    filterStockAlertItems,
    setFilterStockAlertItems,
}) => {
    return (
        <div className="search-container">
            <FormGroup>
                <FormControlLabel
                    control={
                        <Switch
                            checked={filterStockAlertItems}
                            onChange={(event) =>
                                setFilterStockAlertItems(event.target.checked)
                            }
                        />
                    }
                    label="Only show stock alert items"
                />
            </FormGroup>
        </div>
    )
}

const ItemAnalytics = () => {
    const dispatch = useDispatch()
    const { itemAnalytics } = useSelector((state) => state.item)

    const [filterStockAlertItems, setFilterStockAlertItems] = useState(false)

    useEffect(() => {
        dispatch(getItemAnalyticsAction({ filterStockAlertItems }))
    }, [dispatch, filterStockAlertItems])

    return (
        <Layout>
            <Container>
                <SearchComponent
                    filterStockAlertItems={filterStockAlertItems}
                    setFilterStockAlertItems={setFilterStockAlertItems}
                />
                {itemAnalytics?.length === 0 && 'No Data'}
                {itemAnalytics?.length > 0 && (
                    <ItemDataList itemAnalytics={itemAnalytics} />
                )}
            </Container>
        </Layout>
    )
}

export default ItemAnalytics
