import React from 'react'
import { useFormikContext } from 'formik'
import { TextField } from '@mui/material'

const AppInput = ({ name, type, label, required, ...otherProps }) => {
    const { setFieldTouched, handleChange, values, errors, touched } =
        useFormikContext()
    return (
        <div className="form-input">
            <TextField
                fullWidth
                error={touched[name] && errors[name] ? true : false}
                required={required}
                name={name}
                type={type}
                id={name}
                label={label}
                variant="standard"
                value={values[name]}
                onChange={handleChange(name)}
                onBlur={() => setFieldTouched(name)}
                helperText={touched[name] && errors[name] ? errors[name] : null}
                {...otherProps}
            />
        </div>
    )
}

export default AppInput
