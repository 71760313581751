import React from 'react'
import Layout from '../components/Layout'

const Main = () => {
    return (
        <Layout>
            <div></div>
        </Layout>
    )
}

export default Main
