export const roleListId = {
    LOG: 'A000',
    USER: 'A001',
    DOOR_CATEGORY: 'A002',
    ROLES: 'A003',
    SUPPLIER: 'A004',
    SUPPLIER_CONTACT: 'A005',
    ITEM: 'A006',
    PRODUCT_CATEGORY: 'A007',
    PRODUCT_STYLE: 'A008',
    DOOR_STYLE: 'A009',
    DOOR_COLOR: 'A010',
    PRODUCT: 'A011',
    PRODUCT_COLOR: 'A012',
    CUSTOMER: 'A013',
    ORDER: 'A014',
    WAREHOUSE: 'A015',
    BASE_TAX: 'A016',
    PURCHASE_ORDER: 'A017',
    SHIPMENT: 'A018',
    DEFECTIVE_ITEM: 'A019',
    ORDER_PAYMENT: 'A020',
    ORDER_SHIPMENT: 'A021',
    WAREHOUSE_MAP: 'A022',
    ITEM_ANALYTICS: 'A023',
    RETURN_ORDER: 'A024',
}

export const roleNameList = {
    A000: 'Logs',
    A001: 'User',
    A002: 'Door Category',
    A003: 'Roles',
    A004: 'Supplier',
    A005: 'Suppler Contact',
    A006: 'Item',
    A007: 'Product Category',
    A008: 'Product Style',
    A009: 'Door Style',
    A010: 'Door Color',
    A011: 'Product',
    A012: 'Product Color',
    A013: 'Customer',
    A014: 'Order',
    A015: 'Warehouse',
    A016: 'Base Tax',
    A017: 'Purchase Order',
    A018: 'Shipment',
    A019: 'Defective Item',
    A020: 'Order Payment',
    A021: 'Order Shipment',
    A022: 'Warehouse Map',
    A023: 'Item Analytics',
    A024: 'Return Order',
}

export const roleType = {
    READ: 'readRole',
    CREATE: 'createRole',
    EDIT: 'editRole',
    DELETE: 'deleteRole',
    APPROVAL: 'approvalRole',
}

export const roleTypeName = {
    readRole: 'Read',
    createRole: 'Create',
    editRole: 'Edit',
    deleteRole: 'Delete',
    approvalRole: 'Approval',
}
