import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Paper from '@mui/material/Paper'
import BasicModal from '../modal/BasicModal'
import ShipmentItemForm from './ShipmentItemForm'
import { formTypeList } from '../../constants/form'
import { roleListId, roleType } from '../../config/roleList'
import { checkUserRole } from '../../common/checkUserRole'
import { Button } from '@mui/material'

const ItemList = ({ item, shipmentId }) => {
    const [open, setOpen] = useState(false)
    const { userInfo } = useSelector((state) => state.auth)
    const { roleList } = useSelector((state) => state.user)
    return (
        <TableRow>
            <TableCell>{item?.purchaseorderitem?.color}</TableCell>
            <TableCell>{item?.purchaseorderitem?.sku}</TableCell>
            <TableCell>{item?.item?.itemSKU}</TableCell>
            <TableCell>{item.quantity}</TableCell>
            <TableCell>{item.stock}</TableCell>
            <TableCell>
                {item?.stock ? (
                    <>
                        <BasicModal open={open} setOpen={setOpen}>
                            <ShipmentItemForm
                                formType={formTypeList.EDIT}
                                shipmentId={shipmentId}
                                setOpen={setOpen}
                                propsValue={item}
                            />
                        </BasicModal>
                        <Button
                            size="small"
                            variant="outlined"
                            color="secondary"
                            onClick={() =>
                                checkUserRole(
                                    userInfo,
                                    roleList,
                                    roleListId.SHIPMENT,
                                    roleType.EDIT
                                )
                                    ? setOpen(true)
                                    : alert('Not Permit User')
                            }
                        >
                            Edit
                        </Button>
                    </>
                ) : (
                    <>
                        <BasicModal open={open} setOpen={setOpen}>
                            <ShipmentItemForm
                                formType={formTypeList.NEW}
                                shipmentId={shipmentId}
                                setOpen={setOpen}
                                propsValue={item}
                            />
                        </BasicModal>
                        <Button
                            size="small"
                            variant="outlined"
                            color="primary"
                            onClick={() =>
                                checkUserRole(
                                    userInfo,
                                    roleList,
                                    roleListId.SHIPMENT,
                                    roleType.CREATE
                                )
                                    ? setOpen(true)
                                    : alert('Not Permit User')
                            }
                        >
                            Receive
                        </Button>
                    </>
                )}
            </TableCell>
        </TableRow>
    )
}

const ShipmentItemList = ({ displayData, shipmentId }) => {
    return (
        <div style={{ marginTop: '30px' }}>
            <TableContainer component={Paper}>
                <Table aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell>Color</TableCell>
                            <TableCell>SKU</TableCell>
                            <TableCell>Item SKU</TableCell>
                            <TableCell>Shipped Qty</TableCell>
                            <TableCell>Received Qty</TableCell>
                            <TableCell></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {displayData?.map((item, index) => (
                            <ItemList
                                item={item}
                                key={index}
                                shipmentId={shipmentId}
                            />
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    )
}

export default ShipmentItemList
