import React from 'react'
import { useFormikContext } from 'formik'
import Select from 'react-select'
import InputLabel from '@mui/material/InputLabel'
import FormHelperText from '@mui/material/FormHelperText'
import { useColorScheme } from '@mui/material/styles'

const AppReactSelect = ({ name, label, options, ...otherProps }) => {
    const { setFieldTouched, setFieldValue, values, errors, touched } =
        useFormikContext()

    const selectedOptions = options?.filter((option) =>
        values[name].includes(option.id)
    )

    const handleChange = (selectedOptions) => {
        const selectedIds = selectedOptions?.map((option) => option.id)
        setFieldValue(name, selectedIds)
    }

    const handleBlur = () => {
        setFieldTouched(name, true)
    }

    const { mode } = useColorScheme()

    const isDarkMode = mode === 'dark'

    const customStyles = {
        control: (provided) => ({
            ...provided,
            height: '50px',
            marginTop: '10px',
            backgroundColor: isDarkMode ? '#121212' : '#FFFFFF', // Dark gray or light gray
            color: isDarkMode ? '#f4f4f4' : '#444', // Light gray or dark gray
        }),
        menu: (provided) => ({
            ...provided,
            backgroundColor: isDarkMode ? '#333' : '#FFFFFF', // Dark gray or light gray
            color: isDarkMode ? '#f4f4f4' : '#333', // Light gray or dark gray
        }),
        option: (provided, state) => ({
            ...provided,
            backgroundColor: state.isFocused
                ? isDarkMode
                    ? '#121212' // Darker gray for selected option
                    : '#cccccc' // Lighter gray for selected option
                : null,
            color: state.isFocused ? (isDarkMode ? '#f4f4f4' : '#444') : null,
        }),
    }

    return (
        <div className="form-input">
            <InputLabel id={`select-label-${name}`}>{label}</InputLabel>
            <Select
                labelId={`select-label-${name}`}
                fullWidth
                styles={customStyles}
                isMulti
                error={touched[name] && errors[name] ? true : false}
                name={name}
                options={options}
                id={name}
                value={selectedOptions}
                getOptionValue={(option) => option.id}
                getOptionLabel={(option) => option.name}
                variant="standard"
                onChange={handleChange}
                onBlur={handleBlur}
                {...otherProps}
            />
            <FormHelperText style={{ color: 'red' }}>
                {touched[name] && errors[name] ? errors[name] : null}
            </FormHelperText>
        </div>
    )
}

export default AppReactSelect
