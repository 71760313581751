import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import Layout from '../../components/Layout'
import { Button, Container, Grid } from '@mui/material'
import { getOnePurchaseOrder } from '../../store/actions/purchaseOrderAction'
import PurchaseOrderItemsUpload from '../../components/purchaseOrder/PurchaseOrderItemsUpload'
import PurchaseOrderItemList from '../../components/purchaseOrder/PurchaseOrderItemList'

const PurchaseOrder = () => {
    const { id } = useParams()
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const { purchaseOrder } = useSelector((state) => state.purchaseOrder)

    useEffect(() => {
        dispatch(getOnePurchaseOrder(id))
    }, [dispatch, id])

    return (
        <Layout>
            {purchaseOrder ? (
                <Container>
                    <Button onClick={() => navigate(-1)}>Back</Button>
                    <div style={{ marginTop: '20px' }} />
                    <Grid container rowSpacing={2}>
                        <Grid item xs={4}>
                            Supplier:
                        </Grid>
                        <Grid item xs={8}>
                            {purchaseOrder?.supplier?.name}
                        </Grid>
                        <Grid item xs={4}>
                            Order No:
                        </Grid>
                        <Grid item xs={8}>
                            {purchaseOrder.orderId}
                        </Grid>
                        <Grid item xs={4}>
                            Invoice No:
                        </Grid>
                        <Grid item xs={8}>
                            {purchaseOrder.invoiceNo}
                        </Grid>
                        <Grid item xs={4}>
                            PO No:
                        </Grid>
                        <Grid item xs={8}>
                            {purchaseOrder.po}
                        </Grid>
                    </Grid>
                    {purchaseOrder?.purchaseorderitems?.length > 0 && (
                        <div style={{ marginTop: '30px' }}>
                            <Button
                                variant="contained"
                                onClick={() =>
                                    navigate(`/addshipment/${purchaseOrder.id}`)
                                }
                            >
                                Create Shipment
                            </Button>
                            <Button
                                variant="contained"
                                color="secondary"
                                style={{ marginLeft: '20px' }}
                                onClick={() =>
                                    navigate('/purchaseorderdownload')
                                }
                            >
                                Generate Factory PO
                            </Button>
                        </div>
                    )}
                    {purchaseOrder?.shipments?.length > 0 && (
                        <div style={{ marginTop: '30px' }}>
                            {purchaseOrder?.shipments?.map((shipment) => (
                                <Button
                                    key={shipment.id}
                                    style={{ marginRight: '20px' }}
                                    variant="outlined"
                                    onClick={() =>
                                        navigate(`/shipment/${shipment.id}`)
                                    }
                                >
                                    {shipment.shipmentId}
                                </Button>
                            ))}
                        </div>
                    )}
                    {purchaseOrder?.purchaseorderitems?.length > 0 ? (
                        <PurchaseOrderItemList
                            displayData={purchaseOrder?.purchaseorderitems}
                        />
                    ) : (
                        <PurchaseOrderItemsUpload
                            purchaseOrderId={purchaseOrder.id}
                        />
                    )}
                </Container>
            ) : null}
        </Layout>
    )
}

export default PurchaseOrder
