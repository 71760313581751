import { Button, TableCell, TableRow } from '@mui/material'
import { green, red } from '@mui/material/colors'
import BlockIcon from '@mui/icons-material/Block'
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline'
import { useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { roleListId, roleType } from '../../config/roleList'
import { checkUserRole } from '../../common/checkUserRole'
import { formTypeList } from '../../constants/form'

const SupplierList = ({ supplier }) => {
    const navigate = useNavigate()
    const { userInfo } = useSelector((state) => state.auth)
    const { roleList } = useSelector((state) => state.user)
    return (
        <>
            <TableRow>
                <TableCell>{supplier.name}</TableCell>
                <TableCell>{supplier.email}</TableCell>
                <TableCell>{supplier.phone}</TableCell>
                <TableCell align="center">
                    {supplier.isActive ? (
                        <CheckCircleOutlineIcon style={{ color: green[500] }} />
                    ) : (
                        <BlockIcon style={{ color: red[500] }} />
                    )}
                </TableCell>
                <TableCell>
                    <div className="button-box">
                        <Button
                            size="small"
                            variant="outlined"
                            color="primary"
                            onClick={() =>
                                checkUserRole(
                                    userInfo,
                                    roleList,
                                    roleListId.SUPPLIER,
                                    roleType.READ
                                )
                                    ? navigate(
                                          `/supplier/${supplier.id}/${formTypeList.READ}`
                                      )
                                    : alert('Not Permit User')
                            }
                        >
                            Info
                        </Button>
                        <Button
                            size="small"
                            variant="outlined"
                            color="secondary"
                            onClick={() =>
                                checkUserRole(
                                    userInfo,
                                    roleList,
                                    roleListId.SUPPLIER,
                                    roleType.EDIT
                                )
                                    ? navigate(
                                          `/editsupplier/${supplier.id}/${formTypeList.EDIT}`
                                      )
                                    : alert('Not Permit User')
                            }
                        >
                            Edit
                        </Button>
                    </div>
                </TableCell>
            </TableRow>
        </>
    )
}

export default SupplierList
