import React from 'react'
import { OrderHeader } from '../../pages/order/Order'
import OrderItemInfo from '../order/OrderItemInfo'

const ItemToPrint = React.forwardRef(({ order }, ref) => (
    <div className="invoice-container" ref={ref}>
        <div className="order-products-box">
            <OrderHeader order={order} orderInfo={false} />
            <div style={{ marginTop: '30px' }} />
            <div className="order-product-items">
                <OrderItemInfo order={order} />
            </div>
        </div>
    </div>
))

export default ItemToPrint
