import {
    GET_ALL_WAREHOUSES,
    GET_ONE_WAREHOUSE,
    GET_ONE_WAREHOUSE_MAP,
} from '../types'

const initialState = {
    warehouses: null,
    warehouse: null,
    warehouseMap: null,
}

const warehouseReducer = (state = initialState, action) => {
    const { type, payload } = action
    switch (type) {
        case GET_ALL_WAREHOUSES:
            return {
                ...state,
                warehouses: payload,
            }
        case GET_ONE_WAREHOUSE:
            return {
                ...state,
                warehouse: payload,
            }
        case GET_ONE_WAREHOUSE_MAP:
            return {
                ...state,
                warehouseMap: payload,
            }
        default:
            return state
    }
}

export default warehouseReducer
