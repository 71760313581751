import { USER_LOGOIN_SUCCESS, USER_LOGOUT, USER_UPDATE_TOKEN } from '../types'

const initialState = {
    userInfo: null,
    userToken: null,
}

const authReducer = (state = initialState, action) => {
    const { type, payload } = action
    switch (type) {
        case USER_LOGOIN_SUCCESS:
            return {
                ...state,
                userInfo: payload.userInfo,
                userToken: payload.userToken,
            }
        case USER_UPDATE_TOKEN:
            return { ...state, userToken: payload }
        case USER_LOGOUT:
            return {
                userInfo: null,
                userToken: null,
            }

        default:
            return state
    }
}

export default authReducer
