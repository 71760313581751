import {
    CHANGE_CUSTOMERS_PAGE,
    GET_ALL_CUSTOMERS,
    GET_ONE_CUSTOMER,
    GET_USER_SELECT_LIST,
    SEARCH_CUSTOMERS,
    USER_LOGOUT,
} from '../types'

const initialState = {
    customers: null,
    customer: null,
    userList: null,
    pages: '',
    page: '',
    count: '',
    keyword: '',
}

const customerReducer = (state = initialState, action) => {
    const { type, payload } = action
    switch (type) {
        case GET_ALL_CUSTOMERS:
            return {
                ...state,
                customers: payload.customers,
                pages: payload.pages,
                page: payload.page,
                count: payload.count,
                keyword: payload.keyword,
                customer: null,
            }
        case SEARCH_CUSTOMERS:
            return {
                ...state,
                keyword: payload.keyword,
                page: 1,
            }
        case CHANGE_CUSTOMERS_PAGE:
            return {
                ...state,
                page: payload,
            }
        case GET_ONE_CUSTOMER:
            return {
                ...state,
                customer: payload,
            }
        case GET_USER_SELECT_LIST:
            return {
                ...state,
                userList: payload,
            }
        case USER_LOGOUT:
            return {
                customers: null,
                customer: null,
                userList: null,
                pages: '',
                page: '',
                count: '',
                keyword: '',
            }
        default:
            return state
    }
}

export default customerReducer
