import React from 'react'
import { useFormikContext } from 'formik'
import Select from '@mui/material/Select'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import FormHelperText from '@mui/material/FormHelperText'

const AppSelect = ({
    name,
    label,
    options,
    setDefaultValue,
    ...otherProps
}) => {
    const { setFieldTouched, handleChange, values, errors, touched } =
        useFormikContext()
    return (
        <div className="form-input">
            <InputLabel id={`select-label-${name}`}>{label}</InputLabel>
            <Select
                labelId={`select-label-${name}`}
                fullWidth
                error={touched[name] && errors[name] ? true : false}
                name={name}
                id={name}
                value={values[name]}
                variant="standard"
                onChange={handleChange(name)}
                onBlur={() => setFieldTouched(name)}
                {...otherProps}
            >
                {options?.map((option) => (
                    <MenuItem key={option} value={option}>
                        {option}
                    </MenuItem>
                ))}
            </Select>
            <FormHelperText style={{ color: '#D32E2F' }}>
                {touched[name] && errors[name] ? errors[name] : null}
            </FormHelperText>
        </div>
    )
}

export default AppSelect

export const AppSelectId = ({
    name,
    label,
    options,
    setDefaultValue,
    ...otherProps
}) => {
    const { setFieldTouched, handleChange, values, errors, touched } =
        useFormikContext()
    return (
        <div className="form-input">
            <InputLabel id={`select-label-${name}`}>{label}</InputLabel>
            <Select
                labelId={`select-label-${name}`}
                fullWidth
                error={touched[name] && errors[name] ? true : false}
                name={name}
                id={name}
                value={values[name]}
                variant="standard"
                onChange={handleChange(name)}
                onBlur={() => setFieldTouched(name)}
                {...otherProps}
            >
                {options?.map((option) => (
                    <MenuItem key={option.id} value={option.id}>
                        {option.name}
                    </MenuItem>
                ))}
            </Select>
            <FormHelperText style={{ color: '#D32E2F' }}>
                {touched[name] && errors[name] ? errors[name] : null}
            </FormHelperText>
        </div>
    )
}
