import React from 'react'
import { useFormikContext } from 'formik'
import InputLabel from '@mui/material/InputLabel'
import Checkbox from '@mui/material/Checkbox'

const AppCheckbox = ({ name, label, ...otherProps }) => {
    const { setFieldTouched, handleChange, values } = useFormikContext()
    return (
        <div className="form-input">
            <InputLabel>{label}</InputLabel>
            <Checkbox
                checked={values[name]}
                onChange={handleChange(name)}
                onBlur={() => setFieldTouched(name)}
                {...otherProps}
            />
        </div>
    )
}

export default AppCheckbox
