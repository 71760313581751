import React, { useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { getOneOrder } from '../../store/actions/orderAction'
import { GET_ONE_ORDER } from '../../store/types'
import Layout from '../../components/Layout'
import { Button, Container } from '@mui/material'
import TotalAmount from '../../components/order/TotalAmount'
import { ReviewOrderStyle } from './Order'
import { formTypeList } from '../../constants/form'
import OrderEditList from '../../components/order/OrderEditList'
import OrderLogs from '../../components/order/OrderLogs'
import AdditionalChargeList from '../../components/order/AdditionalChargeList'

const OrderEdit = () => {
    const { id } = useParams()
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const { order } = useSelector((state) => state.order)

    useEffect(() => {
        if (id) dispatch(getOneOrder(id))
        return () => {
            dispatch({ type: GET_ONE_ORDER, payload: null })
        }
    }, [dispatch, id])

    const orderItemList = useMemo(() => {
        const itemIdsSet = new Set()

        const processFinishedEnds = (finishedEnds) => {
            finishedEnds.forEach(({ item }) => {
                if (item?.id) {
                    itemIdsSet.add(item.id)
                }
            })
        }

        const processProductItems = (orderItems) => {
            orderItems.forEach((orderItem) => {
                if (orderItem.isItem && orderItem?.item?.id) {
                    itemIdsSet.add(orderItem.item.id)
                } else if (
                    !orderItem.isItem &&
                    orderItem?.productcolor?.productitems?.length > 0
                ) {
                    orderItem.productcolor.productitems.forEach(
                        (productItem) => {
                            if (productItem?.item?.id) {
                                itemIdsSet.add(productItem.item.id)
                            }
                        }
                    )
                }
            })
        }

        if (order?.orderitemfinishedends?.length > 0) {
            processFinishedEnds(order.orderitemfinishedends)
        }

        if (order?.orderstyles?.length > 0) {
            order.orderstyles.forEach((style) => {
                if (style?.orderitems?.length > 0) {
                    processProductItems(style.orderitems)
                }
            })
        }

        return Array.from(itemIdsSet)
    }, [order])

    return (
        <Layout>
            <Container>
                <div style={{ marginTop: '50px' }} />
                <div className="order-products-box">
                    <Button
                        variant="outlined"
                        color="success"
                        onClick={() => navigate(-1)}
                        style={{ marginBottom: '10px' }}
                    >
                        Back
                    </Button>
                </div>
                <div className="order-product-items">
                    {order?.orderstyles?.map((orderStyle) => (
                        <ReviewOrderStyle
                            key={orderStyle.id}
                            orderStyle={orderStyle}
                            viewType={
                                order?.quoteType === 1
                                    ? formTypeList.EDIT
                                    : formTypeList.READ
                            }
                            orderId={id}
                        />
                    ))}
                </div>
                {order?.orderadditionalcharges?.length > 0 && (
                    <AdditionalChargeList
                        orderAdditionalCharges={order.orderadditionalcharges}
                    />
                )}
                <OrderEditList order={order} orderItemList={orderItemList} />
                <TotalAmount
                    order={order}
                    createInvoiceButton={true}
                    orderItemList={orderItemList}
                />
                <OrderLogs order={order} />
            </Container>
        </Layout>
    )
}

export default OrderEdit
