import React from 'react'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Paper from '@mui/material/Paper'

const ItemList = ({ item }) => (
    <TableRow>
        <TableCell>{item.color}</TableCell>
        <TableCell>{item.sku}</TableCell>
        <TableCell>
            <span style={{ color: 'red' }}>{item.itemSKU}</span>
        </TableCell>
        <TableCell>{item.quantity}</TableCell>
    </TableRow>
)

const MissingItemList = ({ displayData }) => {
    return (
        <TableContainer component={Paper}>
            <Table aria-label="simple table">
                <TableHead>
                    <TableRow>
                        <TableCell>Color</TableCell>
                        <TableCell>SKU</TableCell>
                        <TableCell>Item SKU</TableCell>
                        <TableCell>Qty</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {displayData?.map((item, index) => (
                        <ItemList item={item} key={index} />
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    )
}

export default MissingItemList
