import { Button, TableCell, TableRow } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline'
import BlockIcon from '@mui/icons-material/Block'
import { roleListId, roleType } from '../../config/roleList'
import { checkUserRole } from '../../common/checkUserRole'
import { deleteProduct } from '../../store/actions/productAction'
import { useCallback } from 'react'
import { DELETE_CONFIRM } from '../../constants/form'

const ProductList = ({ product }) => {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const { userInfo } = useSelector((state) => state.auth)
    const { roleList } = useSelector((state) => state.user)

    const handleDelete = useCallback(
        (id) => {
            if (window.confirm(DELETE_CONFIRM)) {
                dispatch(deleteProduct(id))
            }
        },
        [dispatch]
    )

    return (
        <>
            <TableRow>
                <TableCell>
                    {product?.productstyle?.productcategory?.name}
                </TableCell>
                <TableCell>{product?.productstyle?.name}</TableCell>
                <TableCell>{product.name}</TableCell>
                <TableCell>{product.sku}</TableCell>
                <TableCell align="center">
                    {product?.doorDirection ? (
                        <CheckCircleOutlineIcon color="success" />
                    ) : (
                        <BlockIcon color="error" />
                    )}
                </TableCell>
                <TableCell>
                    {product?.assemblyFee
                        ? `$${product?.assemblyFee?.toFixed(2)}`
                        : ''}
                </TableCell>
                <TableCell>
                    <div className="button-box">
                        <Button
                            size="small"
                            variant="outlined"
                            color="primary"
                            onClick={() =>
                                checkUserRole(
                                    userInfo,
                                    roleList,
                                    roleListId.PRODUCT,
                                    roleType.READ
                                )
                                    ? navigate(`/product/${product.id}`)
                                    : alert('Not Permit User')
                            }
                        >
                            Info
                        </Button>
                        <Button
                            size="small"
                            variant="outlined"
                            color="secondary"
                            onClick={() =>
                                checkUserRole(
                                    userInfo,
                                    roleList,
                                    roleListId.PRODUCT,
                                    roleType.EDIT
                                )
                                    ? navigate(`/editproduct/${product.id}`)
                                    : alert('Not Permit User')
                            }
                        >
                            Edit
                        </Button>
                        <Button
                            size="small"
                            variant="outlined"
                            color="error"
                            onClick={() =>
                                checkUserRole(
                                    userInfo,
                                    roleList,
                                    roleListId.PRODUCT,
                                    roleType.DELETE
                                )
                                    ? handleDelete(product.id)
                                    : alert('Not Permit User')
                            }
                        >
                            Delete
                        </Button>
                    </div>
                </TableCell>
            </TableRow>
        </>
    )
}

export default ProductList
