import { useEffect, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Layout from '../components/Layout'
import { CHANGE_CUSTOMERS_PAGE } from '../store/types'
import PagnationComponent from '../components/PagnationComponent'
import { Container, Typography } from '@mui/material'
import CustomerInfos from '../components/customer/CustomerInfo'
import SearchCustomer from '../components/customer/SearchCustomer'
import { getAllCustomers } from '../store/actions/customerAction'

const Customers = () => {
    const dispatch = useDispatch()
    const { customers, pages, page, count, keyword } = useSelector(
        (state) => state.customer
    )

    const pageNumber = page || 1
    useEffect(() => {
        dispatch(getAllCustomers(keyword, pageNumber))
    }, [dispatch, keyword, pageNumber])

    const changePage = useCallback(
        (page) => {
            dispatch({ type: CHANGE_CUSTOMERS_PAGE, payload: page })
        },
        [dispatch]
    )
    return (
        <Layout>
            <Container>
                <SearchCustomer displayKeyword={keyword} />
                <Typography
                    variant="body2"
                    gutterBottom
                    style={{ paddingBottom: '10px', paddingTop: '10px' }}
                >
                    Count: {count}
                </Typography>
                {customers && <CustomerInfos customers={customers} />}
                <PagnationComponent
                    page={pageNumber}
                    pages={pages}
                    func={changePage}
                />
            </Container>
        </Layout>
    )
}

export default Customers
