export const formTypeList = {
    NEW: 'new',
    EDIT: 'edit',
    READ: 'read',
}

export const DELETE_CONFIRM = 'Are you sure?'

export const priceChangeType = {
    ITEM_PRICE: 'itemPrice',
    FINISHED_END_PRICE: 'finishedEndPrice',
    ASSEMBLY_FEE: 'assemblyFee',
}
