import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import Layout from '../../components/Layout'
import { Button, Container, Grid } from '@mui/material'
import { getOneShipment } from '../../store/actions/shipmentAction'
import ShipmentItemList from '../../components/shipment/ShipmentItemList'

const Shipment = () => {
    const { id } = useParams()
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const { shipment } = useSelector((state) => state.shipment)

    useEffect(() => {
        dispatch(getOneShipment(id))
    }, [dispatch, id])

    return (
        <Layout>
            {shipment ? (
                <Container>
                    <Button onClick={() => navigate(-1)}>Back</Button>
                    <div style={{ marginTop: '20px' }} />
                    <Grid container rowSpacing={2}>
                        <Grid item xs={4}>
                            Shipment ID:
                        </Grid>
                        <Grid item xs={8}>
                            {shipment?.shipmentId}
                        </Grid>
                        <Grid item xs={4}>
                            ETD:
                        </Grid>
                        <Grid item xs={8}>
                            {shipment.etd}
                        </Grid>
                        <Grid item xs={4}>
                            ETA:
                        </Grid>
                        <Grid item xs={8}>
                            {shipment.eta}
                        </Grid>
                    </Grid>

                    {shipment?.shipmentorderitems?.length > 0 && (
                        <ShipmentItemList
                            displayData={shipment?.shipmentorderitems}
                            shipmentId={id}
                        />
                    )}
                </Container>
            ) : null}
        </Layout>
    )
}

export default Shipment
