import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Form from './Form'
import { formTypeList } from '../../constants/form'
import { getOneProductCategory } from '../../store/actions/productCategoryAction'
import { GET_ONE_PRODUCT_CATEGORY } from '../../store/types'

const Edit = ({ id, setOpen }) => {
    const dispatch = useDispatch()
    const { productCategory } = useSelector((state) => state.productCategory)
    useEffect(() => {
        dispatch(getOneProductCategory(id))
        return () => {
            dispatch({
                type: GET_ONE_PRODUCT_CATEGORY,
                payload: null,
            })
        }
    }, [dispatch, id])
    return productCategory ? (
        <Form
            formType={formTypeList.EDIT}
            setOpen={setOpen}
            propsValue={productCategory}
        />
    ) : null
}

export default Edit
