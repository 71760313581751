import React, { useState } from 'react'
import { useFormikContext } from 'formik'
import { Button } from '@mui/material'

const SubmitButton = ({ title }) => {
    const { handleSubmit } = useFormikContext()
    const [isButtonDisabled, setIsButtonDisabled] = useState(false)

    const handleButtonClick = () => {
        if (!isButtonDisabled) {
            setIsButtonDisabled(true)
            handleSubmit()
            setIsButtonDisabled(false)
        }
    }

    return (
        <div className="form-submit-button">
            <Button
                variant="contained"
                onClick={handleButtonClick}
                fullWidth
                disabled={isButtonDisabled}
            >
                {title}
            </Button>
        </div>
    )
}

export default SubmitButton
