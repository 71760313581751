import {
    GET_ALL_DOOR_CATEGORY,
    GET_ONE_DOOR_CATEGORY,
    GET_ONE_DOOR_COLOR,
    GET_ONE_DOOR_STYLE,
} from '../types'

const initialState = {
    doorCategories: null,
    doorCategory: null,
    doorStyle: null,
    doorColor: null,
}

const doorCategoryReducer = (state = initialState, action) => {
    const { type, payload } = action
    switch (type) {
        case GET_ALL_DOOR_CATEGORY:
            return {
                ...state,
                doorCategories: payload,
                doorCategory: null,
                doorStyle: null,
                doorColor: null,
            }
        case GET_ONE_DOOR_CATEGORY:
            return {
                ...state,
                doorCategory: payload,
            }
        case GET_ONE_DOOR_STYLE:
            return {
                ...state,
                doorStyle: payload,
            }
        case GET_ONE_DOOR_COLOR:
            return {
                ...state,
                doorColor: payload,
            }
        default:
            return state
    }
}

export default doorCategoryReducer
