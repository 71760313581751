import React, { useState } from 'react'
import { Button, Grid } from '@mui/material'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Paper from '@mui/material/Paper'
import ProductItemList from './ProductItemList'
import { checkUserRole } from '../../common/checkUserRole'
import { roleListId, roleType } from '../../config/roleList'
import BasicModal from '../modal/BasicModal'
import Edit from './Edit'
import { useSelector } from 'react-redux'
import BlockIcon from '@mui/icons-material/Block'
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline'
import { green, red } from '@mui/material/colors'
import AddNew from '../productItem/AddNew'

const ProductColorList = ({ productColor }) => {
    const { userInfo } = useSelector((state) => state.auth)
    const { roleList } = useSelector((state) => state.user)

    const [editOpen, setEditOpen] = useState(false)
    const [itemOpen, setItemOpen] = useState(false)

    return productColor ? (
        <div>
            <BasicModal open={editOpen} setOpen={setEditOpen}>
                <Edit id={productColor.id} setOpen={setEditOpen} />
            </BasicModal>
            <BasicModal open={itemOpen} setOpen={setItemOpen}>
                <AddNew
                    productColorId={productColor.id}
                    setOpen={setItemOpen}
                />
            </BasicModal>
            <Grid container spacing={2}>
                <Grid item xs={2}>
                    SKU NO
                </Grid>
                <Grid item xs={10}>
                    {productColor.skuNo}
                </Grid>
                <Grid item xs={2}>
                    MSRP
                </Grid>
                <Grid item xs={10}>
                    ${productColor.MSRPinCAD}{' '}
                    {productColor?.adjustment &&
                        `( Adjustment ${
                            productColor?.adjustmentType === 1 ? '+' : '-'
                        } $${productColor?.adjustmentAmount} )`}
                </Grid>
                {/* <Grid item xs={2}>
                    MSRP USD
                </Grid>
                <Grid item xs={10}>
                    ${productColor.MSRPinUSD}
                </Grid> */}
                <Grid item xs={2}>
                    Finished End Item
                </Grid>
                <Grid item xs={10}>
                    {productColor?.finishedEnd?.itemSKU}
                </Grid>
                <Grid item xs={2}>
                    Online
                </Grid>
                <Grid item xs={10}>
                    {productColor.online ? (
                        <CheckCircleOutlineIcon style={{ color: green[500] }} />
                    ) : (
                        <BlockIcon style={{ color: red[500] }} />
                    )}
                </Grid>
            </Grid>
            <div style={{ marginTop: '20px' }}>
                <Button
                    size="small"
                    variant="outlined"
                    color="primary"
                    onClick={() =>
                        checkUserRole(
                            userInfo,
                            roleList,
                            roleListId.PRODUCT_COLOR,
                            roleType.EDIT
                        )
                            ? setEditOpen(true)
                            : alert('Not Permit User')
                    }
                    style={{
                        marginRight: '20px',
                        cursor: 'pointer',
                        fontSize: '15px',
                    }}
                >
                    Edit
                </Button>
                <Button
                    size="small"
                    variant="outlined"
                    color="secondary"
                    onClick={() =>
                        checkUserRole(
                            userInfo,
                            roleList,
                            roleListId.PRODUCT_COLOR,
                            roleType.EDIT
                        )
                            ? setItemOpen(true)
                            : alert('Not Permit User')
                    }
                    style={{
                        marginLeft: '20px',
                        marginRight: '20px',
                        cursor: 'pointer',
                        fontSize: '15px',
                    }}
                >
                    Add New Item
                </Button>
            </div>
            <TableContainer component={Paper} style={{ marginTop: '20px' }}>
                <Table aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell>Item SKU</TableCell>
                            <TableCell>Item price</TableCell>
                            <TableCell>Qty</TableCell>
                            <TableCell></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {productColor?.productitems?.map((item) => (
                            <ProductItemList item={item} key={item.id} />
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    ) : null
}

export default ProductColorList
