import React from 'react'
import { formTypeList } from '../../constants/form'
import OrderPaymentForm from './OrderPaymentForm'

const AddNewOrderPayment = ({ setOpen, orderId }) => {
    return (
        <OrderPaymentForm
            formType={formTypeList.NEW}
            setOpen={setOpen}
            orderId={orderId}
        />
    )
}

export default AddNewOrderPayment
