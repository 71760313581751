import React, { useEffect, useState } from 'react'
import { useReactToPrint } from 'react-to-print'
import { Button } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import InvoiceToPrint from '../../components/order/InvoiceToPrint'
import Layout from '../../components/Layout'
import { getOneOrder } from '../../store/actions/orderAction'

const PrintInvoice = () => {
    const { id } = useParams()
    const dispatch = useDispatch()
    const [componentRef, setComponentRef] = useState(null)
    const navigate = useNavigate()

    const { order } = useSelector((state) => state.order)

    useEffect(() => {
        if (id) dispatch(getOneOrder(id))
        window.scrollTo(0, 0)
    }, [dispatch, id])

    const handlePrint = useReactToPrint({
        content: () => componentRef,
        onAfterPrint: () => {
            setComponentRef(null)
            navigate(-1)
        },
    })
    return (
        <Layout>
            <div style={{ marginTop: '20px' }}>
                <Button
                    variant="outlined"
                    color="grey"
                    onClick={() => navigate(-1)}
                >
                    Back
                </Button>
                <Button
                    style={{ marginLeft: '10px' }}
                    variant="outlined"
                    color="primary"
                    onClick={handlePrint}
                >
                    Print
                </Button>
            </div>
            <InvoiceToPrint ref={setComponentRef} order={order} />
        </Layout>
    )
}

export default PrintInvoice
