import React, { useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { Button, Container } from '@mui/material'
import Layout from '../../components/Layout'
import { getCategories, getOneOrder } from '../../store/actions/orderAction'
import OrderStyle from '../../components/order/OrderStyle'
import AddNewStyle from '../../components/order/AddNewStyle'

function ProductOrders() {
    const { id } = useParams()
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const { order } = useSelector((state) => state.order)
    useEffect(() => {
        dispatch(getCategories())
        if (id) dispatch(getOneOrder(id))
    }, [dispatch, id])

    return (
        <Layout>
            <Container>
                <div style={{ marginTop: '50px' }} />
                <div className="order-products-box">
                    <Button
                        variant="outlined"
                        color="grey"
                        onClick={() => navigate(-1)}
                    >
                        Back
                    </Button>
                </div>
                <div className="order-products-box">
                    <div style={{ marginTop: '20px', marginBottom: '20px' }}>
                        <AddNewStyle orderId={order?.id} />
                    </div>
                    {order && (
                        <div className="order-product-items">
                            {order?.orderstyles?.map((orderStyle) => (
                                <OrderStyle
                                    key={orderStyle.id}
                                    orderStyle={orderStyle}
                                    orderId={order.id}
                                />
                            ))}
                        </div>
                    )}
                </div>
            </Container>
        </Layout>
    )
}

export default ProductOrders
