import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Paper from '@mui/material/Paper'
import ProductColorPageList from './ProductColorPageList'

const ProductColorInfo = ({ productColors }) => {
    return (
        <TableContainer component={Paper}>
            <Table aria-label="simple table">
                <TableHead>
                    <TableRow>
                        <TableCell></TableCell>
                        <TableCell>SKU No</TableCell>
                        <TableCell>MSRP</TableCell>
                        {/* <TableCell>MSRP in USD</TableCell> */}
                        <TableCell>Stock</TableCell>
                        <TableCell align="center">Online</TableCell>
                        <TableCell></TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {productColors?.map((productColor) => (
                        <ProductColorPageList
                            productColor={productColor}
                            key={productColor.id}
                        />
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    )
}

export default ProductColorInfo
