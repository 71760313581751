import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { formTypeList } from '../../constants/form'
import { GET_ONE_DOOR_CATEGORY } from '../../store/types'
import { getOneDoorCategory } from '../../store/actions/doorCategoryAction'
import SubmitForm from './SubmitForm'

const Edit = ({ id, setOpen }) => {
    const dispatch = useDispatch()
    const { doorCategory } = useSelector((state) => state.doorCategory)
    useEffect(() => {
        dispatch(getOneDoorCategory(id))
        return () => {
            dispatch({
                type: GET_ONE_DOOR_CATEGORY,
                payload: null,
            })
        }
    }, [dispatch, id])
    return doorCategory ? (
        <SubmitForm
            formType={formTypeList.EDIT}
            setOpen={setOpen}
            propsValue={doorCategory}
        />
    ) : null
}

export default Edit
