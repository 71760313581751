import { Button, TableCell, TableRow } from '@mui/material'
import { useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
import { OrderStatusList, orderTypeList } from '../../constants/orderType'

const OrderShipmentList = ({ order }) => {
    const navigate = useNavigate()
    const orderStatus = useMemo(() => {
        if (order.orderType === OrderStatusList.IN_PROGRESS) {
            return orderTypeList[3]
        }
        if (order.orderType === OrderStatusList.READY && !order.orderPicked) {
            return orderTypeList[4]
        }
        if (order.orderType === OrderStatusList.READY && order.orderPicked) {
            return 'Order Picked'
        }
        return ''
    }, [order])
    return (
        <>
            <TableRow>
                <TableCell>{orderStatus}</TableCell>
                <TableCell>{order?.invoiceDate}</TableCell>
                <TableCell>{order?.invoiceNumber}</TableCell>
                <TableCell>{order.poNumber}</TableCell>
                <TableCell>{order.shippingType}</TableCell>
                <TableCell>{order.customer.company}</TableCell>
                <TableCell>
                    <div className="button-box">
                        <Button
                            size="small"
                            variant="outlined"
                            color="primary"
                            onClick={() =>
                                navigate(`/ordershipment/${order.id}`)
                            }
                        >
                            Info
                        </Button>
                    </div>
                </TableCell>
            </TableRow>
        </>
    )
}

export default OrderShipmentList
