import React, { useCallback, useEffect, useMemo, useState } from 'react'
import {
    Button,
    Card,
    CardContent,
    CardMedia,
    Container,
    Grid,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
} from '@mui/material'
import { PDFViewer } from '@react-pdf/renderer'
import styled from '@emotion/styled'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import Layout from '../../components/Layout'
import OrderItemInfo from '../../components/order/OrderItemInfo'
import { GET_ONE_ORDER_SHIPMENT } from '../../store/types'
import { formTypeList } from '../../constants/form'
import {
    backOrderToInProgressAction,
    getOneOrderShipment,
    markOrderToPickedAction,
    orderFinished,
    sendOrderToReadyAction,
} from '../../store/actions/orderShipmentAction'
import { OrderHeader } from '../order/Order'
import PrintPDFLabel from '../../components/orderPayment/PrintPDFLabel'
import { roleListId, roleType } from '../../config/roleList'
import { checkUserRole } from '../../common/checkUserRole'
import { OrderStatusList } from '../../constants/orderType'

const CustomCardMedia = styled(CardMedia)({
    height: 50,
    objectFit: 'contain',
})

const ReviewOrderItemLists = ({ orderItem }) => {
    const finishedEndItem = useMemo(() => {
        if (orderItem?.orderitemfinishedend) {
            return orderItem.orderitemfinishedend
        }
        return null
    }, [orderItem])

    return (
        <>
            <TableRow
                className={
                    orderItem?.note ||
                    orderItem?.finishedOption ||
                    orderItem?.assemblyRequest
                        ? 'noBottomBorder'
                        : ''
                }
            >
                <TableCell>{orderItem?.quantity}</TableCell>
                <TableCell component="th" scope="row">
                    {orderItem.skuNo}
                </TableCell>
                <TableCell>
                    {orderItem.isItem
                        ? 'Item'
                        : orderItem?.productcolor?.product?.name}
                </TableCell>
                <TableCell>
                    {orderItem?.productcolor?.product?.doorDirection
                        ? orderItem?.doorDirection
                        : ''}
                </TableCell>
                <TableCell>
                    {orderItem?.productcolor?.finishedEnd
                        ? orderItem.finishedOption
                        : ''}
                </TableCell>
            </TableRow>
            {finishedEndItem && (
                <TableRow
                    className={
                        orderItem?.note || orderItem?.assemblyRequest
                            ? 'noBottomBorder'
                            : ''
                    }
                >
                    <TableCell>{finishedEndItem?.quantity}</TableCell>
                    <TableCell component="th" scope="row">
                        {finishedEndItem?.item?.itemSKU}
                    </TableCell>
                    <TableCell>{finishedEndItem?.description}</TableCell>
                    <TableCell />
                    <TableCell />
                </TableRow>
            )}

            {orderItem?.assemblyRequest && (
                <TableRow className={orderItem?.note ? 'noBottomBorder' : ''}>
                    <TableCell>{orderItem?.quantity}</TableCell>
                    <TableCell component="th" scope="row">
                        Assembly Required
                    </TableCell>
                    <TableCell></TableCell>
                    <TableCell />
                    <TableCell />
                </TableRow>
            )}

            {orderItem?.note && (
                <>
                    <TableRow
                        sx={{
                            '&:last-child td, &:last-child th': { border: 0 },
                        }}
                    >
                        <TableCell colSpan={8}>
                            <div>
                                <div className="dot-border">
                                    {orderItem.note}
                                </div>
                            </div>
                        </TableCell>
                    </TableRow>
                </>
            )}
        </>
    )
}

export const ReviewOrderStyle = ({ orderStyle }) => (
    <Card className="order-item-card">
        <Grid container spacing={2}>
            <Grid item xs={1}>
                <CustomCardMedia
                    component="img"
                    image={
                        orderStyle?.doorcolor?.picLink ||
                        'https://res.cloudinary.com/home-space/image/upload/v1633013512/Products/image-not-available_tnweud.png'
                    }
                    alt="order pic"
                />
            </Grid>
            <Grid item xs={11}>
                <CardContent>
                    <h3>
                        {orderStyle?.styleName} ({orderStyle?.doorcolor?.name})
                    </h3>
                </CardContent>
            </Grid>
        </Grid>
        {orderStyle?.orderitems?.length > 0 && (
            <CardContent>
                <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 650 }} aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell>Qty</TableCell>
                                <TableCell>Item</TableCell>
                                <TableCell>Description</TableCell>
                                <TableCell>Hinge Side</TableCell>
                                <TableCell>Finished End</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {orderStyle?.orderitems
                                ?.filter((item) => item.assemblyRequest)
                                ?.map((orderItem) => (
                                    <ReviewOrderItemLists
                                        orderItem={orderItem}
                                        key={orderItem.id}
                                    />
                                ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </CardContent>
        )}
    </Card>
)

const orderShipmentPageList = [
    { id: 1, title: 'Order Items' },
    { id: 2, title: 'Assembly List' },
    { id: 3, title: 'Print Label' },
]

const OrderToFinished = ({ order }) => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    // const orderItemList = useMemo(() => {
    //     const itemIdsSet = new Set()

    //     const processFinishedEnds = (finishedEnds) => {
    //         finishedEnds.forEach(({ item }) => {
    //             if (item?.id) {
    //                 itemIdsSet.add(item.id)
    //             }
    //         })
    //     }

    //     const processProductItems = (orderItems) => {
    //         orderItems.forEach((orderItem) => {
    //             if (orderItem.isItem && orderItem?.item?.id) {
    //                 itemIdsSet.add(orderItem.item.id)
    //             } else if (
    //                 !orderItem.isItem &&
    //                 orderItem?.productcolor?.productitems?.length > 0
    //             ) {
    //                 orderItem.productcolor.productitems.forEach(
    //                     (productItem) => {
    //                         if (productItem?.item?.id) {
    //                             itemIdsSet.add(productItem.item.id)
    //                         }
    //                     }
    //                 )
    //             }
    //         })
    //     }

    //     if (order?.orderitemfinishedends?.length > 0) {
    //         processFinishedEnds(order.orderitemfinishedends)
    //     }

    //     if (order?.orderstyles?.length > 0) {
    //         order.orderstyles.forEach((style) => {
    //             if (style?.orderitems?.length > 0) {
    //                 processProductItems(style.orderitems)
    //             }
    //         })
    //     }

    //     return Array.from(itemIdsSet)
    // }, [order])

    const sendOrderToFinished = useCallback(
        (id) => {
            if (window.confirm('Confirm this Order Finished?')) {
                const values = { id }
                dispatch(orderFinished(values, navigate))
            }
        },
        [dispatch, navigate]
    )

    return (
        <Button
            variant="contained"
            color="info"
            onClick={() => sendOrderToFinished(order.id)}
            style={{ marginRight: '10px' }}
        >
            Order Finished
        </Button>
    )
}

const OrderShipmentTopButtons = ({ order }) => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const { userInfo } = useSelector((state) => state.auth)
    const { roleList } = useSelector((state) => state.user)

    const sendOrderToReady = useCallback(
        (id) => {
            dispatch(sendOrderToReadyAction(id))
        },
        [dispatch]
    )

    const sendOrderToInProgress = useCallback(
        (id) => {
            dispatch(backOrderToInProgressAction(id))
        },
        [dispatch]
    )

    const markOrderPicked = useCallback(
        (id) => {
            dispatch(markOrderToPickedAction(id))
        },
        [dispatch]
    )

    return (
        <div style={{ marginBottom: '10px' }}>
            <Button
                variant="contained"
                color="primary"
                onClick={() => navigate(`/printitems/${order.id}`)}
                style={{ marginRight: '10px' }}
            >
                Print Items
            </Button>
            <Button
                variant="contained"
                color="info"
                onClick={() => navigate(`/printassembly/${order.id}`)}
                style={{ marginRight: '10px' }}
            >
                Print Assembly
            </Button>
            <Button
                variant="contained"
                color="secondary"
                onClick={() => navigate(`/printpackinglist/${order.id}`)}
                style={{ marginRight: '10px' }}
            >
                Print Packing List
            </Button>
            {checkUserRole(
                userInfo,
                roleList,
                roleListId.ORDER_SHIPMENT,
                roleType.EDIT
            ) &&
                order?.orderType === OrderStatusList.IN_PROGRESS && (
                    <Button
                        variant="contained"
                        color="success"
                        onClick={() => sendOrderToReady(order.id)}
                        style={{ marginRight: '10px' }}
                    >
                        Order Ready
                    </Button>
                )}
            {checkUserRole(
                userInfo,
                roleList,
                roleListId.ORDER_SHIPMENT,
                roleType.EDIT
            ) &&
                order?.orderType === OrderStatusList.READY && (
                    <Button
                        variant="contained"
                        color="secondary"
                        onClick={() => sendOrderToInProgress(order.id)}
                        style={{ marginRight: '10px' }}
                    >
                        Back Order to IN-PROGRESS
                    </Button>
                )}
            {checkUserRole(
                userInfo,
                roleList,
                roleListId.ORDER_SHIPMENT,
                roleType.EDIT
            ) &&
                order?.orderType === OrderStatusList.READY &&
                !order?.orderPicked && (
                    <Button
                        variant="contained"
                        color="success"
                        onClick={() => markOrderPicked(order.id)}
                        style={{ marginRight: '10px' }}
                    >
                        Mark Order Picked
                    </Button>
                )}
            {checkUserRole(
                userInfo,
                roleList,
                roleListId.ORDER,
                roleType.APPROVAL
            ) &&
                order?.orderType === OrderStatusList.READY &&
                order?.orderPicked && <OrderToFinished order={order} />}
        </div>
    )
}

const OrderShipment = () => {
    const { id } = useParams()
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const { orderShipment } = useSelector((state) => state.orderShipment)
    const [orderPage, setOrderPage] = useState(1)

    useEffect(() => {
        if (id) dispatch(getOneOrderShipment(id))
        return () => {
            dispatch({ type: GET_ONE_ORDER_SHIPMENT, payload: null })
        }
    }, [dispatch, id])
    return (
        <Layout>
            <Container>
                <div style={{ marginTop: '50px' }} />
                <div className="order-products-box">
                    <Button
                        variant="outlined"
                        color="success"
                        onClick={() => navigate(-1)}
                        style={{ marginBottom: '10px' }}
                    >
                        Back
                    </Button>
                </div>
                {orderShipment && (
                    <>
                        <div>
                            <OrderShipmentTopButtons order={orderShipment} />
                        </div>
                        <div className="order-products-box">
                            <OrderHeader
                                order={orderShipment}
                                orderInfo={false}
                            />
                            <div style={{ marginTop: '30px' }} />
                            {orderShipmentPageList.map((list) => (
                                <Button
                                    variant={
                                        list.id === orderPage
                                            ? 'contained'
                                            : 'outlined'
                                    }
                                    color="primary"
                                    onClick={() => setOrderPage(list.id)}
                                    key={list.id}
                                    style={{ marginRight: '10px' }}
                                >
                                    {list.title}
                                </Button>
                            ))}
                            <div style={{ marginTop: '30px' }} />
                            {orderPage === 1 && (
                                <OrderItemInfo order={orderShipment} />
                            )}
                            {orderPage === 2 && (
                                <div className="order-product-items">
                                    {orderShipment?.orderstyles
                                        ?.filter(
                                            (style) =>
                                                style?.orderitems?.filter(
                                                    (item) =>
                                                        item.assemblyRequest
                                                )?.length > 0
                                        )
                                        ?.map((orderStyle) => (
                                            <ReviewOrderStyle
                                                key={orderStyle.id}
                                                orderStyle={orderStyle}
                                                viewType={formTypeList.READ}
                                                orderId={id}
                                            />
                                        ))}
                                </div>
                            )}
                            {orderPage === 3 && (
                                <PDFViewer width="100%" height="600">
                                    <PrintPDFLabel order={orderShipment} />
                                </PDFViewer>
                            )}
                        </div>
                    </>
                )}
            </Container>
        </Layout>
    )
}

export default OrderShipment
