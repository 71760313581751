import React, { useCallback, useMemo, useRef, useState } from 'react'
import * as Yup from 'yup'
import { useNavigate, useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Paper from '@mui/material/Paper'
import Layout from '../../components/Layout'
import { Button, Checkbox, Container, TextField } from '@mui/material'
import AppInput from '../../components/form/AppInput'
import AppDatePicker from '../../components/form/AppDatePicker'
import AppForm from '../../components/form/AppForm'
import SubmitButton from '../../components/form/SubmitButton'
import { addShipment } from '../../store/actions/purchaseOrderAction'

const ItemList = ({ item, setItems }) => {
    const textFieldRef = useRef(null)
    const shippedQty = useMemo(() => {
        return (
            item?.shipmentorderitems?.reduce(
                (sum, item) => sum + item.quantity,
                0
            ) || 0
        )
    }, [item?.shipmentorderitems])

    const handleSelectChange = useCallback(
        (status, id) => {
            setItems((prev) =>
                prev.map((oldItem) =>
                    oldItem.id === id
                        ? { ...oldItem, selected: !status }
                        : oldItem
                )
            )
        },
        [setItems]
    )
    const handleQtyChange = useCallback(
        (event, id) => {
            setItems((prev) =>
                prev.map((oldItem) =>
                    oldItem.id === id
                        ? { ...oldItem, quantity: event.target.value || 0 }
                        : oldItem
                )
            )
        },
        [setItems]
    )

    const handleMouseOver = () => {
        if (textFieldRef.current) {
            textFieldRef.current.select()
        }
    }

    return (
        <TableRow>
            <TableCell>
                <Checkbox
                    checked={item?.selected}
                    onChange={() =>
                        handleSelectChange(item?.selected, item?.id)
                    }
                />
            </TableCell>
            <TableCell>{item.color}</TableCell>
            <TableCell>{item.sku}</TableCell>
            <TableCell>{item.originalQuantity}</TableCell>
            <TableCell>{shippedQty}</TableCell>
            <TableCell>
                <TextField
                    inputRef={textFieldRef}
                    onMouseOver={handleMouseOver}
                    disabled={!item?.selected}
                    size="small"
                    type="number"
                    value={item.quantity}
                    onChange={(e) => handleQtyChange(e, item.id)}
                />
            </TableCell>
        </TableRow>
    )
}

const validationSchema = Yup.object().shape({
    shipmentId: Yup.string().required().label('Shipment ID'),
    etd: Yup.date().required().label('ETD'),
    eta: Yup.date().required().label('ETA'),
})

const AddShipment = () => {
    const { id } = useParams()
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const { purchaseOrder } = useSelector((state) => state.purchaseOrder)
    const [items, setItems] = useState(() =>
        purchaseOrder?.purchaseorderitems?.map((item) => {
            const leftQty =
                item.quantity -
                (item?.shipmentorderitems?.reduce(
                    (sum, item) => sum + item.quantity,
                    0
                ) || 0)
            return {
                ...item,
                quantity: leftQty >= 0 ? leftQty : 0,
                selected: false,
                originalQuantity: item.quantity,
            }
        })
    )
    const [selectAll, setSelectAll] = useState(false)
    const handleSelectAllChange = useCallback(
        (status) => {
            setSelectAll(!status)
            setItems(
                purchaseOrder?.purchaseorderitems?.map((item) => {
                    const leftQty =
                        item.quantity -
                        (item?.shipmentorderitems?.reduce(
                            (sum, item) => sum + item.quantity,
                            0
                        ) || 0)
                    return {
                        ...item,
                        quantity: leftQty >= 0 ? leftQty : 0,
                        selected: !status,
                        originalQuantity: item.quantity,
                    }
                })
            )
        },
        [purchaseOrder?.purchaseorderitems]
    )

    return (
        <Layout>
            <Container>
                <Button onClick={() => navigate(-1)}>Back</Button>
            </Container>
            <AppForm
                initialValues={{
                    shipmentId: '',
                    etd: '',
                    eta: '',
                }}
                onSubmit={(values) => {
                    const shipmentItems = items
                        ?.filter((filterItem) => filterItem.selected)
                        ?.map((selectedItem) => ({
                            purchaseOrderItemId: selectedItem.id,
                            quantity: selectedItem.quantity,
                            itemId: selectedItem.item.id,
                        }))
                    if (!shipmentItems || shipmentItems.length === 0) {
                        alert('No Item selected')
                        return
                    }
                    const payload = {
                        ...values,
                        purchaseOrderId: id,
                        shipmentItems,
                    }
                    dispatch(addShipment(payload, navigate))
                }}
                validationSchema={validationSchema}
            >
                <AppInput
                    name="shipmentId"
                    label="Shipment ID"
                    type="text"
                    inputProps={{
                        maxLength: 50,
                    }}
                    required={true}
                />
                <AppDatePicker name="etd" label="ETD" required={true} />
                <div style={{ marginTop: '20px' }} />
                <AppDatePicker name="eta" label="ETA" required={true} />
                <div style={{ marginTop: '20px' }} />
                <TableContainer component={Paper}>
                    <Table aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell>
                                    <Checkbox
                                        checked={selectAll}
                                        onChange={() =>
                                            handleSelectAllChange(selectAll)
                                        }
                                    />
                                </TableCell>
                                <TableCell>Color</TableCell>
                                <TableCell>SKU</TableCell>
                                <TableCell>Order Qty</TableCell>
                                <TableCell>Shipped</TableCell>
                                <TableCell>This Qty</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {items?.map((item, index) => (
                                <ItemList
                                    item={item}
                                    items={items}
                                    setItems={setItems}
                                    key={index}
                                />
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                <SubmitButton title="Submit" />
            </AppForm>
        </Layout>
    )
}

export default AddShipment
