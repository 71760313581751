import React from 'react'
import Select from 'react-select'
import { useColorScheme } from '@mui/material/styles'

const ItemSelect = ({
    name,
    value,
    onChange,
    onInputChange,
    options,
    placeholder,
}) => {
    const { mode } = useColorScheme()

    const isDarkMode = mode === 'dark'

    const customStyles = {
        control: (provided) => ({
            ...provided,
            height: '50px',
            marginTop: '10px',
            backgroundColor: isDarkMode ? '#121212' : '#FFFFFF', // Dark gray or light gray
            color: isDarkMode ? '#f4f4f4' : '#444', // Light gray or dark gray
        }),
        menu: (provided) => ({
            ...provided,
            backgroundColor: isDarkMode ? '#333' : '#FFFFFF', // Dark gray or light gray
            color: isDarkMode ? '#f4f4f4' : '#333', // Light gray or dark gray
        }),
        option: (provided, state) => ({
            ...provided,
            backgroundColor: state.isFocused
                ? isDarkMode
                    ? '#121212' // Darker gray for selected option
                    : '#cccccc' // Lighter gray for selected option
                : null,
            color: state.isFocused ? (isDarkMode ? '#f4f4f4' : '#444') : null,
        }),
    }
    return (
        <Select
            name={name}
            value={value}
            onChange={onChange}
            onInputChange={onInputChange}
            options={options}
            isClearable
            placeholder={placeholder}
            styles={customStyles}
            isSearchable
        />
    )
}

export default ItemSelect
