import request from '../../config/request'
import { errorsReturn } from '../errorsReturn'
import { GET_ALL_USERS, GET_ONE_USER, GET_ROLES_LIST } from '../types'
import { logout, renewUserToken } from './authAction'
import { getErrors } from './errorActions'
import { controlLoading } from './loadingActions'

export const getAllUsers =
    (keyword = '', pageNumber = '') =>
    async (dispatch, getState) => {
        if (pageNumber) {
            try {
                dispatch(controlLoading(true))
                const {
                    auth: { userToken },
                } = getState()
                let res = await request.get(
                    `user/listall?keyword=${keyword}&pageNumber=${pageNumber}`,
                    {
                        headers: { Authorization: `Bearer ${userToken}` },
                    }
                )
                dispatch(renewUserToken(res))
                dispatch({
                    type: GET_ALL_USERS,
                    payload: {
                        users: res.data.data,
                        pages: res.data.pages,
                        page: res.data.page,
                        count: res.data.count,
                        keyword: keyword,
                    },
                })
                dispatch(controlLoading(false))
            } catch (e) {
                errorsReturn(e, dispatch, controlLoading, getErrors, logout)
            }
        }
    }

export const getRoleList = () => async (dispatch, getState) => {
    try {
        dispatch(controlLoading(true))
        const {
            auth: { userToken },
        } = getState()
        let res = await request.get(`roles/listall`, {
            headers: { Authorization: `Bearer ${userToken}` },
        })
        dispatch(renewUserToken(res))
        dispatch({
            type: GET_ROLES_LIST,
            payload: res.data.data,
        })
        dispatch(controlLoading(false))
    } catch (e) {
        errorsReturn(e, dispatch, controlLoading, getErrors, logout)
    }
}

export const addNewUserAction =
    (values, navigate) => async (dispatch, getState) => {
        try {
            dispatch(controlLoading(true))
            const {
                auth: { userToken },
            } = getState()
            let res = await request.post(`user/create`, values, {
                headers: { Authorization: `Bearer ${userToken}` },
            })
            dispatch(renewUserToken(res))
            dispatch(controlLoading(false))
            dispatch(getAllUsers())
            navigate('/users')
        } catch (e) {
            errorsReturn(e, dispatch, controlLoading, getErrors, logout)
        }
    }

export const getOneUser = (id) => async (dispatch, getState) => {
    try {
        dispatch(controlLoading(true))
        const {
            auth: { userToken },
        } = getState()

        let res = await request.get(`user/listone/${id}`, {
            headers: { Authorization: `Bearer ${userToken}` },
        })
        dispatch(renewUserToken(res))
        dispatch({ type: GET_ONE_USER, payload: res.data.data })
        dispatch(controlLoading(false))
    } catch (e) {
        errorsReturn(e, dispatch, controlLoading, getErrors, logout)
    }
}

export const updateUser =
    (id, values, navigate) => async (dispatch, getState) => {
        try {
            dispatch(controlLoading(true))
            const {
                auth: { userToken },
            } = getState()

            let res = await request.put(`user/update/${id}`, values, {
                headers: { Authorization: `Bearer ${userToken}` },
            })
            dispatch(renewUserToken(res))
            dispatch(controlLoading(false))
            // dispatch(getAllUsers())
            navigate('/users')
        } catch (e) {
            errorsReturn(e, dispatch, controlLoading, getErrors, logout)
        }
    }
