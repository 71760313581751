import {
    CHANGE_SHIPMENTS_PAGE,
    GET_ALL_SHIPMENTS,
    GET_ONE_SHIPMENT,
    SEARCH_SHIPMENTS,
} from '../types'

const initialState = {
    shipments: null,
    shipment: null,
    pages: '',
    page: '',
    count: '',
    keyword: '',
}

export default function shipmentReducer(state = initialState, action) {
    const { type, payload } = action
    switch (type) {
        case GET_ALL_SHIPMENTS:
            return {
                ...state,
                shipments: payload.shipments,
                pages: payload.pages,
                page: payload.page,
                count: payload.count,
                keyword: payload.keyword,
                shipment: null,
            }
        case SEARCH_SHIPMENTS:
            return {
                ...state,
                keyword: payload.keyword,
                page: 1,
            }
        case CHANGE_SHIPMENTS_PAGE:
            return {
                ...state,
                page: payload,
            }
        case GET_ONE_SHIPMENT:
            return {
                ...state,
                shipment: payload,
            }
        default:
            return state
    }
}
