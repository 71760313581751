import { Container } from '@mui/material'
import React from 'react'
import * as Yup from 'yup'
import { useDispatch } from 'react-redux'
import AppForm from '../form/AppForm'
import AppSelect from '../form/AppSelect'
import AppInput from '../form/AppInput'
import SubmitButton from '../form/SubmitButton'
import selectList from '../../constants/selectList.json'
import {
    addNewWarehouse,
    updateWarehouse,
} from '../../store/actions/warehouseAction'
import Header from '../form/Header'
import { formTypeList } from '../../constants/form'
import AppCheckbox from '../form/AppCheckbox'

const validationSchema = Yup.object().shape({
    name: Yup.string().required().min(2).max(50).label('Warehouse Name'),
    shortName: Yup.string()
        .required()
        .min(2)
        .max(10)
        .label('Warehouse Short Name'),
    address: Yup.string().required().min(2).max(200).label('Address'),
    address1: Yup.string().min(2).max(100).label('Apt or Suit #'),
    city: Yup.string().required().max(40).label('City'),
    province: Yup.string().required().max(80).label('Province'),
    postCode: Yup.string().required().max(40).label('Postal Code'),
    country: Yup.string().required().max(40).label('Country'),
})

const WarehouseForm = ({ formType, setOpen, propsState = null }) => {
    const dispatch = useDispatch()
    return (
        <Container>
            <Header
                title={`${formType === formTypeList.NEW ? 'ADD' : ''}${
                    formType === formTypeList.EDIT ? 'EDIT' : ''
                } WAREHOUSE`}
            />
            <AppForm
                initialValues={{
                    name: propsState ? propsState.name : '',
                    shortName: propsState ? propsState.shortName : '',
                    address: propsState ? propsState.address : '',
                    address1: propsState ? propsState.address1 : '',
                    city: propsState ? propsState.city : '',
                    province: propsState ? propsState.province : '',
                    postCode: propsState ? propsState.postCode : '',
                    country: propsState ? propsState.country : '',
                    isActive: propsState ? propsState.isActive : true,
                }}
                onSubmit={async (values) => {
                    if (formType === formTypeList.NEW) {
                        dispatch(addNewWarehouse(values))
                    } else {
                        const updatedValues = { ...propsState, ...values }
                        dispatch(updateWarehouse(updatedValues))
                    }
                    setOpen(false)
                }}
                validationSchema={validationSchema}
            >
                <AppInput
                    name="name"
                    label="Name"
                    type="text"
                    required
                    inputprops={{ maxLength: 50 }}
                />
                <AppInput
                    name="shortName"
                    label="Short Name"
                    type="text"
                    required
                    inputprops={{ maxLength: 10 }}
                />
                <AppInput
                    name="address"
                    label="Address"
                    type="text"
                    required
                    inputprops={{ maxLength: 200 }}
                />
                <AppInput
                    name="address1"
                    label="Apt or Suit #"
                    type="text"
                    inputprops={{ maxLength: 200 }}
                />
                <AppInput
                    name="city"
                    label="City"
                    type="text"
                    required
                    inputprops={{ maxLength: 40 }}
                />
                <AppInput
                    name="province"
                    label="Province"
                    type="text"
                    required
                    inputprops={{ maxLength: 80 }}
                />
                <AppInput
                    name="postCode"
                    label="Postal Code"
                    type="text"
                    required
                    inputprops={{ maxLength: 40 }}
                />
                <AppSelect
                    name="country"
                    label="Country"
                    required
                    options={selectList.country.list}
                />
                <AppCheckbox name="isActive" label="Is Active" />
                <SubmitButton title="Submit" />
            </AppForm>
        </Container>
    )
}

export default WarehouseForm
