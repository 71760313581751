import React, { useMemo } from 'react'
import { Button, TableCell, TableRow } from '@mui/material'
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline'
import BlockIcon from '@mui/icons-material/Block'
import { Link } from 'react-router-dom'

const ProductColorPageList = ({ productColor }) => {
    const productColorStock = useMemo(() => {
        const eachItemQty = productColor?.productitems?.map((productItem) => {
            const { quantity, item } = productItem
            const { stock } = item
            const tempStock = stock >= 0 ? stock : 0
            return Math.floor(tempStock / quantity) || 0
        })

        if (eachItemQty?.length === 0) {
            return 0
        }
        return Math.min(...eachItemQty)
    }, [productColor])

    return (
        <>
            <TableRow>
                <TableCell>
                    <img
                        src={productColor?.picLink}
                        alt="product color pic"
                        className="display-item-pic"
                        onError={(event) => {
                            event.target.src =
                                'https://res.cloudinary.com/home-space/image/upload/v1633013512/Products/image-not-available_tnweud.png'
                            event.onerror = null
                        }}
                    />
                </TableCell>
                <TableCell>{productColor?.skuNo}</TableCell>
                <TableCell>${productColor?.MSRPinCAD}</TableCell>
                {/* <TableCell>USD ${productColor?.MSRPinUSD}</TableCell> */}
                <TableCell>{productColorStock}</TableCell>
                <TableCell align="center">
                    {productColor.online ? (
                        <CheckCircleOutlineIcon color="success" />
                    ) : (
                        <BlockIcon color="error" />
                    )}
                </TableCell>
                <TableCell>
                    <Link to={`/product/${productColor?.product?.id}`}>
                        <Button color="primary" variant="outlined">
                            INFO
                        </Button>
                    </Link>
                </TableCell>
            </TableRow>
            <TableRow>
                <TableCell colSpan={7}>
                    {productColor?.productitems?.map((item) => (
                        <div key={item.id}>
                            {item?.item?.itemSKU} ({item.quantity})
                        </div>
                    ))}
                </TableCell>
            </TableRow>
        </>
    )
}

export default ProductColorPageList
