import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Layout from '../components/Layout'
import { Button, Container, Paper } from '@mui/material'
import { roleListId, roleType } from '../config/roleList'
import { checkUserRole } from '../common/checkUserRole'
import BasicModal from '../components/modal/BasicModal'
import AddNew from '../components/doorCategory/AddNew'
import { getAllDoorCategories } from '../store/actions/doorCategoryAction'
import DoorCategoryList from '../components/doorCategory/DoorCategoryList'
import DoorStyleList from '../components/doorStyle/DoorStyleList'

const DoorCategory = () => {
    const dispatch = useDispatch()
    const { doorCategories } = useSelector((state) => state.doorCategory)
    const { userInfo } = useSelector((state) => state.auth)
    const { roleList } = useSelector((state) => state.user)
    const [open, setOpen] = useState(false)

    useEffect(() => {
        dispatch(getAllDoorCategories())
    }, [dispatch])

    return (
        <Layout>
            <Container>
                <BasicModal open={open} setOpen={setOpen}>
                    <AddNew setOpen={setOpen} />
                </BasicModal>

                <Button
                    color="info"
                    variant="outlined"
                    style={{ marginBottom: '20px' }}
                    onClick={() =>
                        checkUserRole(
                            userInfo,
                            roleList,
                            roleListId.DOOR_CATEGORY,
                            roleType.CREATE
                        )
                            ? setOpen(true)
                            : alert('Not Permit User')
                    }
                >
                    Add New Door Category
                </Button>
                <Paper>
                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                        {doorCategories?.map((item) => (
                            <DoorCategoryList
                                key={item.id}
                                doorCategory={item}
                            />
                        ))}
                    </div>
                </Paper>
                <div style={{ padding: '20px' }} />
                {doorCategories?.map((doorCategory) =>
                    doorCategory?.doorstyles?.map((doorStyle) => (
                        <DoorStyleList
                            key={doorStyle.id}
                            doorStyle={doorStyle}
                            doorCategory={doorCategory}
                        />
                    ))
                )}
            </Container>
        </Layout>
    )
}

export default DoorCategory
