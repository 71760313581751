import { useEffect, useCallback, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Layout from '../../components/Layout'
import { CHANGE_DEFECTIVE_ITEMS_PAGE } from '../../store/types'
import PagnationComponent from '../../components/PagnationComponent'
import { Button, Container, Typography } from '@mui/material'
import { roleListId, roleType } from '../../config/roleList'
import { checkUserRole } from '../../common/checkUserRole'
import {
    getAllDefectiveItemSuppliers,
    getAllDefectiveItems,
} from '../../store/actions/defectiveItemAction'
import SearchDefectiveItem from '../../components/defectiveItem/SearchDefectiveItem'
import DefectiveItemInfo from '../../components/defectiveItem/DefectiveItemInfo'
import BasicModal from '../../components/modal/BasicModal'
import DefectiveItemForm from '../../components/defectiveItem/DefectiveItemForm'

const DefectiveItems = () => {
    const dispatch = useDispatch()
    const defectiveItemState = useSelector((state) => state.defectiveItem)
    const { defectiveItems, pages, page, count, keyword } = defectiveItemState
    const { userInfo } = useSelector((state) => state.auth)
    const { roleList } = useSelector((state) => state.user)
    const [open, setOpen] = useState(false)

    useEffect(() => {
        dispatch(getAllDefectiveItemSuppliers())
    }, [dispatch])

    const pageNumber = page || 1
    useEffect(() => {
        dispatch(getAllDefectiveItems(keyword, pageNumber))
    }, [dispatch, keyword, pageNumber])

    const changePage = useCallback(
        (page) => {
            dispatch({ type: CHANGE_DEFECTIVE_ITEMS_PAGE, payload: page })
        },
        [dispatch]
    )
    return (
        <Layout>
            <Container>
                <BasicModal open={open} setOpen={setOpen}>
                    <DefectiveItemForm setOpen={setOpen} />
                </BasicModal>
                <Button
                    color="info"
                    variant="outlined"
                    onClick={() =>
                        checkUserRole(
                            userInfo,
                            roleList,
                            roleListId.DEFECTIVE_ITEM,
                            roleType.CREATE
                        )
                            ? setOpen(true)
                            : alert('Not Permit User')
                    }
                >
                    Add New Defective Item
                </Button>
                <SearchDefectiveItem displayKeyword={keyword} />
                <Typography
                    variant="body2"
                    gutterBottom
                    style={{ paddingBottom: '10px', paddingTop: '10px' }}
                >
                    Count: {count}
                </Typography>
                {defectiveItems && (
                    <DefectiveItemInfo defectiveItems={defectiveItems} />
                )}
                <PagnationComponent
                    page={pageNumber}
                    pages={pages}
                    func={changePage}
                />
            </Container>
        </Layout>
    )
}

export default DefectiveItems
