import React, { useEffect } from 'react'
import Layout from '../../components/Layout'
import { formTypeList } from '../../constants/form'
import ProductForm from '../../components/product/ProductForm'
import { useDispatch } from 'react-redux'
import { getCreateProductCategory } from '../../store/actions/productAction'

const AddProduct = () => {
    const dispatch = useDispatch()
    useEffect(() => {
        dispatch(getCreateProductCategory())
    }, [dispatch])
    return (
        <Layout>
            <ProductForm formType={formTypeList.NEW} />
        </Layout>
    )
}

export default AddProduct
