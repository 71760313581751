import request from '../../config/request'
import { errorsReturn } from '../errorsReturn'
import {
    CREATE_PRODUCT_CATEGORY,
    GET_ALL_PRODUCTS,
    GET_ONE_PRODUCT,
    GET_PRODUCT_DOOR_COLORS,
} from '../types'
import { logout, renewUserToken } from './authAction'
import { getErrors } from './errorActions'
import { controlLoading } from './loadingActions'

export const getAllProducts =
    (keyword = '', pageNumber = '') =>
    async (dispatch, getState) => {
        if (pageNumber) {
            try {
                dispatch(controlLoading(true))
                const {
                    auth: { userToken },
                } = getState()
                let res = await request.get(
                    `product/listall?keyword=${keyword}&pageNumber=${pageNumber}`,
                    {
                        headers: { Authorization: `Bearer ${userToken}` },
                    }
                )
                dispatch(renewUserToken(res))
                dispatch({
                    type: GET_ALL_PRODUCTS,
                    payload: {
                        products: res.data.data,
                        pages: res.data.pages,
                        page: res.data.page,
                        count: res.data.count,
                        keyword: keyword,
                    },
                })
                dispatch(controlLoading(false))
            } catch (e) {
                errorsReturn(e, dispatch, controlLoading, getErrors, logout)
            }
        }
    }

export const getOneProduct = (id) => async (dispatch, getState) => {
    try {
        dispatch(controlLoading(true))
        const {
            auth: { userToken },
        } = getState()

        let res = await request.get(`product/listone/${id}`, {
            headers: { Authorization: `Bearer ${userToken}` },
        })
        dispatch(renewUserToken(res))
        dispatch({ type: GET_ONE_PRODUCT, payload: res.data.data })
        dispatch(controlLoading(false))
    } catch (e) {
        errorsReturn(e, dispatch, controlLoading, getErrors, logout)
    }
}

export const getCreateProductCategory = () => async (dispatch, getState) => {
    try {
        dispatch(controlLoading(true))
        const {
            auth: { userToken },
        } = getState()

        let res = await request.get(`product/listcategory`, {
            headers: { Authorization: `Bearer ${userToken}` },
        })
        dispatch(renewUserToken(res))
        dispatch({ type: CREATE_PRODUCT_CATEGORY, payload: res.data.data })
        dispatch(controlLoading(false))
    } catch (e) {
        errorsReturn(e, dispatch, controlLoading, getErrors, logout)
    }
}

export const getProductDoorColors = () => async (dispatch, getState) => {
    try {
        dispatch(controlLoading(true))
        const {
            auth: { userToken },
        } = getState()

        let res = await request.get(`product/listdoorcolor`, {
            headers: { Authorization: `Bearer ${userToken}` },
        })
        dispatch(renewUserToken(res))
        dispatch({ type: GET_PRODUCT_DOOR_COLORS, payload: res.data.data })
        dispatch(controlLoading(false))
    } catch (e) {
        errorsReturn(e, dispatch, controlLoading, getErrors, logout)
    }
}

export const addNewProduct =
    (values, navigate) => async (dispatch, getState) => {
        try {
            dispatch(controlLoading(true))
            const {
                auth: { userToken },
            } = getState()
            let res = await request.post(`product/create`, values, {
                headers: { Authorization: `Bearer ${userToken}` },
            })
            dispatch(renewUserToken(res))
            dispatch(controlLoading(false))
            dispatch(getAllProducts())
            navigate('/products')
        } catch (e) {
            errorsReturn(e, dispatch, controlLoading, getErrors, logout)
        }
    }

export const updateProduct =
    (values, navigate) => async (dispatch, getState) => {
        try {
            dispatch(controlLoading(true))
            const {
                auth: { userToken },
            } = getState()
            let res = await request.put(`product/update/${values.id}`, values, {
                headers: { Authorization: `Bearer ${userToken}` },
            })
            dispatch(renewUserToken(res))
            dispatch(controlLoading(false))
            dispatch(getAllProducts())
            navigate('/products')
        } catch (e) {
            errorsReturn(e, dispatch, controlLoading, getErrors, logout)
        }
    }

export const deleteProduct = (id) => async (dispatch, getState) => {
    try {
        dispatch(controlLoading(true))
        const {
            auth: { userToken },
        } = getState()
        let res = await request.delete(`product/delete/${id}`, {
            headers: { Authorization: `Bearer ${userToken}` },
        })
        dispatch(renewUserToken(res))
        dispatch(controlLoading(false))
        dispatch(getAllProducts())
    } catch (e) {
        errorsReturn(e, dispatch, controlLoading, getErrors, logout)
    }
}
