import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Paper from '@mui/material/Paper'
import OrderShipmentList from './OrderShipmentList'

const OrderShipmentInfo = ({ orders }) => {
    return (
        <TableContainer component={Paper}>
            <Table aria-label="simple table">
                <TableHead>
                    <TableRow>
                        <TableCell>Status</TableCell>
                        <TableCell>Invoice Date</TableCell>
                        <TableCell>Invoice No</TableCell>
                        <TableCell>PO</TableCell>
                        <TableCell>Shipping</TableCell>
                        <TableCell>Customer</TableCell>
                        <TableCell style={{ width: '50px' }}></TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {orders?.map((order) => (
                        <OrderShipmentList order={order} key={order.id} />
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    )
}

export default OrderShipmentInfo
