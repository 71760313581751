import {
    GET_ALL_PRODUCT_CATEGORY,
    GET_ONE_PRODUCT_CATEGORY,
    GET_ONE_PRODUCT_STYLE,
} from '../types'

const initialState = {
    productCategories: null,
    productCategory: null,
    productStyle: null,
}

const productCategoryReducer = (state = initialState, action) => {
    const { type, payload } = action
    switch (type) {
        case GET_ALL_PRODUCT_CATEGORY:
            return {
                ...state,
                productCategories: payload,
                productCategory: null,
                productStyle: null,
            }
        case GET_ONE_PRODUCT_CATEGORY:
            return {
                ...state,
                productCategory: payload,
            }
        case GET_ONE_PRODUCT_STYLE:
            return {
                ...state,
                productStyle: payload,
            }
        default:
            return state
    }
}

export default productCategoryReducer
