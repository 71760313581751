import {
    CHANGE_ITEMS_PAGE,
    GET_ALL_ITEMS,
    GET_ALL_WAREHOUSE_MAPS_ITEMS,
    GET_ITEM_ANALYTICS,
    GET_ONE_ITEM,
    SEARCH_ITEMS,
} from '../types'

const initialState = {
    items: null,
    suppliers: null,
    item: null,
    pages: null,
    page: 1,
    count: 0,
    keyword: '',
    warehouseMaps: null,
    itemAnalytics: null,
}

const itemReducer = (state = initialState, action) => {
    const { type, payload } = action
    switch (type) {
        case GET_ALL_ITEMS:
            return {
                ...state,
                items: payload.data.items,
                suppliers: payload.data.suppliers,
                pages: payload.pages,
                page: payload.page,
                count: payload.count,
                keyword: payload.keyword,
                item: null,
            }
        case SEARCH_ITEMS:
            return {
                ...state,
                keyword: payload.keyword,
                page: 1,
            }
        case CHANGE_ITEMS_PAGE:
            return {
                ...state,
                page: payload,
            }
        case GET_ONE_ITEM:
            return {
                ...state,
                item: payload,
            }
        case GET_ALL_WAREHOUSE_MAPS_ITEMS:
            return {
                ...state,
                warehouseMaps: payload,
            }
        case GET_ITEM_ANALYTICS:
            return {
                ...state,
                itemAnalytics: payload,
            }
        default:
            return state
    }
}

export default itemReducer
