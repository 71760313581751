import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { formTypeList } from '../../constants/form'
import { GET_ONE_DOOR_STYLE } from '../../store/types'
import Form from './Form'
import { getOneDoorStyle } from '../../store/actions/doorStyleAction'

const Edit = ({ id, setOpen }) => {
    const dispatch = useDispatch()
    const { doorStyle } = useSelector((state) => state.doorCategory)
    useEffect(() => {
        dispatch(getOneDoorStyle(id))
        return () => {
            dispatch({
                type: GET_ONE_DOOR_STYLE,
                payload: null,
            })
        }
    }, [dispatch, id])
    return doorStyle ? (
        <Form
            formType={formTypeList.EDIT}
            setOpen={setOpen}
            propsValue={doorStyle}
        />
    ) : null
}

export default Edit
