/* eslint-disable no-unused-vars */
import { Button, Grid, TextField } from '@mui/material'
import { useDispatch } from 'react-redux'
import React, { useCallback, useEffect, useState } from 'react'
import Select from 'react-select'
import request from '../../config/request'
import { addOrderItem } from '../../store/actions/orderAction'

const SearchProduct = ({ orderStyleId, orderId, doorcolorId, itemQty }) => {
    const dispatch = useDispatch()
    const [searchItems, setSearchItems] = useState([])
    const [productColorId, setProductColorId] = useState(null)
    const [selectValue, setSelectValue] = useState(null)
    const [quantity, setQuantity] = useState(1)
    const [controller, setController] = useState(new AbortController())

    useEffect(() => () => controller.abort(), [controller])

    const handleSearchItemChange = useCallback((option) => {
        if (option) {
            setSelectValue(option)
            setProductColorId(option?.value)
        } else {
            setSelectValue(null)
            setProductColorId(null)
        }
    }, [])

    const handleInputChange = useCallback(
        (searchKeyword) => {
            const searchValues = { searchKeyword, doorcolorId }
            controller.abort()
            const newController = new AbortController()
            setController(newController)
            request
                .post('order/searchproduct', searchValues, {
                    signal: newController.signal,
                })
                .then((res) => {
                    if (res.data.data) {
                        const selectValues = res?.data?.data?.map((item) => ({
                            value: item.id,
                            label: item.product.sku,
                        }))
                        setSearchItems(selectValues)
                    }
                })
                .catch((error) => {
                    if (error.name === 'AbortError') {
                        console.log('Search request aborted')
                    } else {
                        console.error('Error fetching search items:', error)
                    }
                })
        },
        [doorcolorId, controller]
    )

    const handleAddItem = useCallback(() => {
        if (!productColorId) return false
        if (quantity <= 0) return false
        const values = {
            name: '',
            productBaseSku: selectValue.label,
            quantity,
            sort: itemQty + 1,
            doorDirection: '',
            finishedOption: '',
            note: '',
            orderStyleId,
            productColorId,
        }
        dispatch(addOrderItem(values, orderStyleId, orderId))
        setQuantity(1)
        setSearchItems([])
        setProductColorId(null)
        setSelectValue(null)
        return true
    }, [
        dispatch,
        quantity,
        productColorId,
        selectValue,
        itemQty,
        orderId,
        orderStyleId,
    ])

    return (
        <div>
            <div>Add Product</div>
            <Grid
                container
                spacing={2}
                style={{ display: 'flex', alignItems: 'flex-end' }}
            >
                <Grid item xs={6}>
                    <Select
                        name="searchItem"
                        value={selectValue}
                        onChange={handleSearchItemChange}
                        onInputChange={handleInputChange}
                        options={searchItems}
                        placeholder="Search Product"
                        isClearable
                        isSearchable
                    />
                </Grid>
                <Grid item xs={3}>
                    <TextField
                        fullWidth
                        size="small"
                        name="quantity"
                        type="number"
                        id={Math.random().toString()}
                        label="Quantity"
                        variant="outlined"
                        value={quantity}
                        onChange={(e) => setQuantity(e.target.value)}
                    />
                </Grid>
                <Grid item xs={3}>
                    <Button
                        fullWidth
                        variant="outlined"
                        color="primary"
                        onClick={handleAddItem}
                    >
                        Add
                    </Button>
                </Grid>
            </Grid>
        </div>
    )
}

export default SearchProduct
