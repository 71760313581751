import React from 'react'
import Form from './Form'
import { formTypeList } from '../../constants/form'

const AddNew = ({ setOpen, productCategoryId }) => {
    return (
        <Form
            formType={formTypeList.NEW}
            productCategoryId={productCategoryId}
            setOpen={setOpen}
        />
    )
}

export default AddNew
