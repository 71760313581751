import React, { useCallback, useState } from 'react'
import EditIcon from '@mui/icons-material/Edit'
import DeleteForeverIcon from '@mui/icons-material/DeleteForever'
import { useDispatch, useSelector } from 'react-redux'
import { roleListId, roleType } from '../../config/roleList'
import { DELETE_CONFIRM } from '../../constants/form'
import { deleteDoorColor } from '../../store/actions/doorColorAction'
import BasicModal from '../modal/BasicModal'
import Edit from './Edit'
import { checkUserRole } from '../../common/checkUserRole'

const DoorColorList = ({ doorColor }) => {
    const dispatch = useDispatch()
    const { userInfo } = useSelector((state) => state.auth)
    const { roleList } = useSelector((state) => state.user)
    const [editOpen, setEditOpen] = useState(false)
    const handleDelete = useCallback(
        (id) => {
            if (window.confirm(DELETE_CONFIRM)) {
                dispatch(deleteDoorColor(id))
            }
        },
        [dispatch]
    )

    return (
        <div
            style={{
                display: 'flex',
            }}
        >
            <BasicModal open={editOpen} setOpen={setEditOpen}>
                <Edit id={doorColor.id} setOpen={setEditOpen} />
            </BasicModal>
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}
            >
                <img
                    src={doorColor?.picLink}
                    alt="item pic"
                    className="display-door-color-pic"
                    onError={(event) => {
                        event.target.src =
                            'https://res.cloudinary.com/home-space/image/upload/v1633013512/Products/image-not-available_tnweud.png'
                        event.onerror = null
                    }}
                />
                <div
                    style={{
                        width: '100%',

                        textAlign: 'center',
                    }}
                >
                    {' '}
                    {doorColor?.name}
                </div>
                <div
                    style={{
                        width: '100%',

                        textAlign: 'center',
                    }}
                >
                    {' '}
                    {doorColor?.colorCode}
                </div>
                <div
                    style={{
                        width: '100%',

                        textAlign: 'center',
                    }}
                >
                    <EditIcon
                        onClick={() =>
                            checkUserRole(
                                userInfo,
                                roleList,
                                roleListId.DOOR_COLOR,
                                roleType.EDIT
                            )
                                ? setEditOpen(true)
                                : alert('Not Permit User')
                        }
                        style={{
                            marginRight: '20px',
                            cursor: 'pointer',
                            fontSize: '15px',
                        }}
                    />
                    <DeleteForeverIcon
                        onClick={() =>
                            checkUserRole(
                                userInfo,
                                roleList,
                                roleListId.DOOR_COLOR,
                                roleType.DELETE
                            )
                                ? handleDelete(doorColor.id)
                                : alert('Not Permit User')
                        }
                        style={{
                            color: 'red',
                            cursor: 'pointer',
                            fontSize: '15px',
                        }}
                    />
                </div>
            </div>
        </div>
    )
}

export default DoorColorList
