import React from 'react'
import { formTypeList } from '../../constants/form'
import ProductItemForm from './ProductItemForm'

const AddNew = ({ setOpen, productColorId }) => {
    return (
        <ProductItemForm
            formType={formTypeList.NEW}
            productColorId={productColorId}
            setOpen={setOpen}
        />
    )
}

export default AddNew
