import React from 'react'
import { useFormikContext } from 'formik'
import { TextField } from '@mui/material'

const AppInputPic = ({ name, type, label, required, ...otherProps }) => {
    const { setFieldTouched, handleChange, values, errors, touched } =
        useFormikContext()
    return (
        <>
            <div className="form-input">
                <TextField
                    fullWidth
                    error={touched[name] && errors[name] ? true : false}
                    required={required}
                    name={name}
                    type={type}
                    id={name}
                    label={label}
                    variant="standard"
                    value={values[name]}
                    onChange={handleChange(name)}
                    onBlur={() => setFieldTouched(name)}
                    helperText={
                        touched[name] && errors[name] ? errors[name] : null
                    }
                    {...otherProps}
                />
            </div>
            {values[name] && (
                <div className="app-input-src">
                    <img
                        src={values[name]}
                        alt="item pic"
                        className="item-pic"
                        onError={(event) => {
                            event.target.src =
                                'https://res.cloudinary.com/home-space/image/upload/v1633013512/Products/image-not-available_tnweud.png'
                            event.onerror = null
                        }}
                    />
                </div>
            )}
        </>
    )
}

export default AppInputPic
