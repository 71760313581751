import { CHANGE_ITEM_RECORDS_PAGE, GET_ALL_ITEM_RECORDS } from '../types'

const initialState = {
    itemRecords: null,
    pages: null,
    page: 1,
    count: 0,
}

const itemRecordReducer = (state = initialState, action) => {
    const { type, payload } = action
    switch (type) {
        case GET_ALL_ITEM_RECORDS:
            return {
                ...state,
                itemRecords: payload.data,

                pages: payload.pages,
                page: payload.page,
                count: payload.count,
            }
        case CHANGE_ITEM_RECORDS_PAGE:
            return {
                ...state,
                page: payload,
            }
        default:
            return state
    }
}

export default itemRecordReducer
