import {
    CHANGE_PURCHASE_ORDERS_PAGE,
    GET_ALL_ITEM_SKU,
    GET_ALL_PURCHASE_ORDERS,
    GET_ALL_PURCHASE_ORDER_SUPPLIERS,
    GET_ONE_PURCHASE_ORDER,
    SEARCH_PURCHASE_ORDERS,
} from '../types'

const initialState = {
    purchaseOrders: null,
    purchaseOrder: null,
    itemSKUs: null,
    suppliers: null,
    pages: '',
    page: '',
    count: '',
    keyword: '',
}

export default function purchaseOrderReducer(state = initialState, action) {
    const { type, payload } = action
    switch (type) {
        case GET_ALL_PURCHASE_ORDERS:
            return {
                ...state,
                purchaseOrders: payload.purchaseOrders,
                pages: payload.pages,
                page: payload.page,
                count: payload.count,
                keyword: payload.keyword,
                purchaseOrder: null,
            }
        case SEARCH_PURCHASE_ORDERS:
            return {
                ...state,
                keyword: payload.keyword,
                page: 1,
            }
        case CHANGE_PURCHASE_ORDERS_PAGE:
            return {
                ...state,
                page: payload,
            }
        case GET_ONE_PURCHASE_ORDER:
            return {
                ...state,
                purchaseOrder: payload,
            }
        case GET_ALL_ITEM_SKU:
            return {
                ...state,
                itemSKUs: payload,
            }
        case GET_ALL_PURCHASE_ORDER_SUPPLIERS:
            return {
                ...state,
                suppliers: payload,
            }
        default:
            return state
    }
}
