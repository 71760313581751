import { TableCell, TableRow } from '@mui/material'
import { roleNameList, roleTypeName } from '../../config/roleList'
import moment from 'moment'

const LogsList = ({ log }) => {
    return (
        <>
            <TableRow>
                <TableCell>{roleNameList[log.categoryId]}</TableCell>
                <TableCell>{roleTypeName[log.type]}</TableCell>
                <TableCell>{log.description}</TableCell>
                <TableCell>
                    {log?.user?.firstName} {log?.user?.lastName}
                </TableCell>
                <TableCell>{log?.user?.email}</TableCell>
                <TableCell>{moment(log?.createdAt).format('lll')}</TableCell>
            </TableRow>
        </>
    )
}

export default LogsList
