import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import Layout from '../../components/Layout'
import { formTypeList } from '../../constants/form'
import {
    getCreateProductCategory,
    getOneProduct,
} from '../../store/actions/productAction'
import ProductForm from '../../components/product/ProductForm'

const EditProduct = () => {
    const dispatch = useDispatch()
    const { id } = useParams()
    const { product } = useSelector((state) => state.product)

    useEffect(() => {
        dispatch(getCreateProductCategory())
        dispatch(getOneProduct(id))
    }, [dispatch, id])
    return (
        <Layout>
            {product ? (
                <ProductForm
                    formType={formTypeList.EDIT}
                    propsValue={{
                        ...product,
                        productStyleId: product?.productstyle?.id,
                    }}
                />
            ) : null}
        </Layout>
    )
}

export default EditProduct
