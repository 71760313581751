const CLIENT = process.env.REACT_APP_CLIENT || 'woodworth';

const colorCode =
  CLIENT === 'woodworth'
    ? {
        PRIMARY: '#C8893E',
        SCONDARY: '#F6F6F6',
        GREY: '#757575',
      }
    : {
        PRIMARY: '#34435C',
        SCONDARY: '#8F401F',
        GREY: '#757575',
      };

export default colorCode;
