import React from 'react'
import Layout from '../../components/Layout'
import SupplierForm from '../../components/supplier/SupplierForm'
import { formTypeList } from '../../constants/form'

const AddSupplier = () => {
    return (
        <Layout>
            <SupplierForm formType={formTypeList.NEW} />
        </Layout>
    )
}

export default AddSupplier
