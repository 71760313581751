import { useEffect, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Layout from '../components/Layout'
import SearchUser from '../components/users/SearchUser'
import { getAllUsers } from '../store/actions/usersAction'
import UserInfos from '../components/users/UserInfos'
import { CHANGE_USERS_PAGE } from '../store/types'
import PagnationComponent from '../components/PagnationComponent'
import { Container, Typography } from '@mui/material'

const Users = () => {
    const dispatch = useDispatch()
    const userState = useSelector((state) => state.user)
    const { users, pages, page, count, keyword } = userState

    const pageNumber = page || 1
    useEffect(() => {
        dispatch(getAllUsers(keyword, pageNumber))
    }, [dispatch, keyword, pageNumber])

    const changePage = useCallback(
        (page) => {
            dispatch({ type: CHANGE_USERS_PAGE, payload: page })
        },
        [dispatch]
    )
    return (
        <Layout>
            <Container>
                <SearchUser displayKeyword={keyword} />
                <Typography
                    variant="body2"
                    gutterBottom
                    style={{ paddingBottom: '10px', paddingTop: '10px' }}
                >
                    Count: {count}
                </Typography>
                {users && <UserInfos users={users} />}
                <PagnationComponent
                    page={pageNumber}
                    pages={pages}
                    func={changePage}
                />
            </Container>
        </Layout>
    )
}

export default Users
