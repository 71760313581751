import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { formTypeList } from '../../constants/form'
import { GET_ONE_WAREHOUSE } from '../../store/types'
import { getOneWarehouse } from '../../store/actions/warehouseAction'
import WarehouseForm from './WarehouseForm'

const EditWarehouse = ({ id, setOpen }) => {
    const dispatch = useDispatch()
    const { warehouse } = useSelector((state) => state.warehouse)
    useEffect(() => {
        dispatch(getOneWarehouse(id))
        return () => {
            dispatch({
                type: GET_ONE_WAREHOUSE,
                payload: null,
            })
        }
    }, [dispatch, id])
    return warehouse ? (
        <WarehouseForm
            formType={formTypeList.EDIT}
            setOpen={setOpen}
            propsState={warehouse}
        />
    ) : null
}

export default EditWarehouse
