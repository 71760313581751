import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import * as Yup from 'yup'
import { Container } from '@mui/material'
import AppForm from '../form/AppForm'
import AppInput from '../form/AppInput'
import SubmitButton from '../form/SubmitButton'
import Header from '../form/Header'
import { formTypeList } from '../../constants/form'
import {
    addNewProductColorItem,
    getProductColorItems,
    updateProductColorItem,
} from '../../store/actions/productColorAction'
import { getErrors } from '../../store/actions/errorActions'
import ItemSelect from '../form/ItemSelect'

const validationSchema = Yup.object().shape({
    quantity: Yup.number().required().label('Quantity'),
})

const ProductItemForm = ({
    formType,
    setOpen,
    productColorId = null,
    propsValue = null,
}) => {
    const dispatch = useDispatch()
    const { searchItems } = useSelector((state) => state.productColor)
    const [selectValue, setSelectValue] = useState(null)
    const [selectedItem, setSelectedItem] = useState(null)

    const handleSearchItemChange = (option) => {
        if (option) {
            const [itemSKU, priceCAD, priceUSD] = option.label.split('|')
            const values = {
                itemId: option.value,
                itemSKU,
                priceCAD: parseFloat(priceCAD),
                priceUSD: parseFloat(priceUSD),
            }
            setSelectedItem(values)
            setSelectValue(option)
        }
    }

    const handleInputChange = (inputValue) => {
        const values = { search: inputValue }
        dispatch(getProductColorItems(values))
    }

    return (
        <Container>
            <Header
                title={`${formType === formTypeList.NEW ? 'ADD' : ''}${
                    formType === formTypeList.EDIT ? 'EDIT' : ''
                } PRODUCT ITEM`}
            />
            <AppForm
                initialValues={{
                    quantity: propsValue ? propsValue.quantity : '',
                }}
                onSubmit={(values) => {
                    if (!selectedItem) {
                        dispatch(getErrors('item empty'))
                        return
                    }
                    const submitValues =
                        formType === formTypeList.NEW
                            ? { ...values, productColorId, ...selectedItem }
                            : { ...propsValue, ...values, ...selectedItem }
                    formType === formTypeList.NEW
                        ? dispatch(
                              addNewProductColorItem(submitValues),
                              setOpen(false)
                          )
                        : dispatch(
                              updateProductColorItem(submitValues),
                              setOpen(false)
                          )
                }}
                validationSchema={validationSchema}
            >
                <ItemSelect
                    name="searchItem"
                    value={selectValue}
                    onChange={handleSearchItemChange}
                    onInputChange={handleInputChange}
                    options={searchItems}
                    placeholder="Item"
                />
                <AppInput
                    name="quantity"
                    label="Quantity"
                    type="number"
                    inputProps={{
                        maxLength: 10,
                    }}
                    required={true}
                />

                <SubmitButton title="Submit" />
            </AppForm>
        </Container>
    )
}

export default ProductItemForm
