import React from 'react'
import { OrderHeader } from '../../pages/order/Order'
import { ReviewOrderStyle } from '../../pages/orderShipment/OrderShipment'
import { formTypeList } from '../../constants/form'

const AssemblyToPrint = React.forwardRef(({ order }, ref) => (
    <div className="invoice-container" ref={ref}>
        <div className="order-products-box">
            <OrderHeader order={order} orderInfo={false} />
            <div style={{ marginTop: '30px' }} />
            <div className="order-product-items">
                {order?.orderstyles
                    ?.filter(
                        (style) =>
                            style?.orderitems?.filter(
                                (item) => item.assemblyRequest
                            )?.length > 0
                    )
                    ?.map((orderStyle) => (
                        <ReviewOrderStyle
                            key={orderStyle.id}
                            orderStyle={orderStyle}
                            viewType={formTypeList.READ}
                            orderId={order.id}
                        />
                    ))}
            </div>
        </div>
    </div>
))

export default AssemblyToPrint
