import React from 'react'

const CustomerSignature = () => {
    return (
        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <div style={{ width: '200px' }}>
                <div>Customer Signature</div>
                <div
                    style={{ height: '50px', borderBottom: '1px solid black' }}
                />
            </div>
        </div>
    )
}

export default CustomerSignature
