import { Button } from '@mui/material'
import React, { useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import Paper from '@mui/material/Paper'
import Divider from '@mui/material/Divider'
import BasicModal from '../modal/BasicModal'
import AddNew from '../productColor/AddNew'
import ProductColorList from '../productColor/ProductColorList'

const ProductDoorColorList = ({ doorColor }) => {
    const { product } = useSelector((state) => state.product)
    const productColorList = useMemo(
        () => product?.productcolors?.map((color) => color.doorcolor.id),
        [product]
    )
    const autoSku = useMemo(
        () => `${doorColor.colorCode}-${product.sku}`,
        [doorColor.colorCode, product.sku]
    )
    const [open, setOpen] = useState(false)
    return (
        <Paper
            style={{
                marginBottom: '20px',
            }}
        >
            <BasicModal open={open} setOpen={setOpen}>
                <AddNew
                    setOpen={setOpen}
                    autoSku={autoSku}
                    productId={product.id}
                    doorColorId={doorColor.id}
                />
            </BasicModal>
            <div style={{ padding: '20px', fontWeight: 'bold' }}>
                {doorColor.name}
            </div>
            <Divider />
            <div style={{ padding: '20px' }}>
                {productColorList.indexOf(doorColor.id) === -1 ? (
                    <Button
                        size="small"
                        variant="outlined"
                        color="primary"
                        onClick={() => setOpen(true)}
                    >
                        Add
                    </Button>
                ) : (
                    <ProductColorList
                        productColor={product?.productcolors?.find(
                            (color) => color.doorcolor.id === doorColor.id
                        )}
                    />
                )}
            </div>
        </Paper>
    )
}

export default ProductDoorColorList
