import React, { useState } from 'react'
import Papa from 'papaparse'
import { TextField, Button, Divider } from '@mui/material'
import { useDispatch } from 'react-redux'
import { addNewPurchaseOrderItemFromCSV } from '../../store/actions/purchaseOrderAction'
import MissingItemList from './MissingItemList'

const PurchaseOrderItemsUpload = ({ purchaseOrderId }) => {
    const dispatch = useDispatch()
    const [csvData, setCsvData] = useState(null)
    const [displayData, setDisplayData] = useState(null)

    const handleFileChange = (e) => {
        const file = e.target.files[0]

        if (!file) {
            return
        }

        if (file.type !== 'text/csv' && !file.name.endsWith('.csv')) {
            alert('Please select a CSV file.')
            return
        }

        const reader = new FileReader()

        reader.onload = (event) => {
            const csv = event.target.result
            setCsvData(csv)
        }

        reader.readAsText(file)
    }

    const processCSV = () => {
        if (!csvData) return

        Papa.parse(csvData, {
            complete: (result) => {
                const rows = result.data
                    .map((row) => {
                        if (row.length === 3) {
                            const color = row[0]
                            const sku = row[1]
                            const quantity = row[2]

                            let itemSKU = null
                            if (color?.toUpperCase() === 'BOX') {
                                itemSKU = `${sku}-BOX`
                            } else {
                                itemSKU = `${color}-${sku}-F`
                            }

                            return { color, sku, itemSKU, quantity }
                        }
                        return null
                    })
                    .filter((item) => item !== null)
                // console.log(rows)
                const frequency = {}
                const duplicates = []

                // Count the occurrences of each item
                rows.map((mapItem) => mapItem.itemSKU).forEach((item) => {
                    frequency[item] = (frequency[item] || 0) + 1
                })

                // Find items with more than one occurrence
                for (const key in frequency) {
                    if (frequency.hasOwnProperty(key) && frequency[key] > 1) {
                        console.log('key ==>', JSON.stringify(key, null, 2))
                        duplicates.push(key)
                    }
                }

                if (duplicates.length > 0) {
                    alert(`Have duplicate items ${duplicates.join(',')}`)
                    return
                }

                const values = { itemsCSV: rows, purchaseOrderId }
                dispatch(addNewPurchaseOrderItemFromCSV(values, setDisplayData))
                // setDisplayData(rows)
            },
        })
    }

    return (
        <>
            <div style={{ marginTop: '30px' }}>
                <TextField
                    size="small"
                    // label="Upload CSV File"
                    type="file"
                    accept=".csv"
                    onChange={handleFileChange}
                />
                <Button variant="outlined" onClick={processCSV}>
                    Process CSV
                </Button>
            </div>
            {displayData && (
                <div style={{ marginTop: '30px' }}>
                    <Divider>
                        <span style={{ color: 'red' }}>Items not found</span>
                    </Divider>
                    <div style={{ margin: '30px' }} />
                    <MissingItemList displayData={displayData} />
                </div>
            )}
        </>
    )
}

export default PurchaseOrderItemsUpload
