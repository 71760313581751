import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Paper from '@mui/material/Paper'
import moment from 'moment'
import { green, red } from '@mui/material/colors'
import BlockIcon from '@mui/icons-material/Block'
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline'

const RecordItem = ({ item }) => (
    <TableRow>
        <TableCell>{moment(item?.createdAt).format('lll')}</TableCell>
        <TableCell align="center">{item?.percentage}%</TableCell>
        <TableCell align="center">
            {item.itemUpdated ? (
                <CheckCircleOutlineIcon style={{ color: green[500] }} />
            ) : (
                <BlockIcon style={{ color: red[500] }} />
            )}
        </TableCell>
        <TableCell align="center">
            {item.productColorUpdated ? (
                <CheckCircleOutlineIcon style={{ color: green[500] }} />
            ) : (
                <BlockIcon style={{ color: red[500] }} />
            )}
        </TableCell>
        <TableCell>
            {item?.user?.firstName} {item?.user?.lastName}
        </TableCell>
    </TableRow>
)

const ItemRecordList = ({ itemRecords }) => {
    return (
        <TableContainer component={Paper}>
            <Table aria-label="simple table">
                <TableHead>
                    <TableRow>
                        <TableCell>Date</TableCell>
                        <TableCell>Percentage</TableCell>
                        <TableCell>All Items Updated</TableCell>
                        <TableCell>All Product Colors Updated</TableCell>
                        <TableCell>User</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {itemRecords.map((item) => (
                        <RecordItem item={item} key={item.id} />
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    )
}

export default ItemRecordList
