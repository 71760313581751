import React, { useCallback, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Button, Typography } from '@mui/material'
import EditIcon from '@mui/icons-material/Edit'
import DeleteForeverIcon from '@mui/icons-material/DeleteForever'
import { checkUserRole } from '../../common/checkUserRole'
import { roleListId, roleType } from '../../config/roleList'
import { DELETE_CONFIRM } from '../../constants/form'
import { deleteDoorStyle } from '../../store/actions/doorStyleAction'
import BasicModal from '../modal/BasicModal'
import Edit from './Edit'
import AddNew from '../doorColor/AddNew'
import DoorColorList from '../doorColor/DoorColorList'

const DoorStyleList = ({ doorStyle, doorCategory }) => {
    const dispatch = useDispatch()
    const { userInfo } = useSelector((state) => state.auth)
    const { roleList } = useSelector((state) => state.user)
    const [editOpen, setEditOpen] = useState(false)
    const [colorOpen, setColorOpen] = useState(false)
    const handleDelete = useCallback(
        (id) => {
            if (window.confirm(DELETE_CONFIRM)) {
                dispatch(deleteDoorStyle(id))
            }
        },
        [dispatch]
    )

    return (
        <>
            <BasicModal open={editOpen} setOpen={setEditOpen}>
                <Edit id={doorStyle.id} setOpen={setEditOpen} />
            </BasicModal>
            <BasicModal open={colorOpen} setOpen={setColorOpen}>
                <AddNew doorStyleId={doorStyle.id} setOpen={setColorOpen} />
            </BasicModal>
            <div style={{ marginBottom: '10px' }}>
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                    }}
                >
                    <Typography gutterBottom>
                        {doorCategory?.name} / {doorStyle?.name}
                    </Typography>
                    <EditIcon
                        onClick={() =>
                            checkUserRole(
                                userInfo,
                                roleList,
                                roleListId.DOOR_STYLE,
                                roleType.EDIT
                            )
                                ? setEditOpen(true)
                                : alert('Not Permit User')
                        }
                        style={{
                            marginLeft: '20px',
                            marginRight: '20px',
                            cursor: 'pointer',
                            fontSize: '15px',
                        }}
                    />
                    <DeleteForeverIcon
                        onClick={() =>
                            checkUserRole(
                                userInfo,
                                roleList,
                                roleListId.DOOR_STYLE,
                                roleType.DELETE
                            )
                                ? handleDelete(doorStyle.id)
                                : alert('Not Permit User')
                        }
                        style={{
                            color: 'red',
                            cursor: 'pointer',
                            fontSize: '15px',
                            marginRight: '20px',
                        }}
                    />
                    <Button
                        color="secondary"
                        variant="outlined"
                        size="small"
                        onClick={() =>
                            checkUserRole(
                                userInfo,
                                roleList,
                                roleListId.DOOR_COLOR,
                                roleType.CREATE
                            )
                                ? setColorOpen(true)
                                : alert('Not Permit User')
                        }
                    >
                        Add New Door Color
                    </Button>
                </div>
                <div
                    style={{
                        display: 'flex',
                        gap: '20px',
                        margin: '20px',
                        flexWrap: 'wrap',
                    }}
                >
                    {doorStyle?.doorcolors?.map((color) => (
                        <DoorColorList key={color.id} doorColor={color} />
                    ))}
                </div>
            </div>
        </>
    )
}

export default DoorStyleList
