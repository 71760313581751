import React from 'react'
import { useDispatch } from 'react-redux'
import * as Yup from 'yup'
import { Container } from '@mui/material'
import AppForm from '../form/AppForm'
import AppInput from '../form/AppInput'
import SubmitButton from '../form/SubmitButton'
import Header from '../form/Header'
import { formTypeList } from '../../constants/form'
import {
    addNewDoorCategory,
    updateDoorCategory,
} from '../../store/actions/doorCategoryAction'

const validationSchema = Yup.object().shape({
    name: Yup.string().required().label('Name'),
})

const SubmitForm = ({ formType, setOpen, propsValue = null }) => {
    const dispatch = useDispatch()
    return (
        <Container>
            <Header
                title={`${formType === formTypeList.NEW ? 'ADD' : ''}${
                    formType === formTypeList.EDIT ? 'EDIT' : ''
                } DOOR CATEGORY`}
            />
            <AppForm
                initialValues={{
                    name: propsValue ? propsValue.name : '',
                }}
                onSubmit={(values) => {
                    const submitValues =
                        formType === formTypeList.NEW
                            ? values
                            : { ...propsValue, ...values }
                    formType === formTypeList.NEW
                        ? dispatch(addNewDoorCategory(submitValues))
                        : dispatch(updateDoorCategory(submitValues))
                    setOpen(false)
                }}
                validationSchema={validationSchema}
            >
                <AppInput
                    name="name"
                    label="Name"
                    type="text"
                    inputProps={{
                        maxLength: 100,
                    }}
                    required={true}
                />

                <SubmitButton title="Submit" />
            </AppForm>
        </Container>
    )
}

export default SubmitForm
