import { Button, TableCell, TableRow } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import { green, red } from '@mui/material/colors'
import BlockIcon from '@mui/icons-material/Block'
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline'
import { roleListId, roleType } from '../../config/roleList'
import { checkUserRole } from '../../common/checkUserRole'
import { useCallback } from 'react'
import { DELETE_CONFIRM } from '../../constants/form'

import { deleteDefectiveItem } from '../../store/actions/defectiveItemAction'
import moment from 'moment'

const DefectiveItemList = ({ defectiveItem }) => {
    const dispatch = useDispatch()
    const { userInfo } = useSelector((state) => state.auth)
    const { roleList } = useSelector((state) => state.user)

    const handleDelete = useCallback(
        (id) => {
            if (window.confirm(DELETE_CONFIRM)) {
                dispatch(deleteDefectiveItem(id, defectiveItem?.item?.id))
            }
        },
        [dispatch, defectiveItem?.item?.id]
    )

    return (
        <TableRow>
            <TableCell>
                {moment(defectiveItem?.createdAt).format('ll')}
            </TableCell>
            <TableCell>{defectiveItem?.supplier?.name}</TableCell>
            <TableCell>{defectiveItem?.item?.itemSKU}</TableCell>
            <TableCell>
                {defectiveItem.defectiveType === 1 ? '+' : '-'}
                {defectiveItem.quantity}
            </TableCell>
            <TableCell align="center">
                {defectiveItem.reOrder ? (
                    <CheckCircleOutlineIcon style={{ color: green[500] }} />
                ) : (
                    <BlockIcon style={{ color: red[500] }} />
                )}
            </TableCell>
            <TableCell></TableCell>
            <TableCell>
                <div className="button-box">
                    {!defectiveItem.reOrder && (
                        <Button
                            size="small"
                            variant="outlined"
                            color="error"
                            onClick={() =>
                                checkUserRole(
                                    userInfo,
                                    roleList,
                                    roleListId.DEFECTIVE_ITEM,
                                    roleType.DELETE
                                )
                                    ? handleDelete(defectiveItem.id)
                                    : alert('Not Permit User')
                            }
                        >
                            Delete
                        </Button>
                    )}
                </div>
            </TableCell>
        </TableRow>
    )
}

export default DefectiveItemList
