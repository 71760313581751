/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useCallback, useMemo, useRef, useState } from 'react'
import _ from 'lodash'
import TableCell from '@mui/material/TableCell'
import {
    Box,
    Button,
    Modal,
    Paper,
    Stack,
    Switch,
    TextField,
    Tooltip,
} from '@mui/material'
import TableRow from '@mui/material/TableRow'
import { styled } from '@mui/material/styles'
import { useDispatch } from 'react-redux'
import { MdDeleteForever, MdOutlineNoteAlt } from 'react-icons/md'
import { IoMdCloseCircleOutline } from 'react-icons/io'
import AppModal from '../AppModal'
import {
    addItemNote,
    deleteOrderItem,
    updateDoorDirection,
    updateFinishedOption,
    updateItemAssembly,
    updateItemQty,
} from '../../store/actions/orderAction'
import doorDirectionList, {
    finishedEndList,
} from '../../constants/doorDirection'
import colorCode from '../../constants/colorCode'

const getDotColor = (stock) => {
    if (stock <= 5) return 'red'
    if (stock <= 20) return 'yellow'
    return 'green'
}

export const StockDot = ({ productColorStock }) => (
    <span
        style={{
            display: 'inline-block',
            width: '15px',
            height: '15px',
            borderRadius: '50%',
            backgroundColor: getDotColor(productColorStock),
        }}
    />
)

const AntSwitch = styled(Switch)(({ theme }) => ({
    width: 28,
    height: 16,
    padding: 0,
    display: 'flex',
    '&:active': {
        '& .MuiSwitch-thumb': {
            width: 15,
        },
        '& .MuiSwitch-switchBase.Mui-checked': {
            transform: 'translateX(9px)',
        },
    },
    '& .MuiSwitch-switchBase': {
        padding: 2,
        '&.Mui-checked': {
            transform: 'translateX(12px)',
            color: '#fff',
            '& + .MuiSwitch-track': {
                opacity: 1,
                backgroundColor:
                    theme.palette.mode === 'dark'
                        ? colorCode.PRIMARY
                        : colorCode.PRIMARY,
            },
        },
    },
    '& .MuiSwitch-thumb': {
        boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
        width: 12,
        height: 12,
        borderRadius: 6,
        transition: theme.transitions.create(['width'], {
            duration: 200,
        }),
    },
    '& .MuiSwitch-track': {
        borderRadius: 16 / 2,
        opacity: 1,
        backgroundColor:
            theme.palette.mode === 'dark'
                ? 'rgba(255,255,255,.35)'
                : 'rgba(0,0,0,.25)',
        boxSizing: 'border-box',
    },
}))

const AssemblyRequestComponent = ({
    assemblyRequest,
    orderItemId,
    orderStyleId,
}) => {
    const dispatch = useDispatch()
    const handleClick = useCallback(
        (e) => {
            const values = {
                assemblyRequest: e.target.checked,
                orderItemId,
                orderStyleId,
            }
            dispatch(updateItemAssembly(values))
        },
        [dispatch, orderItemId, orderStyleId]
    )
    return (
        <Stack direction="row" spacing={1} alignItems="center">
            <AntSwitch
                checked={assemblyRequest}
                onChange={(e) => handleClick(e)}
                inputProps={{ 'aria-label': 'assembly' }}
            />
        </Stack>
    )
}

const DoorDirectionComponent = ({
    doorDirection,
    orderItemId,
    orderStyleId,
}) => {
    const dispatch = useDispatch()
    const handleClick = useCallback(
        (selectedDoorDirection) => {
            const values = {
                doorDirection: selectedDoorDirection,
                orderItemId,
                orderStyleId,
            }
            dispatch(updateDoorDirection(values))
        },
        [dispatch, orderItemId, orderStyleId]
    )
    return (
        <div className="door-direction-box">
            <Tooltip title={doorDirectionList.LEFT}>
                <div
                    onClick={() => handleClick(doorDirectionList.LEFT)}
                    className={`door-direction-item ${
                        doorDirection === doorDirectionList.LEFT
                            ? 'door-direction-item-active'
                            : null
                    }`}
                >
                    L
                </div>
            </Tooltip>
            <Tooltip title={doorDirectionList.RIGHT}>
                <div
                    onClick={() => handleClick(doorDirectionList.RIGHT)}
                    className={`door-direction-item ${
                        doorDirection === doorDirectionList.RIGHT
                            ? 'door-direction-item-active'
                            : null
                    }`}
                >
                    R
                </div>
            </Tooltip>
            <Tooltip title="None">
                <div
                    onClick={() => handleClick(null)}
                    className="door-direction-item"
                >
                    -
                </div>
            </Tooltip>
        </div>
    )
}

const FinishedEndComponent = ({
    finishedOption,
    orderItemId,
    orderStyleId,
}) => {
    const dispatch = useDispatch()
    const handleClick = useCallback(
        (selectedFinishedOption) => {
            const values = {
                finishedOption: selectedFinishedOption,
                orderItemId,
                orderStyleId,
            }
            dispatch(updateFinishedOption(values))
        },
        [dispatch, orderItemId, orderStyleId]
    )
    return (
        <div className="finished-end-box">
            <Tooltip title={finishedEndList.LEFT}>
                <div
                    onClick={() => handleClick(finishedEndList.LEFT)}
                    className={`door-direction-item ${
                        finishedOption === finishedEndList.LEFT
                            ? 'door-direction-item-active'
                            : null
                    }`}
                >
                    L
                </div>
            </Tooltip>
            <Tooltip title={finishedEndList.BOTH}>
                <div
                    onClick={() => handleClick(finishedEndList.BOTH)}
                    className={`door-direction-item ${
                        finishedOption === finishedEndList.BOTH
                            ? 'door-direction-item-active'
                            : null
                    }`}
                >
                    B
                </div>
            </Tooltip>
            <Tooltip title={finishedEndList.RIGHT}>
                <div
                    onClick={() => handleClick(finishedEndList.RIGHT)}
                    className={`door-direction-item ${
                        finishedOption === finishedEndList.RIGHT
                            ? 'door-direction-item-active'
                            : null
                    }`}
                >
                    R
                </div>
            </Tooltip>
            <Tooltip title="None">
                <div
                    onClick={() => handleClick(null)}
                    className="door-direction-item"
                >
                    -
                </div>
            </Tooltip>
        </div>
    )
}

const OrderItemQty = ({ currentQty, orderItemId, orderStyleId }) => {
    const dispatch = useDispatch()
    const textFieldRef = useRef(null)
    const [quantity, setQty] = useState(currentQty)
    const apiCall = useRef(_.debounce(() => {}, 1000))

    const updateQtyViaAPI = useCallback(
        (value) => {
            const values = {
                quantity: value,
                orderItemId,
                orderStyleId,
            }
            dispatch(updateItemQty(values))
        },
        [dispatch, orderItemId, orderStyleId] // No dependencies required for this callback
    )

    const handleChange = (e) => {
        let input = e.target.value
        input = input.replace(/^0+/, '')

        const numberValue = parseInt(input, 10)

        if (Number.isNaN(numberValue) || numberValue < 1) {
            setQty(1)
            apiCall.current.cancel() // Cancel previous debounced call
            apiCall.current = _.debounce(() => updateQtyViaAPI(1), 1000)
            apiCall.current()
        } else if (numberValue >= 0 && numberValue <= 999) {
            setQty(numberValue)
            apiCall.current.cancel() // Cancel previous debounced call
            apiCall.current = _.debounce(
                () => updateQtyViaAPI(numberValue),
                1000
            )
            apiCall.current()
        }
    }

    const handleMouseOver = () => {
        if (textFieldRef.current) {
            textFieldRef.current.select()
        }
    }

    return (
        <TextField
            inputRef={textFieldRef}
            id={Math.random().toString()}
            name="qty"
            style={{ width: '80px' }}
            value={quantity}
            onChange={handleChange}
            onMouseOver={handleMouseOver}
            size="small"
            min={1}
            max={999}
            type="number"
        />
    )
}

const AddNodeComponent = ({
    dispatch,
    currentNote,
    orderItemId,
    orderStyleId,
    setAddNote,
}) => {
    const [note, setNote] = useState(currentNote)
    const handleClick = useCallback(
        (changedNote) => {
            setAddNote(false)
            const values = {
                note: changedNote,
                orderItemId,
                orderStyleId,
            }
            dispatch(addItemNote(values))
        },
        [dispatch, orderItemId, orderStyleId, setAddNote]
    )
    return (
        <div
            style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: '100vh',
            }}
        >
            <Paper style={{ maxWidth: '400px', padding: '20px' }}>
                <div style={{ textAlign: 'right', marginBottom: '20px' }}>
                    <IoMdCloseCircleOutline
                        style={{ cursor: 'pointer' }}
                        size={20}
                        onClick={() => setAddNote(false)}
                    />
                </div>

                <TextField
                    fullWidth
                    id={Math.random().toString()}
                    label="Note"
                    multiline
                    name="note"
                    rows={4}
                    onChange={(e) => setNote(e.target.value)}
                    defaultValue={currentNote}
                />
                <Button
                    style={{ marginTop: '20px' }}
                    variant="outlined"
                    fullWidth
                    onClick={() => handleClick(note)}
                >
                    Submit
                </Button>
            </Paper>
        </div>
    )
}

const OrderItemLists = ({
    orderItem,
    orderId,
    orderStyleId,
    draggableProvided,
}) => {
    const orderItemStock = useMemo(() => {
        if (orderItem?.isItem) {
            return orderItem?.item?.stock
        }
        const eachItemQty = orderItem?.productcolor?.productitems?.map(
            (productItem) => {
                const { quantity, item } = productItem
                const { stock } = item
                const tempStock = stock >= 0 ? stock : 0
                return Math.floor(tempStock / quantity) || 0
            }
        ) || [0]

        if (eachItemQty?.length === 0) {
            return 0
        }
        return Math.min(...eachItemQty)
    }, [orderItem])

    const dispatch = useDispatch()
    const [modalOpen, setModalOpen] = useState(false)
    const handleModelOpen = () => setModalOpen(true)
    const handleModalClose = () => setModalOpen(false)

    const [addNote, setAddNote] = useState(false)

    const handleDelete = useCallback(() => {
        dispatch(deleteOrderItem(orderItem.id, orderId))
    }, [dispatch, orderItem.id, orderId])

    const contentRef = useRef(null)
    return (
        <>
            <TableRow
                // ref={innerRef}
                ref={draggableProvided.innerRef}
                {...draggableProvided.draggableProps}
                {...draggableProvided.dragHandleProps}
                className={orderItem?.note ? 'noBottomBorder' : ''}
            >
                <TableCell>
                    <OrderItemQty
                        currentQty={orderItem.quantity}
                        orderItemId={orderItem.id}
                        orderStyleId={orderStyleId}
                    />
                </TableCell>
                <TableCell component="th" scope="row">
                    <div
                        style={{
                            display: 'flex',
                            gap: 5,
                            alignItems: 'center',
                        }}
                    >
                        <StockDot productColorStock={orderItemStock} />{' '}
                        {orderItem.skuNo}
                    </div>
                </TableCell>
                {orderItem.isItem ? (
                    <>
                        <TableCell>Item</TableCell>
                        <TableCell />
                        <TableCell />
                        <TableCell />
                        <TableCell>
                            $
                            {(orderItem.quantity * orderItem.basePrice).toFixed(
                                2
                            )}
                        </TableCell>
                    </>
                ) : (
                    <>
                        <TableCell>
                            {orderItem.productcolor.product.name}
                        </TableCell>
                        <TableCell>
                            {orderItem.productcolor.product.assemblyFee ? (
                                <AssemblyRequestComponent
                                    assemblyRequest={orderItem.assemblyRequest}
                                    orderItemId={orderItem.id}
                                    orderStyleId={orderStyleId}
                                />
                            ) : (
                                'N / A'
                            )}
                        </TableCell>
                        <TableCell>
                            {orderItem.productcolor.product.doorDirection ? (
                                <DoorDirectionComponent
                                    doorDirection={orderItem.doorDirection}
                                    orderItemId={orderItem.id}
                                    orderStyleId={orderStyleId}
                                />
                            ) : (
                                'N / A'
                            )}
                        </TableCell>
                        <TableCell>
                            {orderItem.productcolor.finishedEnd ? (
                                <FinishedEndComponent
                                    finishedOption={orderItem.finishedOption}
                                    orderItemId={orderItem.id}
                                    orderStyleId={orderStyleId}
                                />
                            ) : (
                                'N / A'
                            )}
                        </TableCell>
                        <TableCell>
                            $
                            {(orderItem.quantity * orderItem.basePrice).toFixed(
                                2
                            )}
                        </TableCell>
                    </>
                )}
                <TableCell>
                    <Modal
                        open={addNote}
                        onClose={() => setAddNote(false)}
                        aria-labelledby="add-note"
                        aria-describedby="add-item-note"
                    >
                        <Box ref={contentRef}>
                            <AddNodeComponent
                                currentNote={orderItem.note}
                                orderItemId={orderItem.id}
                                orderStyleId={orderStyleId}
                                setAddNote={setAddNote}
                                dispatch={dispatch}
                            />
                        </Box>
                    </Modal>
                    <AppModal
                        open={modalOpen}
                        cancelContent="Cancel"
                        submitContent="Delete"
                        title="Are you sure you want to delete"
                        cancelClick={handleModalClose}
                        submitClick={handleDelete}
                        danger
                        handleClose={handleModalClose}
                    />
                    <MdDeleteForever
                        color="#FF0000"
                        size={20}
                        style={{ cursor: 'pointer' }}
                        onClick={handleModelOpen}
                    />
                    <MdOutlineNoteAlt
                        size={20}
                        color={colorCode.GREY}
                        style={{ cursor: 'pointer', marginLeft: '10px' }}
                        onClick={() => setAddNote(true)}
                    />
                </TableCell>
            </TableRow>
            {orderItem?.note && (
                <TableRow
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                    <TableCell colSpan={8}>
                        <div className="dot-border">{orderItem.note}</div>
                    </TableCell>
                </TableRow>
            )}
        </>
    )
}

export default OrderItemLists
