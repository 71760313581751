import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { formTypeList } from '../../constants/form'
import { GET_ONE_DOOR_COLOR } from '../../store/types'
import Form from './Form'
import { getOneDoorColor } from '../../store/actions/doorColorAction'

const Edit = ({ id, setOpen }) => {
    const dispatch = useDispatch()
    const { doorColor } = useSelector((state) => state.doorCategory)
    useEffect(() => {
        dispatch(getOneDoorColor(id))
        return () => {
            dispatch({
                type: GET_ONE_DOOR_COLOR,
                payload: null,
            })
        }
    }, [dispatch, id])
    return doorColor ? (
        <Form
            formType={formTypeList.EDIT}
            setOpen={setOpen}
            propsValue={doorColor}
        />
    ) : null
}

export default Edit
