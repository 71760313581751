import { Container } from '@mui/material'
import React from 'react'
import { useDispatch } from 'react-redux'
import * as Yup from 'yup'
import Header from '../form/Header'
import { formTypeList } from '../../constants/form'
import AppForm from '../form/AppForm'
import {
    addNewOrderPayment,
    updateOrderPayment,
} from '../../store/actions/orderPaymentAction'
import AppSelect from '../form/AppSelect'
import selectList from '../../constants/selectList.json'
import AppInput from '../form/AppInput'
import SubmitButton from '../form/SubmitButton'

const validationSchema = Yup.object().shape({
    paymentType: Yup.string().required().label('Payment Type'),
    amount: Yup.number().required().label('Amount'),
    paymentDate: Yup.string().required().label('Payment Date'),
})

const OrderPaymentForm = ({
    formType,
    setOpen,
    orderId,
    propsValue = null,
}) => {
    const dispatch = useDispatch()
    return (
        <Container>
            <Header
                title={`${formType === formTypeList.NEW ? 'ADD' : ''}${
                    formType === formTypeList.EDIT ? 'EDIT' : ''
                } ORDER PAYMENT`}
            />
            <AppForm
                initialValues={{
                    paymentType: propsValue
                        ? propsValue.paymentType
                        : selectList.paymentTypeDW.default,
                    cardNo: propsValue ? propsValue.cardNo : '',
                    cardType: propsValue ? propsValue.cardType : '',
                    amount: propsValue ? propsValue.amount : 0,
                    paymentDate: propsValue ? propsValue.paymentDate : '',
                    refNo: propsValue ? propsValue.refNo : '',
                    message: propsValue ? propsValue.message : '',
                }}
                onSubmit={(values) => {
                    const submitValues =
                        formType === formTypeList.NEW
                            ? { ...values, orderId }
                            : { ...propsValue, ...values }
                    formType === formTypeList.NEW
                        ? dispatch(addNewOrderPayment(submitValues))
                        : dispatch(updateOrderPayment(submitValues, orderId))
                    setOpen(false)
                }}
                validationSchema={validationSchema}
            >
                <AppSelect
                    name="paymentType"
                    label="Payment Type"
                    options={
                        process.env.REACT_APP_CLIENT === 'dw'
                            ? selectList.paymentTypeDW.list
                            : selectList.paymentTypeWLink.list
                    }
                />
                <AppInput
                    name="cardNo"
                    label="Card No"
                    type="text"
                    inputProps={{ maxLength: 20 }}
                />
                <AppInput
                    name="cardType"
                    label="Card Type"
                    type="text"
                    inputProps={{ maxLength: 20 }}
                />
                <AppInput
                    name="amount"
                    label="Amount"
                    type="number"
                    inputProps={{ maxLength: 10 }}
                    required={true}
                />
                <AppInput
                    name="paymentDate"
                    // label="Payment Date"
                    type="date"
                    inputProps={{ maxLength: 20 }}
                    required={true}
                />
                <AppInput
                    name="refNo"
                    label="Reference No"
                    type="text"
                    inputProps={{ maxLength: 50 }}
                />
                <AppInput
                    name="message"
                    label="Message"
                    type="text"
                    inputProps={{ maxLength: 50 }}
                />
                <SubmitButton title="Submit" />
            </AppForm>
        </Container>
    )
}

export default OrderPaymentForm
