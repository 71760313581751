import { useEffect, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import * as XLSX from 'xlsx'
import { saveAs } from 'file-saver'
import Layout from '../../components/Layout'
import { CHANGE_ITEMS_PAGE } from '../../store/types'
import PagnationComponent from '../../components/PagnationComponent'
import { Button, Container, Typography } from '@mui/material'
import { getAllItems } from '../../store/actions/itemAction'
import ItemInfos from '../../components/item/ItemInfos'
import { roleListId, roleType } from '../../config/roleList'
import { checkUserRole } from '../../common/checkUserRole'
import SearchItem from '../../components/item/SearchItem'
import request from '../../config/request'
import { getErrors } from '../../store/actions/errorActions'
import { controlLoading } from '../../store/actions/loadingActions'

const Items = () => {
    const dispatch = useDispatch()
    const { userToken } = useSelector((state) => state.auth)
    const navigate = useNavigate()
    const itemState = useSelector((state) => state.item)
    const { items, pages, page, count, keyword } = itemState
    const { userInfo } = useSelector((state) => state.auth)
    const { roleList } = useSelector((state) => state.user)

    const pageNumber = page || 1
    useEffect(() => {
        dispatch(getAllItems(keyword, pageNumber))
    }, [dispatch, keyword, pageNumber])

    const changePage = useCallback(
        (page) => {
            dispatch({ type: CHANGE_ITEMS_PAGE, payload: page })
        },
        [dispatch]
    )

    const downloadStock = useCallback(async () => {
        try {
            dispatch(controlLoading(true))
            const res = await request.get('item/itemstock', {
                headers: { Authorization: `Bearer ${userToken}` },
            })
            const stockData = res.data.data

            const worksheet = XLSX.utils.json_to_sheet(stockData)

            const workbook = XLSX.utils.book_new()
            XLSX.utils.book_append_sheet(workbook, worksheet, 'StockData')

            const excelBuffer = XLSX.write(workbook, {
                bookType: 'xlsx',
                type: 'array',
            })

            const blob = new Blob([excelBuffer], {
                type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
            })

            saveAs(blob, `ItemStock${Date.now()}.xlsx`)
            dispatch(controlLoading(false))
        } catch (e) {
            dispatch(getErrors('Download stock error'))
            dispatch(controlLoading(false))
        }
    }, [dispatch, userToken])
    return (
        <Layout>
            <Container>
                <Button
                    color="info"
                    variant="outlined"
                    onClick={() =>
                        checkUserRole(
                            userInfo,
                            roleList,
                            roleListId.ITEM,
                            roleType.CREATE
                        )
                            ? navigate(`/additem`)
                            : alert('Not Permit User')
                    }
                >
                    Add New Item
                </Button>
                <Button
                    color="success"
                    variant="outlined"
                    onClick={() => downloadStock()}
                    style={{ marginLeft: '10px' }}
                >
                    Download Stock
                </Button>
                <SearchItem displayKeyword={keyword} />
                <Typography
                    variant="body2"
                    gutterBottom
                    style={{ paddingBottom: '10px', paddingTop: '10px' }}
                >
                    Count: {count}
                </Typography>
                {items && <ItemInfos items={items} />}
                <PagnationComponent
                    page={pageNumber}
                    pages={pages}
                    func={changePage}
                />
            </Container>
        </Layout>
    )
}

export default Items
