import * as React from 'react';
import Modal from '@mui/material/Modal';
import Button from '@mui/material/Button';
import Category from './Category';

export default function AddNewStyle({ orderId }) {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <div>
      <Button variant="outlined" onClick={handleOpen}>
        Add New Style
      </Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description">
        <div
          style={{
            width: '100%',
          }}>
          <div className="order-product-add-style">
            <Category orderId={orderId} handleClose={handleClose} />
          </div>
        </div>
      </Modal>
    </div>
  );
}
