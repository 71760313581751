import { Button, TableCell, TableRow } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { roleListId, roleType } from '../../config/roleList'
import { checkUserRole } from '../../common/checkUserRole'

const ItemList = ({ item }) => {
    const navigate = useNavigate()
    const { userInfo } = useSelector((state) => state.auth)
    const { roleList } = useSelector((state) => state.user)
    return (
        <>
            <TableRow>
                <TableCell>
                    <img
                        src={item?.picLink}
                        alt="item pic"
                        className="display-item-pic"
                        onError={(event) => {
                            event.target.src =
                                'https://res.cloudinary.com/home-space/image/upload/v1633013512/Products/image-not-available_tnweud.png'
                            event.onerror = null
                        }}
                    />
                </TableCell>
                <TableCell>{item.itemSKU}</TableCell>
                <TableCell>{item.itemName}</TableCell>
                <TableCell>${item.MSRPinCAD}</TableCell>
                {/* <TableCell>{item.MSRPinUSD}</TableCell> */}
                <TableCell>{item.stock}</TableCell>
                <TableCell>
                    {item?.warehousemaps?.map((location) => (
                        <div key={location.id}>
                            {location.location} ({location.warehouse.shortName})
                        </div>
                    ))}
                </TableCell>
                <TableCell>{item.alertStock}</TableCell>

                <TableCell>
                    <div className="button-box">
                        <Button
                            size="small"
                            variant="outlined"
                            color="primary"
                            onClick={() =>
                                checkUserRole(
                                    userInfo,
                                    roleList,
                                    roleListId.ITEM,
                                    roleType.READ
                                )
                                    ? navigate(`/item/${item.id}`)
                                    : alert('Not Permit User')
                            }
                        >
                            Info
                        </Button>
                        <Button
                            size="small"
                            variant="outlined"
                            color="secondary"
                            onClick={() =>
                                checkUserRole(
                                    userInfo,
                                    roleList,
                                    roleListId.ITEM,
                                    roleType.EDIT
                                )
                                    ? navigate(`/edititem/${item.id}`)
                                    : alert('Not Permit User')
                            }
                        >
                            Edit
                        </Button>
                    </div>
                </TableCell>
            </TableRow>
        </>
    )
}

export default ItemList
