import React, { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { addOrderTax, deleteOrderTax } from '../../store/actions/orderAction'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Paper from '@mui/material/Paper'
import { Button, Divider } from '@mui/material'
import * as Yup from 'yup'
import AppForm from '../form/AppForm'
import AppInput from '../form/AppInput'
import SubmitButton from '../form/SubmitButton'
import { DELETE_CONFIRM } from '../../constants/form'

const TaxItem = ({ tax, orderId }) => {
    const dispatch = useDispatch()
    const handleDelete = useCallback(
        (id) => {
            if (window.confirm(DELETE_CONFIRM)) {
                dispatch(deleteOrderTax(id, orderId))
            }
        },
        [orderId, dispatch]
    )
    return (
        <TableRow>
            <TableCell>{tax?.taxLabel}</TableCell>
            <TableCell>{tax?.taxRate}%</TableCell>
            <TableCell style={{ width: '50px' }}>
                <Button
                    size="small"
                    onClick={() => handleDelete(tax.id)}
                    variant="outlined"
                    color="error"
                >
                    Delete
                </Button>
            </TableCell>
        </TableRow>
    )
}

const validationSchema = Yup.object().shape({
    taxLabel: Yup.string().required().label('Tax Label'),
    taxRate: Yup.number().required().label('Tax Rate'),
})

const TaxesForm = () => {
    const dispatch = useDispatch()
    const { order } = useSelector((state) => state.order)
    return (
        <div>
            {order?.ordertaxs?.length > 0 && (
                <div>
                    <TableContainer component={Paper}>
                        <Table aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell>Detail</TableCell>
                                    <TableCell>Tax Rate</TableCell>
                                    <TableCell
                                        style={{ width: '50px' }}
                                    ></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {order?.ordertaxs?.map((tax) => (
                                    <TaxItem
                                        key={tax.id}
                                        tax={tax}
                                        orderId={order.id}
                                    />
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </div>
            )}
            <div style={{ marginTop: '30px' }} />
            <Divider>Add New Tax</Divider>
            <div>
                <AppForm
                    initialValues={{
                        taxLabel: '',
                        taxRate: '',
                    }}
                    onSubmit={(values, { resetForm }) => {
                        const submitValues = { ...values, orderId: order.id }
                        dispatch(addOrderTax(submitValues))
                        resetForm()
                    }}
                    validationSchema={validationSchema}
                >
                    <AppInput
                        name="taxLabel"
                        label="Tax Label"
                        type="text"
                        inputProps={{
                            maxLength: 20,
                        }}
                        required={true}
                    />
                    <AppInput
                        name="taxRate"
                        label="Tax Rate"
                        type="number"
                        inputProps={{
                            maxLength: 10,
                        }}
                        required={true}
                    />
                    <SubmitButton title="Submit" />
                </AppForm>
            </div>
        </div>
    )
}

export default TaxesForm
