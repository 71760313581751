import {
    CHANGE_PRODUCT_COLORS_PAGE,
    GET_ALL_PRODUCT_COLORS,
    GET_ONE_PRODUCT_COLOR,
    GET_ONE_PRODUCT_ITEM,
    GET_PRODUCT_COLOR_ITEMS,
    SEARCH_PRODUCT_COLORS,
} from '../types'

const initialState = {
    productColors: null,
    productColor: null,
    searchItems: null,
    pages: null,
    page: null,
    count: 0,
    keyword: '',
    productItem: null,
}

const productColorReducer = (state = initialState, action) => {
    const { type, payload } = action
    switch (type) {
        case GET_ALL_PRODUCT_COLORS:
            return {
                ...state,
                productColors: payload.productColors,
                pages: payload.pages,
                page: payload.page,
                count: payload.count,
                keyword: payload.keyword,
                productColor: null,
            }
        case GET_PRODUCT_COLOR_ITEMS:
            return {
                ...state,
                searchItems: payload,
            }
        case SEARCH_PRODUCT_COLORS:
            return {
                ...state,
                keyword: payload.keyword,
                page: 1,
            }
        case CHANGE_PRODUCT_COLORS_PAGE:
            return {
                ...state,
                page: payload,
            }
        case GET_ONE_PRODUCT_COLOR:
            return {
                ...state,
                productColor: payload,
            }
        case GET_ONE_PRODUCT_ITEM:
            return {
                ...state,
                productItem: payload,
            }
        default:
            return state
    }
}

export default productColorReducer
