import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import Layout from '../../components/Layout'
import ItemForm from '../../components/item/ItemForm'
import { formTypeList } from '../../constants/form'
import {
    getAllWarehouseMapsItem,
    getOneItem,
} from '../../store/actions/itemAction'

const EditItem = () => {
    const dispatch = useDispatch()
    const { id } = useParams()
    const itemState = useSelector((state) => state.item)
    const { item } = itemState

    useEffect(() => {
        dispatch(getOneItem(id))
    }, [dispatch, id])

    useEffect(() => {
        dispatch(getAllWarehouseMapsItem())
    }, [dispatch])

    return (
        <Layout>
            {item ? (
                <ItemForm formType={formTypeList.EDIT} propsValue={item} />
            ) : null}
        </Layout>
    )
}

export default EditItem
