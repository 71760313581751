import React, { useEffect, useState } from 'react'
import SubmitButton from '../components/form/SubmitButton'
import AppForm from '../components/form/AppForm'
import AppInput from '../components/form/AppInput'
import * as Yup from 'yup'
import Layout from '../components/Layout'
import { Checkbox, Container, FormControlLabel } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import { addNewUserAction, getRoleList } from '../store/actions/usersAction'
import { styled } from '@mui/system'
import { useNavigate } from 'react-router-dom'
import Header from '../components/form/Header'

const FlexContainer = styled('div')({
    display: 'flex',
    gap: '16px', // Adjust the gap between checkboxes
})

const validationSchema = Yup.object().shape({
    firstName: Yup.string().required().label('First Name'),
    lastName: Yup.string().required().label('Last Name'),
    email: Yup.string().required().email().label('Email'),
    password: Yup.string()
        .required()
        .min(4, 'must over 4 characters')
        .label('Password'),
})

const AddNewUser = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const { roleList } = useSelector((state) => state.user)
    const [checkedValues, setCheckedValues] = useState([])
    useEffect(() => {
        dispatch(getRoleList())
    }, [dispatch])

    const handleCheckboxChange = (value) => {
        if (checkedValues.includes(value)) {
            setCheckedValues(checkedValues?.filter((item) => item !== value))
        } else {
            setCheckedValues([...checkedValues, value])
        }
    }

    return (
        <Layout>
            <Container>
                <Header title="ADD NEW USER" />
                <AppForm
                    initialValues={{
                        firstName: '',
                        lastName: '',
                        email: '',
                        password: '',
                        phone: '',
                        address: '',
                    }}
                    onSubmit={(values) => {
                        values['roles'] = checkedValues.join(',')
                        dispatch(addNewUserAction(values, navigate))
                    }}
                    validationSchema={validationSchema}
                >
                    <AppInput
                        name="firstName"
                        label="First Name"
                        type="text"
                        inputProps={{ maxLength: 50 }}
                        required={true}
                    />
                    <AppInput
                        name="lastName"
                        label="Last Name"
                        type="text"
                        inputProps={{ maxLength: 50 }}
                        required={true}
                    />
                    <AppInput
                        name="email"
                        label="Email"
                        type="text"
                        inputProps={{ maxLength: 200 }}
                        required={true}
                    />
                    <AppInput
                        name="password"
                        label="Password"
                        type="password"
                        inputProps={{ maxLength: 30 }}
                        required={true}
                    />
                    <AppInput
                        name="phone"
                        label="Phone"
                        type="text"
                        inputProps={{ maxLength: 30 }}
                    />
                    <AppInput
                        name="address"
                        label="Address"
                        type="text"
                        inputProps={{ maxLength: 250 }}
                    />
                    {roleList && (
                        <div>
                            {roleList.map((role) => (
                                <div key={role.id}>
                                    <div>{role.name}</div>
                                    <div className="newuser-roles">
                                        <FlexContainer>
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        checked={checkedValues.includes(
                                                            role.readRole
                                                        )}
                                                        onChange={() =>
                                                            handleCheckboxChange(
                                                                role.readRole
                                                            )
                                                        }
                                                    />
                                                }
                                                label="Read"
                                            />
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        checked={checkedValues.includes(
                                                            role.createRole
                                                        )}
                                                        onChange={() =>
                                                            handleCheckboxChange(
                                                                role.createRole
                                                            )
                                                        }
                                                    />
                                                }
                                                label="Create"
                                            />
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        checked={checkedValues.includes(
                                                            role.editRole
                                                        )}
                                                        onChange={() =>
                                                            handleCheckboxChange(
                                                                role.editRole
                                                            )
                                                        }
                                                    />
                                                }
                                                label="Edit"
                                            />
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        checked={checkedValues.includes(
                                                            role.deleteRole
                                                        )}
                                                        onChange={() =>
                                                            handleCheckboxChange(
                                                                role.deleteRole
                                                            )
                                                        }
                                                    />
                                                }
                                                label="Delete"
                                            />
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        checked={checkedValues.includes(
                                                            role.approvalRole
                                                        )}
                                                        onChange={() =>
                                                            handleCheckboxChange(
                                                                role.approvalRole
                                                            )
                                                        }
                                                    />
                                                }
                                                label="Approval"
                                            />
                                        </FlexContainer>
                                    </div>
                                </div>
                            ))}
                        </div>
                    )}
                    <SubmitButton title="Submit" />
                </AppForm>
            </Container>
        </Layout>
    )
}

export default AddNewUser
