import React from 'react'
import { useDispatch } from 'react-redux'
import * as Yup from 'yup'
import { Container } from '@mui/material'
import AppForm from '../form/AppForm'
import AppInput from '../form/AppInput'
import SubmitButton from '../form/SubmitButton'
import Header from '../form/Header'
import { formTypeList } from '../../constants/form'
import {
    addNewDoorStyle,
    updateDoorStyle,
} from '../../store/actions/doorStyleAction'

const validationSchema = Yup.object().shape({
    name: Yup.string().required().label('Name'),
})

const Form = ({
    formType,
    setOpen,
    doorCategoryId = null,
    propsValue = null,
}) => {
    const dispatch = useDispatch()
    return (
        <Container>
            <Header
                title={`${formType === formTypeList.NEW ? 'ADD' : ''}${
                    formType === formTypeList.EDIT ? 'EDIT' : ''
                } DOOR STYLE`}
            />
            <AppForm
                initialValues={{
                    name: propsValue ? propsValue.name : '',
                }}
                onSubmit={(values) => {
                    const submitValues =
                        formType === formTypeList.NEW
                            ? { ...values, doorCategoryId }
                            : { ...propsValue, ...values }
                    formType === formTypeList.NEW
                        ? dispatch(
                              addNewDoorStyle(submitValues),
                              setOpen(false)
                          )
                        : dispatch(
                              updateDoorStyle(submitValues),
                              setOpen(false)
                          )
                }}
                validationSchema={validationSchema}
            >
                <AppInput
                    name="name"
                    label="Name"
                    type="text"
                    inputProps={{
                        maxLength: 100,
                    }}
                    required={true}
                />

                <SubmitButton title="Submit" />
            </AppForm>
        </Container>
    )
}

export default Form
