import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Layout from '../components/Layout'
import { Button, Container } from '@mui/material'
import { getAllProductCategory } from '../store/actions/productCategoryAction'
import { roleListId, roleType } from '../config/roleList'
import { checkUserRole } from '../common/checkUserRole'
import BasicModal from '../components/modal/BasicModal'
import AddNew from '../components/productCategory/AddNew'
import ProductCategoryList from '../components/productCategory/ProductCategoryList'

const ProductCategory = () => {
    const dispatch = useDispatch()
    const { productCategories } = useSelector((state) => state.productCategory)
    const { userInfo } = useSelector((state) => state.auth)
    const { roleList } = useSelector((state) => state.user)
    const [open, setOpen] = useState(false)

    useEffect(() => {
        dispatch(getAllProductCategory())
    }, [dispatch])

    return (
        <Layout>
            <Container>
                <BasicModal open={open} setOpen={setOpen}>
                    <AddNew setOpen={setOpen} />
                </BasicModal>

                <Button
                    color="info"
                    variant="outlined"
                    style={{ marginBottom: '20px' }}
                    onClick={() =>
                        checkUserRole(
                            userInfo,
                            roleList,
                            roleListId.PRODUCT_CATEGORY,
                            roleType.CREATE
                        )
                            ? setOpen(true)
                            : alert('Not Permit User')
                    }
                >
                    Add New Product Category
                </Button>
                {productCategories?.map((item) => (
                    <ProductCategoryList key={item.id} productCategory={item} />
                ))}
            </Container>
        </Layout>
    )
}

export default ProductCategory
