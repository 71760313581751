import React, { useMemo } from 'react'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableRow from '@mui/material/TableRow'
import Paper from '@mui/material/Paper'

const TABLE_CELL_WIDTH = 250

const OrderPaymentDetail = ({ order }) => {
    const totalPaid = useMemo(() => {
        if (!order || !order.orderpayments) {
            return 0
        }
        return order.orderpayments.reduce(
            (total, payment) => total + payment.amount,
            0
        )
    }, [order])
    return (
        <TableContainer component={Paper} style={{ marginTop: '20px' }}>
            <Table aria-label="simple table">
                <TableBody>
                    <TableRow>
                        <TableCell width={TABLE_CELL_WIDTH}>
                            Invoice No
                        </TableCell>
                        <TableCell>{order?.invoiceNumber}</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell width={TABLE_CELL_WIDTH}>
                            Invoice Date
                        </TableCell>
                        <TableCell>{order?.invoiceDate}</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell width={TABLE_CELL_WIDTH}>
                            PO Number
                        </TableCell>
                        <TableCell>{order?.poNumber}</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell width={TABLE_CELL_WIDTH}>
                            Company Name
                        </TableCell>
                        <TableCell>{order.customer.company}</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell width={TABLE_CELL_WIDTH}>
                            Invoice Amount
                        </TableCell>
                        <TableCell>${order?.invoiceAmountDecimal}</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell width={TABLE_CELL_WIDTH}>
                            Total Paid
                        </TableCell>
                        <TableCell>${totalPaid?.toFixed(2)}</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell width={TABLE_CELL_WIDTH}>
                            Amount Due
                        </TableCell>
                        <TableCell>
                            $
                            {(
                                (order?.invoiceAmountDecimal || 0) -
                                (totalPaid || 0)
                            ).toFixed(2)}
                        </TableCell>
                    </TableRow>
                </TableBody>
            </Table>
        </TableContainer>
    )
}

export default OrderPaymentDetail
