import request from '../../config/request'
import { errorsReturn } from '../errorsReturn'
import {
    GET_ALL_DEFECTIVE_ITEMS,
    GET_ALL_DEFECTIVE_ITEM_SUPPLIERS,
    GET_DEFECTIVE_ITEM_SEARCH_ITEMS,
} from '../types'
import { logout, renewUserToken } from './authAction'
import { getErrors } from './errorActions'
import { controlLoading } from './loadingActions'

export const getAllDefectiveItems =
    (keyword = '', pageNumber = '') =>
    async (dispatch, getState) => {
        if (pageNumber) {
            try {
                dispatch(controlLoading(true))
                const {
                    auth: { userToken },
                } = getState()
                let res = await request.get(
                    `defectiveitem/listall?keyword=${keyword}&pageNumber=${pageNumber}`,
                    {
                        headers: { Authorization: `Bearer ${userToken}` },
                    }
                )
                dispatch(renewUserToken(res))
                dispatch({
                    type: GET_ALL_DEFECTIVE_ITEMS,
                    payload: {
                        defectiveItems: res.data.data,
                        pages: res.data.pages,
                        page: res.data.page,
                        count: res.data.count,
                        keyword: keyword,
                    },
                })
                dispatch(controlLoading(false))
            } catch (e) {
                errorsReturn(e, dispatch, controlLoading, getErrors, logout)
            }
        }
    }

export const getSearchItems = (values) => async (dispatch, getState) => {
    try {
        dispatch(controlLoading(true))
        const {
            auth: { userToken },
        } = getState()

        let res = await request.post(`defectiveitem/searchitem`, values, {
            headers: { Authorization: `Bearer ${userToken}` },
        })
        dispatch(renewUserToken(res))
        dispatch({
            type: GET_DEFECTIVE_ITEM_SEARCH_ITEMS,
            payload: res.data.data,
        })
        dispatch(controlLoading(false))
    } catch (e) {
        errorsReturn(e, dispatch, controlLoading, getErrors, logout)
    }
}

export const getAllDefectiveItemSuppliers =
    () => async (dispatch, getState) => {
        try {
            dispatch(controlLoading(true))
            const {
                auth: { userToken },
            } = getState()

            let res = await request.get(`defectiveitem/listallsuppliers`, {
                headers: { Authorization: `Bearer ${userToken}` },
            })
            dispatch(renewUserToken(res))
            dispatch({
                type: GET_ALL_DEFECTIVE_ITEM_SUPPLIERS,
                payload: res.data.data,
            })
            dispatch(controlLoading(false))
        } catch (e) {
            errorsReturn(e, dispatch, controlLoading, getErrors, logout)
        }
    }

export const addNewDefectiveItem = (values) => async (dispatch, getState) => {
    try {
        dispatch(controlLoading(true))
        const {
            auth: { userToken },
        } = getState()
        let res = await request.post(`defectiveitem/create`, values, {
            headers: { Authorization: `Bearer ${userToken}` },
        })
        dispatch(renewUserToken(res))
        dispatch(controlLoading(false))
        dispatch(getAllDefectiveItems('', 1))
    } catch (e) {
        errorsReturn(e, dispatch, controlLoading, getErrors, logout)
    }
}

export const deleteDefectiveItem =
    (id, itemId) => async (dispatch, getState) => {
        try {
            dispatch(controlLoading(true))
            const {
                auth: { userToken },
            } = getState()
            let res = await request.delete(
                `defectiveitem/delete/${id}`,

                {
                    headers: { Authorization: `Bearer ${userToken}` },
                    params: { itemId },
                }
            )
            dispatch(renewUserToken(res))
            dispatch(controlLoading(false))
            dispatch(getAllDefectiveItems('', 1))
        } catch (e) {
            errorsReturn(e, dispatch, controlLoading, getErrors, logout)
        }
    }
