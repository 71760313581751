import request from '../../config/request'
import { errorsReturn } from '../errorsReturn'
import {
    GET_ALL_PRODUCT_COLORS,
    GET_ONE_PRODUCT_COLOR,
    GET_ONE_PRODUCT_ITEM,
    GET_PRODUCT_COLOR_ITEMS,
} from '../types'
import { logout, renewUserToken } from './authAction'
import { getErrors } from './errorActions'
import { controlLoading } from './loadingActions'
import { getOneProduct } from './productAction'

export const getAllProductColors =
    (keyword = '', pageNumber = '') =>
    async (dispatch, getState) => {
        if (pageNumber) {
            try {
                dispatch(controlLoading(true))
                const {
                    auth: { userToken },
                } = getState()
                let res = await request.get(
                    `productcolor/listall?keyword=${keyword}&pageNumber=${pageNumber}`,
                    {
                        headers: { Authorization: `Bearer ${userToken}` },
                    }
                )
                dispatch(renewUserToken(res))
                dispatch({
                    type: GET_ALL_PRODUCT_COLORS,
                    payload: {
                        productColors: res.data.data,
                        pages: res.data.pages,
                        page: res.data.page,
                        count: res.data.count,
                        keyword: keyword,
                    },
                })
                dispatch(controlLoading(false))
            } catch (e) {
                errorsReturn(e, dispatch, controlLoading, getErrors, logout)
            }
        }
    }

export const getOneProductColor = (id) => async (dispatch, getState) => {
    try {
        dispatch(controlLoading(true))
        const {
            auth: { userToken },
        } = getState()

        let res = await request.get(`productcolor/listone/${id}`, {
            headers: { Authorization: `Bearer ${userToken}` },
        })
        dispatch(renewUserToken(res))
        dispatch({ type: GET_ONE_PRODUCT_COLOR, payload: res.data.data })
        dispatch(controlLoading(false))
    } catch (e) {
        errorsReturn(e, dispatch, controlLoading, getErrors, logout)
    }
}

export const getOneProductItem = (id) => async (dispatch, getState) => {
    try {
        dispatch(controlLoading(true))
        const {
            auth: { userToken },
        } = getState()

        let res = await request.get(`productcolor/listoneitem/${id}`, {
            headers: { Authorization: `Bearer ${userToken}` },
        })
        dispatch(renewUserToken(res))
        dispatch({ type: GET_ONE_PRODUCT_ITEM, payload: res.data.data })
        dispatch(controlLoading(false))
    } catch (e) {
        errorsReturn(e, dispatch, controlLoading, getErrors, logout)
    }
}

export const getProductColorItems = (values) => async (dispatch, getState) => {
    try {
        dispatch(controlLoading(true))
        const {
            auth: { userToken },
        } = getState()

        let res = await request.post(`productcolor/searchitem`, values, {
            headers: { Authorization: `Bearer ${userToken}` },
        })
        dispatch(renewUserToken(res))
        dispatch({ type: GET_PRODUCT_COLOR_ITEMS, payload: res.data.data })
        dispatch(controlLoading(false))
    } catch (e) {
        errorsReturn(e, dispatch, controlLoading, getErrors, logout)
    }
}

export const addNewProductColor = (values) => async (dispatch, getState) => {
    try {
        dispatch(controlLoading(true))
        const {
            auth: { userToken },
        } = getState()
        let res = await request.post(`productcolor/create`, values, {
            headers: { Authorization: `Bearer ${userToken}` },
        })
        dispatch(renewUserToken(res))
        dispatch(controlLoading(false))
        // dispatch(getAllProductColors())
        dispatch(getOneProduct(values.productId))
    } catch (e) {
        errorsReturn(e, dispatch, controlLoading, getErrors, logout)
    }
}

export const addNewProductColorItem =
    (values) => async (dispatch, getState) => {
        try {
            dispatch(controlLoading(true))
            const {
                auth: { userToken },
                product: { product },
            } = getState()
            let res = await request.post(`productcolor/createitem`, values, {
                headers: { Authorization: `Bearer ${userToken}` },
            })
            dispatch(renewUserToken(res))
            dispatch(controlLoading(false))
            dispatch(getOneProduct(product.id))
        } catch (e) {
            errorsReturn(e, dispatch, controlLoading, getErrors, logout)
        }
    }

export const updateProductColor = (values) => async (dispatch, getState) => {
    try {
        dispatch(controlLoading(true))
        const {
            auth: { userToken },
            product: { product },
        } = getState()
        let res = await request.put(
            `productcolor/update/${values.id}`,
            values,
            {
                headers: { Authorization: `Bearer ${userToken}` },
            }
        )
        dispatch(renewUserToken(res))
        dispatch(controlLoading(false))
        dispatch(getOneProduct(product.id))
    } catch (e) {
        errorsReturn(e, dispatch, controlLoading, getErrors, logout)
    }
}

export const updateProductColorItem =
    (values) => async (dispatch, getState) => {
        try {
            dispatch(controlLoading(true))
            const {
                auth: { userToken },
            } = getState()
            let res = await request.put(
                `productcolor/updateitem/${values.id}`,
                values,
                {
                    headers: { Authorization: `Bearer ${userToken}` },
                }
            )
            dispatch(renewUserToken(res))
            dispatch(controlLoading(false))
            dispatch(getAllProductColors())
        } catch (e) {
            errorsReturn(e, dispatch, controlLoading, getErrors, logout)
        }
    }

export const deleteProductColor = (id) => async (dispatch, getState) => {
    try {
        dispatch(controlLoading(true))
        const {
            auth: { userToken },
        } = getState()
        let res = await request.delete(`productcolor/delete/${id}`, {
            headers: { Authorization: `Bearer ${userToken}` },
        })
        dispatch(renewUserToken(res))
        dispatch(controlLoading(false))
        dispatch(getAllProductColors())
    } catch (e) {
        errorsReturn(e, dispatch, controlLoading, getErrors, logout)
    }
}

export const deleteProductColorItem = (id) => async (dispatch, getState) => {
    try {
        dispatch(controlLoading(true))
        const {
            auth: { userToken },
            product: { product },
        } = getState()
        let res = await request.delete(`productcolor/deleteitem/${id}`, {
            headers: { Authorization: `Bearer ${userToken}` },
        })
        dispatch(renewUserToken(res))
        dispatch(controlLoading(false))
        dispatch(getOneProduct(product.id))
    } catch (e) {
        errorsReturn(e, dispatch, controlLoading, getErrors, logout)
    }
}
