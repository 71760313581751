import React from 'react'
import { formTypeList } from '../../constants/form'
import OrderPaymentForm from './OrderPaymentForm'

const EditOrderPayment = ({ orderPayment, setOpen, orderId }) => {
    return orderPayment ? (
        <OrderPaymentForm
            formType={formTypeList.EDIT}
            setOpen={setOpen}
            propsValue={orderPayment}
            orderId={orderId}
        />
    ) : null
}

export default EditOrderPayment
