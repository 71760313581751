import { CHANGE_LOGS_PAGE, GET_ALL_LOGS, SEARCH_LOGS } from '../types'

const initialState = {
    logs: null,
    pages: null,
    page: null,
    count: 0,
    keyword: '',
    searchCategory: '',
    searchFromDate: '',
    searchToDate: '',
}

const logReducer = (state = initialState, action) => {
    const { type, payload } = action
    switch (type) {
        case GET_ALL_LOGS:
            return {
                ...state,
                logs: payload.logs,
                keyword: payload.keyword,
                searchCategory: payload.searchCategory,
                searchFromDate: payload.searchFromDate,
                searchToDate: payload.searchToDate,
                pages: payload.pages,
                page: payload.page,
                count: payload.count,
            }
        case SEARCH_LOGS:
            return {
                ...state,
                keyword: payload.keyword,
                searchCategory: payload.searchCategory,
                searchFromDate: payload.searchFromDate,
                searchToDate: payload.searchToDate,
                page: 1,
            }
        case CHANGE_LOGS_PAGE:
            return {
                ...state,
                page: payload,
            }
        default:
            return state
    }
}
export default logReducer
