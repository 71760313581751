import React from 'react'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableRow from '@mui/material/TableRow'
import Paper from '@mui/material/Paper'
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline'
import BlockIcon from '@mui/icons-material/Block'

const productDetailList = [
    { name: 'Name', value: 'name' },
    { name: 'SKU', value: 'sku' },
    { name: 'Package Long', value: 'packageLong' },
    { name: 'Package Width', value: 'packageWidth' },
    { name: 'Package Height', value: 'packageHeight' },
    { name: 'Volume', value: 'volume' },
    { name: 'Weight', value: 'weight' },
    { name: 'Weight Unit', value: 'weightUnit' },
    { name: 'Barcode', value: 'barcode' },
    { name: 'Description', value: 'description' },
]

const TABLE_CELL_WIDTH = 250

const ProductDetail = ({ product }) => {
    return (
        <TableContainer component={Paper} style={{ marginTop: '20px' }}>
            <Table aria-label="simple table">
                <TableBody>
                    <TableRow>
                        <TableCell width={TABLE_CELL_WIDTH}>
                            Product Category
                        </TableCell>
                        <TableCell>
                            {product?.productstyle?.productcategory?.name}
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell width={TABLE_CELL_WIDTH}>
                            Product Style
                        </TableCell>
                        <TableCell>{product?.productstyle?.name}</TableCell>
                    </TableRow>
                    {productDetailList?.map((item) => (
                        <TableRow key={item.name}>
                            <TableCell width={TABLE_CELL_WIDTH}>
                                {item.name}
                            </TableCell>
                            <TableCell style={{ whiteSpace: 'pre-line' }}>
                                {product[item.value]}
                            </TableCell>
                        </TableRow>
                    ))}
                    <TableRow>
                        <TableCell width={TABLE_CELL_WIDTH}>
                            Have Door Direction
                        </TableCell>
                        <TableCell>
                            {product?.doorDirection ? (
                                <CheckCircleOutlineIcon color="success" />
                            ) : (
                                <BlockIcon color="error" />
                            )}
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell width={TABLE_CELL_WIDTH}>
                            Assembly Fee
                        </TableCell>
                        <TableCell>
                            ${product?.assemblyFee?.toFixed(2)}
                        </TableCell>
                    </TableRow>
                </TableBody>
            </Table>
        </TableContainer>
    )
}

export default ProductDetail
