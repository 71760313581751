import { useEffect, useCallback, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Layout from '../../components/Layout'
import { CHANGE_ITEM_RECORDS_PAGE } from '../../store/types'
import PagnationComponent from '../../components/PagnationComponent'
import { Button, Container, Typography } from '@mui/material'
import { getAllItemRecords } from '../../store/actions/itemAction'
import { roleListId, roleType } from '../../config/roleList'
import { checkUserRole } from '../../common/checkUserRole'
import ItemRecordList from '../../components/itemRocord/ItemRecordList'
import BasicModal from '../../components/modal/BasicModal'
import ItemRecordForm from '../../components/itemRocord/ItemRecordForm'

const ItemRecords = () => {
    const dispatch = useDispatch()
    const itemState = useSelector((state) => state.itemRecord)
    const { itemRecords, pages, page, count } = itemState
    const { userInfo } = useSelector((state) => state.auth)
    const { roleList } = useSelector((state) => state.user)
    const [open, setOpen] = useState(false)

    const pageNumber = page || 1
    useEffect(() => {
        dispatch(getAllItemRecords(pageNumber))
    }, [dispatch, pageNumber])

    const changePage = useCallback(
        (page) => {
            dispatch({ type: CHANGE_ITEM_RECORDS_PAGE, payload: page })
        },
        [dispatch]
    )
    return (
        <Layout>
            <Container>
                <BasicModal open={open} setOpen={setOpen}>
                    <ItemRecordForm setOpen={setOpen} />
                </BasicModal>
                <Button
                    color="info"
                    variant="outlined"
                    onClick={() =>
                        checkUserRole(
                            userInfo,
                            roleList,
                            roleListId.ITEM,
                            roleType.APPROVAL
                        )
                            ? setOpen(true)
                            : alert('Not Permit User')
                    }
                >
                    Add New Item Price Change
                </Button>

                <Typography
                    variant="body2"
                    gutterBottom
                    style={{ paddingBottom: '10px', paddingTop: '10px' }}
                >
                    Count: {count}
                </Typography>
                {itemRecords && <ItemRecordList itemRecords={itemRecords} />}
                <PagnationComponent
                    page={pageNumber}
                    pages={pages}
                    func={changePage}
                />
            </Container>
        </Layout>
    )
}

export default ItemRecords
