import { useEffect, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Layout from '../../components/Layout'
import { CHANGE_SHIPMENTS_PAGE } from '../../store/types'
import PagnationComponent from '../../components/PagnationComponent'
import { Container, Typography } from '@mui/material'
import { getAllShipments } from '../../store/actions/shipmentAction'
import ShipmentInfos from '../../components/shipment/ShipmentInfos'
import SearchShipment from '../../components/shipment/SearchShipment'

const Shipments = () => {
    const dispatch = useDispatch()
    const shipmentState = useSelector((state) => state.shipment)
    const { shipments, pages, page, count, keyword } = shipmentState

    const pageNumber = page || 1
    useEffect(() => {
        dispatch(getAllShipments(keyword, pageNumber))
    }, [dispatch, keyword, pageNumber])

    const changePage = useCallback(
        (page) => {
            dispatch({ type: CHANGE_SHIPMENTS_PAGE, payload: page })
        },
        [dispatch]
    )
    return (
        <Layout>
            <Container>
                <SearchShipment displayKeyword={keyword} />
                <Typography
                    variant="body2"
                    gutterBottom
                    style={{ paddingBottom: '10px', paddingTop: '10px' }}
                >
                    Count: {count}
                </Typography>
                {shipments && <ShipmentInfos shipments={shipments} />}
                <PagnationComponent
                    page={pageNumber}
                    pages={pages}
                    func={changePage}
                />
            </Container>
        </Layout>
    )
}

export default Shipments
