import { useEffect } from 'react'
import { Button, Grid, TextField } from '@mui/material'
import Select from '@mui/material/Select'
import { DemoContainer } from '@mui/x-date-pickers/internals/demo'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { useState } from 'react'
import { useDispatch } from 'react-redux'
import { SEARCH_LOGS } from '../../store/types'
import { roleListId, roleNameList } from '../../config/roleList'
import MenuItem from '@mui/material/MenuItem'

const SearchLogs = ({
    displayKeyword,
    displayCategory,
    displayFromDate,
    displayToDate,
}) => {
    const dispatch = useDispatch()
    const [keyword, setKeyword] = useState('')
    const [searchCategory, setSearchCategory] = useState('')
    const [searchFromDate, setSearchFromDate] = useState('')
    const [searchToDate, setSearchToDate] = useState('')

    useEffect(() => {
        setKeyword(displayKeyword ?? '')
        setSearchCategory(displayCategory ?? '')
        setSearchFromDate(displayFromDate ?? '')
        setSearchToDate(displayToDate ?? '')
    }, [displayKeyword, displayCategory, displayFromDate, displayToDate])
    const handleSubmit = (e) => {
        e.preventDefault()
        dispatch({
            type: SEARCH_LOGS,
            payload: {
                keyword,
                searchCategory,
                searchFromDate,
                searchToDate,
            },
        })
    }
    return (
        <div className="search-container">
            <form onSubmit={handleSubmit} noValidate autoComplete="off">
                <Grid container justify="center" alignItems="center" gap={1}>
                    <Grid item xs={5}>
                        <TextField
                            // variant="outlined"
                            fullWidth
                            id="keyword"
                            label="Search Name and Email..."
                            value={keyword}
                            onChange={(e) => setKeyword(e.target.value)}
                        />
                    </Grid>
                    <Grid item xs={5}>
                        <Select
                            labelId={`select-label-logs`}
                            fullWidth
                            name="searchCategory"
                            id="searchCategory"
                            value={searchCategory}
                            // variant="standard"
                            onChange={(e) => setSearchCategory(e.target.value)}
                        >
                            <MenuItem value="">--Category--</MenuItem>
                            {Object.values(roleListId)?.map((option) => (
                                <MenuItem key={option} value={option}>
                                    {roleNameList[option]}
                                </MenuItem>
                            ))}
                        </Select>
                    </Grid>

                    <Grid item xs={6}>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DemoContainer
                                components={['DatePicker', 'DatePicker']}
                            >
                                <DatePicker
                                    label="From date"
                                    value={searchFromDate}
                                    onChange={(newValue) =>
                                        setSearchFromDate(newValue)
                                    }
                                />
                                <DatePicker
                                    label="To date"
                                    value={searchToDate}
                                    onChange={(newValue) =>
                                        setSearchToDate(newValue)
                                    }
                                />
                            </DemoContainer>
                        </LocalizationProvider>
                    </Grid>
                    <Grid item style={{ marginLeft: '10px' }}>
                        <Button
                            variant="outlined"
                            color="primary"
                            type="submit"
                        >
                            Search
                        </Button>
                    </Grid>
                </Grid>
            </form>
        </div>
    )
}

export default SearchLogs
