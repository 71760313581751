import React from 'react'
import { formTypeList } from '../../constants/form'
import WarehouseMapForm from './WarehouseMapForm'

const AddNewWarehouseMap = ({ setOpen, warehouseId }) => (
    <WarehouseMapForm
        formType={formTypeList.NEW}
        warehouseId={warehouseId}
        setOpen={setOpen}
    />
)

export default AddNewWarehouseMap
