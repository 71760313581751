import React, { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Button, TableCell, TableRow } from '@mui/material'
import { roleListId, roleType } from '../../config/roleList'
import { DELETE_CONFIRM } from '../../constants/form'
import { deleteProductColorItem } from '../../store/actions/productColorAction'
import { checkUserRole } from '../../common/checkUserRole'

const ProductItemList = ({ item }) => {
    const dispatch = useDispatch()
    const { userInfo } = useSelector((state) => state.auth)
    const { roleList } = useSelector((state) => state.user)

    const handleDelete = useCallback(
        (id) => {
            if (window.confirm(DELETE_CONFIRM)) {
                dispatch(deleteProductColorItem(id))
            }
        },
        [dispatch]
    )

    return (
        <>
            <TableRow>
                <TableCell>{item?.item?.itemSKU}</TableCell>
                <TableCell>${item?.item?.MSRPinCAD}</TableCell>
                <TableCell>{item.quantity}</TableCell>
                <TableCell>
                    <div className="button-box">
                        <Button
                            size="small"
                            variant="outlined"
                            color="error"
                            onClick={() =>
                                checkUserRole(
                                    userInfo,
                                    roleList,
                                    roleListId.PRODUCT_COLOR,
                                    roleType.DELETE
                                )
                                    ? handleDelete(item.id)
                                    : alert('Not Permit User')
                            }
                        >
                            Delete
                        </Button>
                    </div>
                </TableCell>
            </TableRow>
        </>
    )
}

export default ProductItemList
