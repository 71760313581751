import React from 'react'
import * as Yup from 'yup'
import { useDispatch } from 'react-redux'
import AppForm from '../form/AppForm'
import AppInput from '../form/AppInput'
import SubmitButton from '../form/SubmitButton'
import Header from '../form/Header'
import { Container } from '@mui/material'
import { updateAllItemsPrice } from '../../store/actions/itemAction'

const validationSchema = Yup.object().shape({
    percentage: Yup.number().required().label('Increase Price Percentage'),
})

const ItemRecordForm = ({ setOpen }) => {
    const dispatch = useDispatch()
    return (
        <Container>
            <Header title={`Increase Price Percentage`} />
            <AppForm
                initialValues={{
                    percentage: '',
                }}
                onSubmit={(values) => {
                    dispatch(updateAllItemsPrice(values))

                    setOpen(false)
                }}
                validationSchema={validationSchema}
            >
                <AppInput
                    name="percentage"
                    label="Increase Price Percentage"
                    type="number"
                    inputProps={{
                        maxLength: 10,
                    }}
                    required={true}
                />

                <SubmitButton title="Submit" />
            </AppForm>
        </Container>
    )
}

export default ItemRecordForm
