import React, { useCallback, useState } from 'react'
import Button from '@mui/material/Button'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import { deleteDoorCategory } from '../../store/actions/doorCategoryAction'
import { useDispatch, useSelector } from 'react-redux'
import BasicModal from '../modal/BasicModal'
import AddNew from '../doorStyle/AddNew'
import Edit from './Edit'
import { roleListId, roleType } from '../../config/roleList'
import { DELETE_CONFIRM } from '../../constants/form'
import { checkUserRole } from '../../common/checkUserRole'

export default function DoorCategoryList({ doorCategory }) {
    const dispatch = useDispatch()
    const { userInfo } = useSelector((state) => state.auth)
    const { roleList } = useSelector((state) => state.user)
    const [anchorEl, setAnchorEl] = React.useState(null)
    const [editOpen, setEditOpen] = useState(false)
    const [styleOpen, setStyleOpen] = useState(false)

    const open = Boolean(anchorEl)
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget)
    }
    const handleClose = () => {
        setAnchorEl(null)
    }

    const handleDelete = useCallback(
        (id) => {
            if (window.confirm(DELETE_CONFIRM)) {
                dispatch(deleteDoorCategory(id))
            }
        },
        [dispatch]
    )

    return (
        <div>
            <BasicModal open={editOpen} setOpen={setEditOpen}>
                <Edit id={doorCategory.id} setOpen={setEditOpen} />
            </BasicModal>
            <BasicModal open={styleOpen} setOpen={setStyleOpen}>
                <AddNew
                    doorCategoryId={doorCategory.id}
                    setOpen={setStyleOpen}
                />
            </BasicModal>
            <Button
                id="basic-button"
                aria-controls={open ? 'basic-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                onClick={handleClick}
            >
                {doorCategory.name}
            </Button>
            <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                }}
            >
                <MenuItem
                    onClick={() =>
                        checkUserRole(
                            userInfo,
                            roleList,
                            roleListId.DOOR_STYLE,
                            roleType.CREATE
                        )
                            ? setStyleOpen(true)
                            : alert('Not Permit User')
                    }
                >
                    Add New Door Style
                </MenuItem>
                <MenuItem
                    onClick={() =>
                        checkUserRole(
                            userInfo,
                            roleList,
                            roleListId.DOOR_CATEGORY,
                            roleType.EDIT
                        )
                            ? setEditOpen(true)
                            : alert('Not Permit User')
                    }
                >
                    Edit
                </MenuItem>
                <MenuItem
                    onClick={() =>
                        checkUserRole(
                            userInfo,
                            roleList,
                            roleListId.DOOR_CATEGORY,
                            roleType.DELETE
                        )
                            ? handleDelete(doorCategory.id)
                            : alert('Not Permit User')
                    }
                >
                    Delete
                </MenuItem>
            </Menu>
        </div>
    )
}
