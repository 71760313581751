import { useEffect, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import * as XLSX from 'xlsx'
import { saveAs } from 'file-saver'
import Layout from '../../components/Layout'
import PagnationComponent from '../../components/PagnationComponent'
import { Button, Container, Grid, Typography } from '@mui/material'
import { CHANGE_ORDER_PAYMENTS_PAGE } from '../../store/types'
import { getAllOrderPayments } from '../../store/actions/orderPaymentAction'
import SearchOrderPayment from '../../components/orderPayment/SearchOrderPayment'
import OrderPaymentInfos from '../../components/orderPayment/OrderPaymentInfos'
import request from '../../config/request'
import { orderTypeList } from '../../constants/orderType'
import { controlLoading } from '../../store/actions/loadingActions'
import { getErrors } from '../../store/actions/errorActions'

const OrderPayments = () => {
    const dispatch = useDispatch()

    const {
        orderPayments,
        pages,
        page,
        count,
        keyword,
        searchFromDate,
        searchToDate,
    } = useSelector((state) => state.orderPayment)
    const { userToken } = useSelector((state) => state.auth)

    const pageNumber = page || 1
    useEffect(() => {
        dispatch(
            getAllOrderPayments(
                keyword,
                pageNumber,
                searchFromDate,
                searchToDate
            )
        )
    }, [dispatch, keyword, pageNumber, searchFromDate, searchToDate])

    const changePage = useCallback(
        (page) => {
            dispatch({ type: CHANGE_ORDER_PAYMENTS_PAGE, payload: page })
        },
        [dispatch]
    )

    const handleDownload = useCallback(async () => {
        dispatch(controlLoading(true))

        try {
            const res = await request.get(
                `orderpayment/listall?keyword=${keyword}&searchFromDate=${searchFromDate}&searchToDate=${searchToDate}&download=true`,
                {
                    headers: { Authorization: `Bearer ${userToken}` },
                }
            )

            const orders = res?.data?.data || []

            if (orders.length === 0) {
                dispatch(controlLoading(false))
                return
            }

            const downloadData = orders.map((order) => {
                const totalPaid =
                    order?.orderpayments?.reduce(
                        (total, payment) => total + payment.amount,
                        0
                    ) || 0

                return {
                    orderType: orderTypeList[order?.orderType] || 'Unknown',
                    invoiceNumber: order?.invoiceNumber || 'N/A',
                    invoiceDate: order?.invoiceDate || 'N/A',
                    poNumber: order?.poNumber || 'N/A',
                    company: order?.customer?.company || 'N/A',
                    invoiceAmount: parseFloat(order?.invoiceAmountDecimal || 0),
                    totalPaid,
                }
            })

            const worksheet = XLSX.utils.json_to_sheet(downloadData)
            const workbook = XLSX.utils.book_new()
            XLSX.utils.book_append_sheet(workbook, worksheet, 'OrderPayment')

            const excelBuffer = XLSX.write(workbook, {
                bookType: 'xlsx',
                type: 'array',
            })

            const blob = new Blob([excelBuffer], {
                type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
            })

            saveAs(blob, `OrderPayment_${Date.now()}.xlsx`)
        } catch (error) {
            dispatch(getErrors('Download error'))
            console.error('Download error:', error)
        } finally {
            dispatch(controlLoading(false))
        }
    }, [userToken, keyword, searchFromDate, searchToDate, dispatch])

    return (
        <Layout>
            <Container>
                <SearchOrderPayment
                    displayKeyword={keyword}
                    displayFromDate={searchFromDate}
                    displayToDate={searchToDate}
                />
                <Grid
                    container
                    justifyContent="space-between"
                    alignItems="center"
                    gap={2}
                >
                    <Typography
                        variant="body2"
                        gutterBottom
                        style={{ paddingBottom: '10px', paddingTop: '10px' }}
                    >
                        Count: {count}
                    </Typography>
                    <Grid item style={{ marginLeft: '10px' }}>
                        <Button
                            variant="outlined"
                            color="secondary"
                            type="button"
                            onClick={handleDownload}
                        >
                            Download
                        </Button>
                    </Grid>
                </Grid>

                {orderPayments && <OrderPaymentInfos orders={orderPayments} />}
                <PagnationComponent
                    page={pageNumber}
                    pages={pages}
                    func={changePage}
                />
            </Container>
        </Layout>
    )
}

export default OrderPayments
