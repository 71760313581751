export default function modifyOrderItemOption(
  order,
  orderStyleId,
  orderItemId,
  optionName,
  optionValue,
) {
  const orderStyle = order.orderstyles.find(style => style.id === orderStyleId);
  if (orderStyle) {
    const orderItem = orderStyle.orderitems.find(
      item => item.id === orderItemId,
    );
    if (orderItem) {
      orderItem[optionName] = optionValue;
    }
  }
  return order;
}
