import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import Layout from '../../components/Layout'
import ItemContent from '../../components/item/ItemContent'
import { getOneItem } from '../../store/actions/itemAction'

const Item = () => {
    const dispatch = useDispatch()
    const { id } = useParams()
    const itemState = useSelector((state) => state.item)
    const { item } = itemState
    useEffect(() => {
        dispatch(getOneItem(id))
    }, [dispatch, id])
    return <Layout>{item ? <ItemContent item={item} /> : null}</Layout>
}

export default Item
