import React from 'react'
import { formTypeList } from '../../constants/form'
import ProductColorForm from './ProductColorForm'

const AddNew = ({ setOpen, productId, doorColorId, autoSku }) => {
    return (
        <ProductColorForm
            formType={formTypeList.NEW}
            productId={productId}
            doorColorId={doorColorId}
            setOpen={setOpen}
            autoSku={autoSku}
        />
    )
}

export default AddNew
