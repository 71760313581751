import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getOneBaseTax } from '../../store/actions/baseTaxAction'
import { GET_ONE_BASE_TAX } from '../../store/types'
import BaseTaxForm from './BaseTaxForm'
import { formTypeList } from '../../constants/form'

const EditBaseTax = ({ id, setOpen }) => {
    const dispatch = useDispatch()
    const { baseTax } = useSelector((state) => state.baseTax)
    useEffect(() => {
        dispatch(getOneBaseTax(id))
        return () => {
            dispatch({
                type: GET_ONE_BASE_TAX,
                payload: null,
            })
        }
    }, [dispatch, id])
    return baseTax ? (
        <BaseTaxForm
            formType={formTypeList.EDIT}
            setOpen={setOpen}
            propsValue={baseTax}
        />
    ) : null
}

export default EditBaseTax
