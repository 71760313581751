import React from 'react'
import { formTypeList } from '../../constants/form'
import Form from './Form'

const AddNew = ({ setOpen, doorCategoryId }) => {
    return (
        <Form
            formType={formTypeList.NEW}
            doorCategoryId={doorCategoryId}
            setOpen={setOpen}
        />
    )
}

export default AddNew
