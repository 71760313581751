import { Button, Container, TextField } from '@mui/material'
import React, { useCallback, useRef, useState } from 'react'
import * as Yup from 'yup'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Paper from '@mui/material/Paper'
import AppForm from '../form/AppForm'
import SubmitButton from '../form/SubmitButton'
import AppSelectNameId from '../form/AppSelectNameId'
import AppInput from '../form/AppInput'
import { getErrors } from '../../store/actions/errorActions'
import { addNewPurchaseOrderFromAnalytics } from '../../store/actions/purchaseOrderAction'

const validationSchema = Yup.object().shape({
    invoiceNo: Yup.string().required().label('Invoice No'),
    po: Yup.string().required().label('PO No'),
    supplierId: Yup.string().required().label('Supplier'),
})

const ItemList = ({ item, setItems }) => {
    const textFieldRef = useRef(null)
    const handleQtyChange = useCallback(
        (event, id) => {
            setItems((prev) =>
                prev.map((oldItem) =>
                    oldItem.itemId === id
                        ? {
                              ...oldItem,
                              quantity: event.target.value || 0,
                              valid: (event.target.value || 0) > 0,
                          }
                        : oldItem
                )
            )
        },
        [setItems]
    )

    const handleMouseOver = () => {
        if (textFieldRef.current) {
            textFieldRef.current.select()
        }
    }
    return (
        <TableRow>
            <TableCell>{item.color}</TableCell>
            <TableCell>{item.sku}</TableCell>
            <TableCell>{item.itemSKU}</TableCell>
            <TableCell>
                <TextField
                    inputRef={textFieldRef}
                    onMouseOver={handleMouseOver}
                    size="small"
                    type="number"
                    value={item.quantity}
                    onChange={(e) => handleQtyChange(e, item.itemId)}
                    error={item.valid ? false : true}
                />
            </TableCell>
        </TableRow>
    )
}

const PurchaseOrderItemList = ({ items, setItems }) => {
    return (
        <TableContainer component={Paper}>
            <Table aria-label="simple table">
                <TableHead>
                    <TableRow>
                        <TableCell>Color</TableCell>
                        <TableCell>SKU</TableCell>
                        <TableCell>Item SKU</TableCell>
                        <TableCell>Order Qty</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {items?.map((item, index) => (
                        <ItemList item={item} setItems={setItems} key={index} />
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    )
}

const PurchaseOrderFromAnalyticsForm = ({ purchaseOrderItems }) => {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const { suppliers } = useSelector((state) => state.purchaseOrder)
    const [items, setItems] = useState(() => purchaseOrderItems)
    return (
        <Container>
            <Button onClick={() => navigate(-1)}>Back</Button>
            <AppForm
                initialValues={{
                    invoiceNo: '',
                    po: '',
                    supplierId: suppliers?.[0]?.id,
                }}
                onSubmit={(values) => {
                    const checkItemsQty = items.filter((item) => !item.valid)
                    if (checkItemsQty.length > 0) {
                        dispatch(getErrors('Order Qty cannot 0'))
                        return
                    }
                    const payload = { ...values, items }
                    dispatch(
                        addNewPurchaseOrderFromAnalytics(payload, navigate)
                    )
                }}
                validationSchema={validationSchema}
            >
                <AppSelectNameId
                    name="supplierId"
                    label="Supplier"
                    options={suppliers}
                />
                <AppInput
                    name="invoiceNo"
                    label="Invoice No"
                    type="text"
                    inputProps={{
                        maxLength: 50,
                    }}
                    required={true}
                />
                <AppInput
                    name="po"
                    label="PO No"
                    type="text"
                    inputProps={{
                        maxLength: 50,
                    }}
                    required={true}
                />
                <PurchaseOrderItemList items={items} setItems={setItems} />
                <SubmitButton title="Submit" />
            </AppForm>
        </Container>
    )
}

export default PurchaseOrderFromAnalyticsForm
