import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Paper from '@mui/material/Paper'
import ItemList from './ItemList'

const ItemInfos = ({ items }) => {
    return (
        <TableContainer component={Paper}>
            <Table aria-label="simple table">
                <TableHead>
                    <TableRow>
                        <TableCell></TableCell>
                        <TableCell>SKU</TableCell>
                        <TableCell>Name</TableCell>
                        <TableCell>MSRP</TableCell>
                        {/* <TableCell>MSRP in USD</TableCell> */}
                        <TableCell>Stock</TableCell>
                        <TableCell>Location</TableCell>
                        <TableCell>Alert Stock</TableCell>
                        <TableCell style={{ width: '50px' }}></TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {items.map((item) => (
                        <ItemList item={item} key={item.id} />
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    )
}

export default ItemInfos
