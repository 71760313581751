import { UPDATE_MODE, UPDATE_SETTING_OPEN, UPDATE_USER_OPEN } from '../types'

const initialState = {
    mode: 'light',
    userOpen: false,
    settingOpen: false,
}

export default function modeReducer(state = initialState, action) {
    const { type, payload } = action
    switch (type) {
        case UPDATE_MODE:
            return {
                ...state,
                mode: payload,
            }
        case UPDATE_USER_OPEN:
            return {
                ...state,
                userOpen: payload,
            }
        case UPDATE_SETTING_OPEN:
            return {
                ...state,
                settingOpen: payload,
            }
        default:
            return state
    }
}
