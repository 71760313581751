import request from '../../config/request'
import { errorsReturn } from '../errorsReturn'
import { GET_ALL_LOGS } from '../types'
import { logout, renewUserToken } from './authAction'
import { getErrors } from './errorActions'
import { controlLoading } from './loadingActions'

export const getAllLogs =
    (
        keyword = '',
        pageNumber = '',
        searchCategory = '',
        searchFromDate = '',
        searchToDate = ''
    ) =>
    async (dispatch, getState) => {
        try {
            dispatch(controlLoading(true))
            const {
                auth: { userToken },
            } = getState()
            let res = await request.get(
                `log/listall?keyword=${keyword}&pageNumber=${pageNumber}&searchCategory=${searchCategory}&searchFromDate=${searchFromDate}&searchToDate=${searchToDate}`,
                {
                    headers: { Authorization: `Bearer ${userToken}` },
                }
            )
            dispatch(renewUserToken(res))
            dispatch({
                type: GET_ALL_LOGS,
                payload: {
                    logs: res.data.data,
                    pages: res.data.pages,
                    page: res.data.page,
                    count: res.data.count,
                    keyword,
                    searchCategory,
                    searchFromDate,
                    searchToDate,
                },
            })
            dispatch(controlLoading(false))
        } catch (e) {
            errorsReturn(e, dispatch, controlLoading, getErrors, logout)
        }
    }
