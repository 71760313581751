import React, { useEffect, useState } from 'react'
import Layout from '../components/Layout'
import { useDispatch, useSelector } from 'react-redux'
import {
    getOneUser,
    getRoleList,
    updateUser,
} from '../store/actions/usersAction'
import Loader from '../components/Loader'
import { useNavigate, useParams } from 'react-router-dom'
import { Container, Checkbox, FormControlLabel, Button } from '@mui/material'
import { styled } from '@mui/system'

const userInfo = [
    { id: 1, label: 'First Name', value: 'firstName' },
    { id: 2, label: 'Last Name', value: 'lastName' },
    { id: 3, label: 'Email', value: 'email' },
    { id: 4, label: 'Phone', value: 'phone' },
    { id: 5, label: 'Address', value: 'address' },
]

const FlexContainer = styled('div')({
    display: 'flex',
    gap: '16px', // Adjust the gap between checkboxes
})

const User = () => {
    const dispatch = useDispatch()
    const { id } = useParams()
    const navigate = useNavigate()
    const userState = useSelector((state) => state.user)
    const { user, roleList } = userState
    useEffect(() => {
        dispatch(getOneUser(id))
        dispatch(getRoleList())
    }, [dispatch, id])

    const [isActive, setIsActive] = useState(false)
    const [checkedValues, setCheckedValues] = useState([])

    useEffect(() => {
        setIsActive(user?.isActive)
        setCheckedValues(user?.roles?.split(',') || [])
    }, [user])

    const handleConfirm = (e) => {
        setIsActive(!isActive)
    }

    const handleCheckboxChange = (value) => {
        if (checkedValues?.includes(value)) {
            setCheckedValues(checkedValues?.filter((item) => item !== value))
        } else {
            setCheckedValues([...checkedValues, value])
        }
    }

    const handleSubmit = () => {
        const values = { ...user }
        values['isActive'] = isActive
        values['roles'] = checkedValues.join(',')
        dispatch(updateUser(user.id, values, navigate))
    }

    if (!user) return <Loader />
    return (
        <Layout>
            <Container>
                {userInfo.map((list) => (
                    <div key={list.id} className="user-box">
                        <div className="user-left">{list.label}: </div>
                        <div>{user[list.value]}</div>
                    </div>
                ))}
                <div className="user-box">
                    <div className="user-left">Active:</div>
                    <div>
                        {' '}
                        <Checkbox
                            defaultChecked={user.isActive}
                            onChange={handleConfirm}
                            color="primary"
                            inputProps={{ 'aria-label': 'secondary checkbox' }}
                        />
                    </div>
                </div>
                {roleList && (
                    <div>
                        {roleList.map((role) => (
                            <div className="user-box" key={role.id}>
                                <div className="user-left">{role.name}</div>
                                <div className="newuser-roles">
                                    <FlexContainer>
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={checkedValues?.includes(
                                                        role.readRole
                                                    )}
                                                    onChange={() =>
                                                        handleCheckboxChange(
                                                            role.readRole
                                                        )
                                                    }
                                                />
                                            }
                                            label="Read"
                                        />
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={checkedValues?.includes(
                                                        role.createRole
                                                    )}
                                                    onChange={() =>
                                                        handleCheckboxChange(
                                                            role.createRole
                                                        )
                                                    }
                                                />
                                            }
                                            label="Create"
                                        />
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={checkedValues?.includes(
                                                        role.editRole
                                                    )}
                                                    onChange={() =>
                                                        handleCheckboxChange(
                                                            role.editRole
                                                        )
                                                    }
                                                />
                                            }
                                            label="Edit"
                                        />
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={checkedValues?.includes(
                                                        role.deleteRole
                                                    )}
                                                    onChange={() =>
                                                        handleCheckboxChange(
                                                            role.deleteRole
                                                        )
                                                    }
                                                />
                                            }
                                            label="Delete"
                                        />
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={checkedValues?.includes(
                                                        role.approvalRole
                                                    )}
                                                    onChange={() =>
                                                        handleCheckboxChange(
                                                            role.approvalRole
                                                        )
                                                    }
                                                />
                                            }
                                            label="Approval"
                                        />
                                    </FlexContainer>
                                </div>
                            </div>
                        ))}
                    </div>
                )}
                <Button
                    size="small"
                    variant="outlined"
                    color="primary"
                    onClick={() => handleSubmit()}
                    style={{ marginRight: '10px' }}
                >
                    Update
                </Button>
                <Button
                    size="small"
                    variant="outlined"
                    color="secondary"
                    onClick={() => navigate(-1)}
                >
                    Back
                </Button>
            </Container>
        </Layout>
    )
}

export default User
