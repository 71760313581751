import { useEffect, useCallback, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { DemoContainer } from '@mui/x-date-pickers/internals/demo'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'

import { Button, Container, Grid } from '@mui/material'
import { getAllOrderAnalytics } from '../../store/actions/orderAction'
import Layout from '../../components/Layout'
import DataList from '../../components/order/DataList'
import { GET_ORDER_ANALYTICS } from '../../store/types'

const SearchComponent = ({
    searchFromDate,
    setSearchFromDate,
    searchToDate,
    setSearchToDate,
    searchSubmit,
}) => {
    return (
        <div className="search-container">
            <form onSubmit={searchSubmit} noValidate autoComplete="off">
                <Grid container justify="center" alignItems="center" gap={1}>
                    <Grid item xs={6}>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DemoContainer
                                components={['DatePicker', 'DatePicker']}
                            >
                                <DatePicker
                                    label="From date"
                                    value={searchFromDate}
                                    onChange={(newValue) =>
                                        setSearchFromDate(
                                            new Date(
                                                newValue
                                            ).toLocaleDateString('en-CA')
                                        )
                                    }
                                />
                                <DatePicker
                                    label="To date"
                                    value={searchToDate}
                                    onChange={(newValue) =>
                                        setSearchToDate(
                                            new Date(
                                                newValue
                                            ).toLocaleDateString('en-CA')
                                        )
                                    }
                                />
                            </DemoContainer>
                        </LocalizationProvider>
                    </Grid>
                    <Grid item style={{ marginLeft: '10px' }}>
                        <Button
                            variant="outlined"
                            color="primary"
                            type="submit"
                        >
                            Search
                        </Button>
                    </Grid>
                </Grid>
            </form>
        </div>
    )
}

const OrderAnalytics = () => {
    const dispatch = useDispatch()
    const { orderAnalytics } = useSelector((state) => state.order)

    const [searchFromDate, setSearchFromDate] = useState('')
    const [searchToDate, setSearchToDate] = useState('')
    const searchSubmit = useCallback(
        (e) => {
            e.preventDefault()
            dispatch(getAllOrderAnalytics(searchFromDate, searchToDate))
        },
        [dispatch, searchFromDate, searchToDate]
    )

    useEffect(
        () => () => {
            dispatch({
                type: GET_ORDER_ANALYTICS,
                payload: null,
            })
        },
        [dispatch]
    )

    return (
        <Layout>
            <Container>
                <SearchComponent
                    setSearchFromDate={setSearchFromDate}
                    setSearchToDate={setSearchToDate}
                    searchFromDate={searchFromDate}
                    searchToDate={searchToDate}
                    searchSubmit={searchSubmit}
                />
                {orderAnalytics?.length === 0 && 'No Data'}
                {orderAnalytics?.length > 0 && (
                    <DataList orderAnalytics={orderAnalytics} />
                )}
            </Container>
        </Layout>
    )
}

export default OrderAnalytics
