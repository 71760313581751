import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Paper from '@mui/material/Paper'
import DefectiveItemList from './DefectiveItemList'

const DefectiveItemInfo = ({ defectiveItems }) => {
    return (
        <TableContainer component={Paper}>
            <Table aria-label="simple table">
                <TableHead>
                    <TableRow>
                        <TableCell>Date</TableCell>
                        <TableCell>Supplier</TableCell>
                        <TableCell>Item SKU</TableCell>
                        <TableCell>Quantity</TableCell>
                        <TableCell align="center">Re-Order</TableCell>
                        <TableCell>Re-Order Date</TableCell>
                        <TableCell style={{ width: '50px' }}></TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {defectiveItems.map((defectiveItem) => (
                        <DefectiveItemList
                            defectiveItem={defectiveItem}
                            key={defectiveItem.id}
                        />
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    )
}

export default DefectiveItemInfo
