import { useEffect, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Layout from '../../components/Layout'
import PagnationComponent from '../../components/PagnationComponent'
import { Container, Typography } from '@mui/material'
import { CHANGE_ORDERS_PAGE } from '../../store/types'
import { getAllOrders } from '../../store/actions/orderAction'
import SearchOrder from '../../components/order/SearchOrder'
import OrderInfos from '../../components/order/OrderInfo'

const Orders = () => {
    const dispatch = useDispatch()

    const { orders, pages, page, count, keyword, status } = useSelector(
        (state) => state.order
    )

    const pageNumber = page || 1
    useEffect(() => {
        dispatch(getAllOrders(keyword, status, pageNumber))
    }, [dispatch, keyword, status, pageNumber])

    const changePage = useCallback(
        (page) => {
            dispatch({ type: CHANGE_ORDERS_PAGE, payload: page })
        },
        [dispatch]
    )

    return (
        <Layout>
            <Container>
                <SearchOrder displayKeyword={keyword} searchStatus={status} />
                <Typography
                    variant="body2"
                    gutterBottom
                    style={{ paddingBottom: '10px', paddingTop: '10px' }}
                >
                    Count: {count}
                </Typography>
                {orders && <OrderInfos orders={orders} />}
                <PagnationComponent
                    page={pageNumber}
                    pages={pages}
                    func={changePage}
                />
            </Container>
        </Layout>
    )
}

export default Orders
