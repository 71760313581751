import React, { useEffect } from 'react'
import { formTypeList } from '../../constants/form'
import { useDispatch, useSelector } from 'react-redux'
import { getOneSupplierContact } from '../../store/actions/supplierAction'
import SupplierContactForm from './SupplierContactForm'
import { GET_ONE_SUPPLIER_CONTACT } from '../../store/types'

const SupplierContactEdit = ({ id, handleClose, supplierId }) => {
    const dispatch = useDispatch()
    const { supplierContact } = useSelector((state) => state.supplier)
    useEffect(() => {
        dispatch(getOneSupplierContact(id))
        return () => {
            dispatch({
                type: GET_ONE_SUPPLIER_CONTACT,
                payload: null,
            })
        }
    }, [dispatch, id])
    return supplierContact ? (
        <div>
            <SupplierContactForm
                supplierId={supplierId}
                handleClose={handleClose}
                formType={formTypeList.EDIT}
                propsValue={supplierContact}
            />
        </div>
    ) : null
}

export default SupplierContactEdit
