import React from 'react'
import { useFormikContext } from 'formik'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { DemoContainer } from '@mui/x-date-pickers/internals/demo'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'

const AppDatePicker = ({ name, label, required, ...otherProps }) => {
    const { setFieldTouched, setFieldValue, values, errors, touched } =
        useFormikContext()
    const handleDateChange = (value) => {
        setFieldValue(name, value)
    }

    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DemoContainer components={['DatePicker', 'DatePicker']}>
                <DatePicker
                    fullWidth
                    name={name}
                    label={label}
                    error={touched[name] && errors[name] ? true : false}
                    required={required}
                    value={values[name]}
                    onChange={(newValue) => handleDateChange(newValue)} // Wrap handleChange in an anonymous function
                    onBlur={() => setFieldTouched(name)}
                    helperText={
                        touched[name] && errors[name] ? errors[name] : null
                    }
                    {...otherProps}
                />
            </DemoContainer>
        </LocalizationProvider>
    )
}

export default AppDatePicker
