import React from 'react'
import { useDispatch } from 'react-redux'
import * as Yup from 'yup'
import AppForm from '../form/AppForm'
import AppInput from '../form/AppInput'
import SubmitButton from '../form/SubmitButton'
import { updateOrderDiscount } from '../../store/actions/orderAction'

const validationSchema = Yup.object().shape({
    deliveryCost: Yup.number().label('Delivery Cost'),
    discountPercentage: Yup.number().label('Discount Percentage'),
})

const DiscountForm = ({ propValues, handleClose }) => {
    const dispatch = useDispatch()
    return (
        <div>
            <AppForm
                initialValues={{
                    deliveryCost: propValues?.deliveryCost || 0,
                    discountPercentage: propValues?.discountPercentage || 0,
                }}
                onSubmit={(values) => {
                    const submitValues = { ...propValues, ...values }
                    dispatch(updateOrderDiscount(submitValues))
                    handleClose(false)
                }}
                validationSchema={validationSchema}
            >
                <AppInput
                    name="deliveryCost"
                    label="Delivery Cost"
                    type="number"
                    inputProps={{
                        maxLength: 10,
                    }}
                />
                <AppInput
                    name="discountPercentage"
                    label="Discount Percentage"
                    type="number"
                    inputProps={{
                        maxLength: 10,
                    }}
                />
                <SubmitButton title="Submit" />
            </AppForm>
        </div>
    )
}

export default DiscountForm
