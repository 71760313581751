import React, { useCallback, useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import Layout from '../../components/Layout'
import {
    getOneOrderPayment,
    sendOrderToQuote,
    sendOrderToShipment,
} from '../../store/actions/orderPaymentAction'
import { GET_ONE_ORDER_PAYMENT } from '../../store/types'
import OrderPaymentDetail from '../../components/orderPayment/OrderPaymentDetail'
import { Button, Divider } from '@mui/material'
import PaymentList from '../../components/orderPayment/PaymentList'
import { OrderStatusList } from '../../constants/orderType'
import { checkUserRole } from '../../common/checkUserRole'
import { roleListId, roleType } from '../../config/roleList'

const OrderPayment = () => {
    const { id } = useParams()
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const { orderPayment } = useSelector((state) => state.orderPayment)
    const { userInfo } = useSelector((state) => state.auth)
    const { roleList } = useSelector((state) => state.user)
    useEffect(() => {
        dispatch(getOneOrderPayment(id))
        return () => {
            dispatch({ type: GET_ONE_ORDER_PAYMENT, payload: null })
        }
    }, [dispatch, id])

    const sendOrder = useCallback(
        (id) => {
            if (window.confirm('Confirm send Order to In-Progress?')) {
                dispatch(sendOrderToShipment(id))
            }
        },
        [dispatch]
    )

    const backOrder = useCallback(
        (id) => {
            if (window.confirm('Confirm send Order back to Quote?')) {
                dispatch(sendOrderToQuote(id))
            }
        },
        [dispatch]
    )

    return (
        <Layout>
            {orderPayment ? (
                <>
                    <Button onClick={() => navigate(-1)}>Back</Button>
                    <OrderPaymentDetail order={orderPayment} />
                    <Divider style={{ marginTop: '10px' }} />
                    <div style={{ paddingTop: '10px', paddingBottom: '10px' }}>
                        {orderPayment?.orderType === OrderStatusList.QUOTE && (
                            <Button
                                variant="contained"
                                color="success"
                                onClick={() =>
                                    checkUserRole(
                                        userInfo,
                                        roleList,
                                        roleListId.ORDER_PAYMENT,
                                        roleType.APPROVAL
                                    )
                                        ? sendOrder(orderPayment.id)
                                        : alert('Not Permit User')
                                }
                            >
                                Order In-Progress
                            </Button>
                        )}
                        {orderPayment?.orderType ===
                            OrderStatusList.IN_PROGRESS && (
                            <Button
                                variant="contained"
                                color="error"
                                onClick={() =>
                                    checkUserRole(
                                        userInfo,
                                        roleList,
                                        roleListId.ORDER_PAYMENT,
                                        roleType.APPROVAL
                                    )
                                        ? backOrder(orderPayment.id)
                                        : alert('Not Permit User')
                                }
                            >
                                Back to Quote
                            </Button>
                        )}
                    </div>
                    <Divider />
                    <PaymentList order={orderPayment} />
                </>
            ) : null}
        </Layout>
    )
}

export default OrderPayment
