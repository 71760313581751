import React, { useCallback, useState } from 'react'
import Button from '@mui/material/Button'
import { useDispatch, useSelector } from 'react-redux'
import { green, red } from '@mui/material/colors'
import BlockIcon from '@mui/icons-material/Block'
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline'
import { useNavigate } from 'react-router-dom'
import BasicModal from '../modal/BasicModal'
import { roleListId, roleType } from '../../config/roleList'
import { DELETE_CONFIRM } from '../../constants/form'
import { checkUserRole } from '../../common/checkUserRole'
import EditWarehouse from './EditWarehouse'
import { Paper } from '@mui/material'
import { deleteWarehouse } from '../../store/actions/warehouseAction'

const WarehouseItem = ({ warehouse }) => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const { userInfo } = useSelector((state) => state.auth)
    const { roleList } = useSelector((state) => state.user)
    const [editOpen, setEditOpen] = useState(false)
    const handleDelete = useCallback(
        (id) => {
            if (window.confirm(DELETE_CONFIRM)) {
                dispatch(deleteWarehouse(id))
            }
        },
        [dispatch]
    )

    return (
        <div>
            <BasicModal open={editOpen} setOpen={setEditOpen}>
                <EditWarehouse id={warehouse.id} setOpen={setEditOpen} />
            </BasicModal>
            <Paper
                style={{
                    padding: '20px',
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '10px',
                    marginBottom: '20px',
                }}
            >
                <div>
                    <Button
                        size="small"
                        variant="outlined"
                        color="success"
                        onClick={() =>
                            checkUserRole(
                                userInfo,
                                roleList,
                                roleListId.WAREHOUSE,
                                roleType.READ
                            )
                                ? navigate(`/warehouse/${warehouse.id}`)
                                : alert('Not Permit User')
                        }
                    >
                        Info
                    </Button>
                    <span style={{ marginLeft: '10px' }} />
                    <Button
                        size="small"
                        variant="outlined"
                        onClick={() =>
                            checkUserRole(
                                userInfo,
                                roleList,
                                roleListId.WAREHOUSE,
                                roleType.EDIT
                            )
                                ? setEditOpen(true)
                                : alert('Not Permit User')
                        }
                    >
                        Edit
                    </Button>
                    <span style={{ marginLeft: '10px' }} />
                    <Button
                        size="small"
                        variant="outlined"
                        color="error"
                        onClick={() =>
                            checkUserRole(
                                userInfo,
                                roleList,
                                roleListId.WAREHOUSE,
                                roleType.DELETE
                            )
                                ? handleDelete(warehouse.id)
                                : alert('Not Permit User')
                        }
                    >
                        Delete
                    </Button>
                </div>
                <div>Name: {warehouse?.name}</div>
                <div>Short Name: {warehouse?.shortName}</div>
                <div>
                    Warehouse: {warehouse?.address}
                    {warehouse?.address1
                        ? `, ${warehouse?.address1}`
                        : ''}, {warehouse?.city}, {warehouse?.province},{' '}
                    {warehouse?.postCode} {warehouse?.country}
                </div>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    Active:
                    {warehouse.isActive ? (
                        <CheckCircleOutlineIcon style={{ color: green[500] }} />
                    ) : (
                        <BlockIcon style={{ color: red[500] }} />
                    )}
                </div>
            </Paper>
        </div>
    )
}

export default WarehouseItem
