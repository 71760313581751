/* eslint-disable react/prop-types */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useCallback, useRef, useState } from 'react'
import { Button, Divider } from '@mui/material'
import { IoMdCloseCircleOutline } from 'react-icons/io'
import { useDispatch, useSelector } from 'react-redux'
import {
    addOrderStyle,
    updateOrderStyle,
} from '../../store/actions/orderAction'
import ImgComponent from '../ImgComponent'
import categoryType from '../../constants/categoryType'

function DoorColorList({ doorColor, orderId, handleClose, type, styleId }) {
    const dispatch = useDispatch()
    const handleClick = useCallback(() => {
        if (type === categoryType.NEW) {
            dispatch(addOrderStyle(doorColor.id, orderId))
        }
        if (type === categoryType.UPDATE) {
            const values = {
                id: styleId,
                doorcolorId: doorColor.id,
                colorCode: doorColor.colorCode,
            }
            dispatch(updateOrderStyle(values, orderId))
        }
        handleClose()
    }, [
        dispatch,
        doorColor.id,
        orderId,
        handleClose,
        type,
        styleId,
        doorColor.colorCode,
    ])
    return (
        <div className="door-color-list-box" onClick={handleClick}>
            <div>
                <ImgComponent
                    picLink={doorColor?.picLink}
                    height="200px"
                    width="150px"
                />
            </div>
            <div
                style={{
                    textAlign: 'center',
                    width: '150px',
                    marginTop: '10px',
                    fontSize: '14px',
                }}
            >
                {doorColor.name}
            </div>
        </div>
    )
}

function DoorStyleList({
    doorStyle,
    doorCategory,
    orderId,
    handleClose,
    type,
    styleId,
}) {
    return (
        <>
            <Divider />
            <div
                id={doorStyle.name}
                className="order-product-add-style-category-item"
            >
                <h4 className="order-product-add-style-category-item-title">
                    {doorCategory.name}/{doorStyle.name}
                </h4>
                <div className="order-product-add-style-category-item-box">
                    {doorStyle?.doorcolors?.map((color) => (
                        <DoorColorList
                            key={color.id}
                            doorColor={color}
                            orderId={orderId}
                            handleClose={handleClose}
                            type={type}
                            styleId={styleId}
                        />
                    ))}
                </div>
            </div>
        </>
    )
}

function Category({
    orderId,
    handleClose,
    type = categoryType.NEW,
    styleId = null,
}) {
    const { categories } = useSelector((state) => state.order)
    const [currentName, setCurrentName] = useState(null)
    const scrollRef = useRef(null)

    const scrollToCategory = (categoryName) => {
        setCurrentName(categoryName)
        const element = document.getElementById(categoryName)
        if (element) {
            element.scrollIntoView({ behavior: 'smooth', block: 'start' })
        }
    }

    return (
        <div className="order-product-add-style-box">
            <div className="order-product-sticky-header">
                <div
                    style={{
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'flex-end',
                    }}
                >
                    <IoMdCloseCircleOutline
                        size={30}
                        onClick={handleClose}
                        style={{ cursor: 'pointer' }}
                    />
                </div>
                <h3 style={{ marginTop: '20px', marginBottom: '20px' }}>
                    Select a door style
                </h3>

                <div className="order-product-add-style-category">
                    {categories?.map((item) => (
                        <Button
                            key={item.id}
                            color={
                                currentName === item.name ? 'primary' : 'grey'
                            }
                            variant={
                                currentName === item.name ? 'contained' : 'text'
                            }
                            onClick={() => scrollToCategory(item.name)}
                        >
                            {item.name}
                        </Button>
                    ))}
                </div>
            </div>
            <div style={{ width: '100%', padding: '20px' }}>
                {categories?.map((doorCategory) => (
                    <div
                        key={doorCategory.id}
                        id={doorCategory.name}
                        ref={scrollRef}
                    >
                        {doorCategory?.doorstyles?.map((doorStyle) => (
                            <DoorStyleList
                                key={doorStyle.id}
                                doorStyle={doorStyle}
                                doorCategory={doorCategory}
                                orderId={orderId}
                                handleClose={handleClose}
                                type={type}
                                styleId={styleId}
                            />
                        ))}
                    </div>
                ))}
            </div>
        </div>
    )
}

export default Category
