/* eslint-disable react/prop-types */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-shadow */

import React, { useCallback, useEffect, useRef, useState } from 'react'
import _ from 'lodash'
import {
    Card,
    CardContent,
    CardHeader,
    CardMedia,
    // Divider,
    Grid,
    ListItemIcon,
    MenuItem,
    Modal,
    TableCell,
    TableHead,
    TableRow,
    TextField,
} from '@mui/material'
import { useDispatch } from 'react-redux'
import { Draggable, DragDropContext, Droppable } from '@hello-pangea/dnd'
import Paper from '@mui/material/Paper'
import TableContainer from '@mui/material/TableContainer'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import { MdDeleteForever } from 'react-icons/md'
import { FaExchangeAlt } from 'react-icons/fa'
import { styled } from '@mui/material/styles'
import {
    deleteOrderStyle,
    updateItemSort,
    updateOrderStyleName,
} from '../../store/actions/orderAction'
import EditIcon from './EditIcon'
import AppModal from '../AppModal'
import SearchProduct from './SearchProduct'
import OrderItemLists from './OrderItemLists'
import categoryType from '../../constants/categoryType'
import Category from './Category'
import SearchItem from './SearchItem'

const CardHeaderIcon = ({ orderStyleId, orderId }) => {
    const dispatch = useDispatch()
    const handleDelete = useCallback(() => {
        dispatch(deleteOrderStyle(orderStyleId, orderId))
    }, [dispatch, orderStyleId, orderId])

    const [anchorEl, setAnchorEl] = useState(null)

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget)
    }
    const handleClose = () => {
        setAnchorEl(null)
    }

    const [modalOpen, setModalOpen] = useState(false)
    const handleModelOpen = () => setModalOpen(true)
    const handleModalClose = () => setModalOpen(false)
    const [updateOpen, setUpdateOpen] = useState(false)

    return (
        <>
            <AppModal
                open={modalOpen}
                cancelContent="Cancel"
                submitContent="Delete"
                title="Are you sure you want to delete"
                cancelClick={handleModalClose}
                submitClick={handleDelete}
                danger
                handleClose={handleModalClose}
            />
            <EditIcon
                handleClick={handleClick}
                handleClose={handleClose}
                title="Order Item"
                anchorEl={anchorEl}
            >
                <MenuItem onClick={() => setUpdateOpen(true)}>
                    <ListItemIcon>
                        <FaExchangeAlt fontSize={24} />
                    </ListItemIcon>
                    Change Style
                </MenuItem>

                <MenuItem onClick={() => handleModelOpen(true)}>
                    <ListItemIcon>
                        <MdDeleteForever
                            fontSize={24}
                            style={{ color: 'red' }}
                        />
                    </ListItemIcon>
                    Delete
                </MenuItem>
            </EditIcon>
            <Modal
                open={updateOpen}
                onClose={() => setUpdateOpen(false)}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <div
                    style={{
                        width: '100%',
                    }}
                >
                    <div className="order-product-add-style">
                        <Category
                            orderId={orderId}
                            handleClose={() => setUpdateOpen(false)}
                            type={categoryType.UPDATE}
                            styleId={orderStyleId}
                        />
                    </div>
                </div>
            </Modal>
        </>
    )
}

const CustomCardMedia = styled(CardMedia)({
    height: 200,
    objectFit: 'contain',
})

const CustomTextField = styled(TextField)({
    '& input': {
        padding: '5px',
        fontSize: '24px',
        borderWidth: '0px',
        width: 'auto',
    },
})

const styles = {
    card: {
        overflow: 'visible', // Setting overflow to visible
    },
}

export default function OrderStyle({ orderStyle, orderId }) {
    const dispatch = useDispatch()
    const textFieldRef = useRef(null)
    const [styleTitle, setStyleTitle] = useState(orderStyle?.styleName)

    const apiCall = useRef(_.debounce(() => {}, 1000))

    const handleChange = useCallback(
        (e) => {
            const input = e.target.value
            setStyleTitle(input)
            const values = { id: orderStyle.id, styleName: input }

            apiCall.current.cancel()
            apiCall.current = _.debounce(
                () => dispatch(updateOrderStyleName(values, orderId)),
                1000
            )
            apiCall.current()
        },
        [orderId, orderStyle.id, dispatch]
    )

    const [droppableId, setDroppableId] = useState(() => orderStyle.id)
    useEffect(() => {
        setDroppableId(() => orderStyle.id)
    }, [])

    const handleDragEnd = (event) => {
        if (!event.destination) return
        const startIndex = event.source.index
        const endIndex = event.destination.index

        const reSort = { startIndex, endIndex }
        const values = { reSort, orderStyleId: orderStyle.id }
        dispatch(updateItemSort(values))
    }

    return (
        <Card sx={styles.card} className="order-item-card">
            <CardHeader
                title={
                    <CustomTextField
                        inputRef={textFieldRef}
                        onChange={handleChange}
                        value={styleTitle}
                        inputProps={{ maxLength: 30 }}
                    />
                }
                action={
                    <CardHeaderIcon
                        orderStyleId={orderStyle.id}
                        orderId={orderId}
                    />
                }
            />
            <Grid container spacing={2}>
                <Grid item xs={3}>
                    <CustomCardMedia
                        component="img"
                        image={
                            orderStyle?.doorcolor?.picLink ||
                            'https://res.cloudinary.com/home-space/image/upload/v1633013512/Products/image-not-available_tnweud.png'
                        }
                        alt="order pic"
                    />
                    <div className="order-item-pic-name">
                        {orderStyle?.doorcolor?.name}
                    </div>
                </Grid>
                <Grid item xs={9}>
                    <CardContent>
                        <SearchProduct
                            orderStyleId={orderStyle.id}
                            orderId={orderId}
                            doorcolorId={orderStyle?.doorcolor?.id}
                            itemQty={orderStyle?.orderitems?.length || 0}
                        />
                        <SearchItem
                            orderStyleId={orderStyle.id}
                            orderId={orderId}
                            colorCode={orderStyle?.doorcolor?.colorCode}
                            itemQty={orderStyle?.orderitems?.length || 0}
                        />
                    </CardContent>
                </Grid>
            </Grid>
            {/* <Divider style={{ marginTop: '20px' }} /> */}
            {orderStyle?.orderitems?.length > 0 && (
                <CardContent>
                    <DragDropContext onDragEnd={handleDragEnd}>
                        <TableContainer component={Paper}>
                            <Table
                                sx={{ minWidth: 650 }}
                                aria-label="simple table"
                            >
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Qty</TableCell>
                                        <TableCell>Item</TableCell>
                                        <TableCell>Description</TableCell>
                                        <TableCell>Assembly</TableCell>
                                        <TableCell>Hinge Side</TableCell>
                                        <TableCell>Finished End</TableCell>
                                        <TableCell>Total Price</TableCell>
                                        <TableCell />
                                    </TableRow>
                                </TableHead>
                                <Droppable droppableId={droppableId}>
                                    {(droppableProvided) => (
                                        <TableBody
                                            {...droppableProvided.droppableProps}
                                            ref={droppableProvided.innerRef}
                                        >
                                            {orderStyle?.orderitems?.map(
                                                (orderItem, index) => (
                                                    <Draggable
                                                        key={orderItem.id}
                                                        draggableId={
                                                            orderItem.id
                                                        }
                                                        index={index}
                                                    >
                                                        {(
                                                            draggableProvided
                                                        ) => (
                                                            <OrderItemLists
                                                                orderItem={
                                                                    orderItem
                                                                }
                                                                orderId={
                                                                    orderId
                                                                }
                                                                key={
                                                                    orderItem.id
                                                                }
                                                                orderStyleId={
                                                                    orderStyle.id
                                                                }
                                                                draggableProvided={
                                                                    draggableProvided
                                                                }
                                                            />
                                                        )}
                                                    </Draggable>
                                                )
                                            )}
                                            {droppableProvided.placeholder}
                                        </TableBody>
                                    )}
                                </Droppable>
                            </Table>
                        </TableContainer>
                    </DragDropContext>
                </CardContent>
            )}
        </Card>
    )
}
