import React, { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
    addOrderAdditionalCharge,
    deleteOrderAdditionalCharge,
} from '../../store/actions/orderAction'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Paper from '@mui/material/Paper'
import { Button, Divider } from '@mui/material'
import * as Yup from 'yup'
import AppForm from '../form/AppForm'
import AppInput from '../form/AppInput'
import SubmitButton from '../form/SubmitButton'
import { DELETE_CONFIRM } from '../../constants/form'

const ChargeItem = ({ charge, orderId }) => {
    const dispatch = useDispatch()
    const handleDelete = useCallback(
        (id) => {
            if (window.confirm(DELETE_CONFIRM)) {
                dispatch(deleteOrderAdditionalCharge(id, orderId))
            }
        },
        [orderId, dispatch]
    )
    return (
        <TableRow>
            <TableCell>{charge?.item}</TableCell>
            <TableCell>${charge?.amount?.toFixed(2)}</TableCell>
            <TableCell>{charge?.note}</TableCell>
            <TableCell style={{ width: '50px' }}>
                <Button
                    size="small"
                    onClick={() => handleDelete(charge.id)}
                    variant="outlined"
                    color="error"
                >
                    Delete
                </Button>
            </TableCell>
        </TableRow>
    )
}

const validationSchema = Yup.object().shape({
    item: Yup.string().required().max(150).label('Charge Item'),
    amount: Yup.number().required().label('Charge Amount'),
    note: Yup.string().max(200).label('Note'),
})

const AdditionalChargeForm = () => {
    const dispatch = useDispatch()
    const { order } = useSelector((state) => state.order)
    return (
        <div>
            {order?.orderadditionalcharges?.length > 0 && (
                <div>
                    <TableContainer component={Paper}>
                        <Table aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell>Item</TableCell>
                                    <TableCell>Amount</TableCell>
                                    <TableCell>Note</TableCell>
                                    <TableCell
                                        style={{ width: '50px' }}
                                    ></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {order?.orderadditionalcharges?.map(
                                    (charge) => (
                                        <ChargeItem
                                            key={charge.id}
                                            charge={charge}
                                            orderId={order.id}
                                        />
                                    )
                                )}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </div>
            )}
            <div style={{ marginTop: '30px' }} />
            <Divider>Add New Additional Charge</Divider>
            <div>
                <AppForm
                    initialValues={{
                        item: '',
                        amount: '',
                        note: '',
                    }}
                    onSubmit={(values, { resetForm }) => {
                        const submitValues = { ...values, orderId: order.id }
                        dispatch(addOrderAdditionalCharge(submitValues))
                        resetForm()
                    }}
                    validationSchema={validationSchema}
                >
                    <AppInput
                        name="item"
                        label="Additional Charge Item"
                        type="text"
                        inputProps={{
                            maxLength: 150,
                        }}
                        required={true}
                    />
                    <AppInput
                        name="amount"
                        label="Amount"
                        type="number"
                        inputProps={{
                            maxLength: 10,
                        }}
                        required={true}
                    />
                    <AppInput
                        name="note"
                        label="Note"
                        type="text"
                        inputProps={{
                            maxLength: 230,
                        }}
                    />
                    <SubmitButton title="Submit" />
                </AppForm>
            </div>
        </div>
    )
}

export default AdditionalChargeForm
