import React from 'react'
import * as Yup from 'yup'
import { useDispatch } from 'react-redux'
import AppForm from '../form/AppForm'
import AppInput from '../form/AppInput'
import SubmitButton from '../form/SubmitButton'
import Header from '../form/Header'
import { formTypeList } from '../../constants/form'
import selectList from '../../constants/selectList.json'
import {
    createSupplierContact,
    updateSupplierContact,
} from '../../store/actions/supplierAction'
import AppSelect from '../form/AppSelect'

const validationSchema = Yup.object().shape({
    name: Yup.string().required().label('Name'),
    email: Yup.string().required().email().label('Email'),
    phone: Yup.string().required().label('Phone'),
})

const SupplierContactForm = ({
    formType,
    supplierId,
    handleClose,
    propsValue = null,
}) => {
    const dispatch = useDispatch()
    return (
        <>
            <Header
                title={`${formType === formTypeList.NEW ? 'ADD' : ''}${
                    formType === formTypeList.EDIT ? 'EDIT' : ''
                } SUPPLIER CONTACT`}
            />
            <AppForm
                initialValues={{
                    name: propsValue ? propsValue.name : '',
                    phone: propsValue ? propsValue.phone : '',
                    email: propsValue ? propsValue.email : '',
                    position: propsValue
                        ? propsValue.position
                        : selectList.position.default,
                    currentStatus: propsValue
                        ? propsValue.currentStatus
                        : selectList.employmentStatus.default,
                }}
                onSubmit={(values) => {
                    const submitValues =
                        formType === formTypeList.NEW
                            ? { ...values, supplierId }
                            : { ...propsValue, ...values }
                    formType === formTypeList.NEW
                        ? dispatch(
                              createSupplierContact(submitValues, supplierId),
                              handleClose()
                          )
                        : dispatch(
                              updateSupplierContact(submitValues, supplierId),
                              handleClose()
                          )
                }}
                validationSchema={validationSchema}
            >
                <AppInput
                    name="name"
                    label="Name"
                    type="text"
                    inputProps={{
                        maxLength: 100,
                    }}
                    required={true}
                />

                <AppInput
                    name="email"
                    label="Email"
                    type="text"
                    inputProps={{
                        maxLength: 200,
                    }}
                    required={true}
                />
                <AppInput
                    name="phone"
                    label="Phone"
                    type="text"
                    inputProps={{ maxLength: 30 }}
                    required={true}
                />
                <AppSelect
                    name="position"
                    label="Position"
                    options={selectList.position.list}
                />
                <AppSelect
                    name="currentStatus"
                    label="Employment Status"
                    options={selectList.employmentStatus.list}
                />
                <SubmitButton title="Submit" />
            </AppForm>
        </>
    )
}

export default SupplierContactForm
