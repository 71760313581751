import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { green, red } from '@mui/material/colors'
import BlockIcon from '@mui/icons-material/Block'
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline'
import { getOneWarehouse } from '../../store/actions/warehouseAction'
import { GET_ONE_WAREHOUSE } from '../../store/types'
import Layout from '../../components/Layout'
import { Button, Container, Paper } from '@mui/material'
import { useNavigate, useParams } from 'react-router-dom'
import { checkUserRole } from '../../common/checkUserRole'
import { roleListId, roleType } from '../../config/roleList'
import BasicModal from '../../components/modal/BasicModal'
import AddNewWarehouseMap from '../../components/warehouseMap/AddNewWarehouseMap'
import WarehouseMapList from '../../components/warehouseMap/WarehouseMapList'

const Warehouse = () => {
    const { id } = useParams()
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const { warehouse } = useSelector((state) => state.warehouse)
    const { userInfo } = useSelector((state) => state.auth)
    const { roleList } = useSelector((state) => state.user)
    const [open, setOpen] = useState(false)
    useEffect(() => {
        dispatch(getOneWarehouse(id))
        return () => {
            dispatch({
                type: GET_ONE_WAREHOUSE,
                payload: null,
            })
        }
    }, [dispatch, id])

    const warehouseMapAisle = useMemo(() => {
        if (warehouse?.warehousemaps?.length > 0) {
            return [
                ...new Set(
                    warehouse.warehousemaps
                        .map((item) => item.aisle)
                        .sort((a, b) => a - b)
                ),
            ]
        }
        return []
    }, [warehouse])

    return (
        <Layout>
            {warehouse ? (
                <Container>
                    <div style={{ marginTop: '50px' }} />
                    <div className="order-products-box">
                        <Button
                            variant="outlined"
                            color="success"
                            onClick={() => navigate(-1)}
                            style={{ marginBottom: '10px' }}
                        >
                            Back
                        </Button>
                    </div>

                    <Paper
                        style={{
                            padding: '20px',
                            display: 'flex',
                            flexDirection: 'column',
                            gap: '10px',
                            marginBottom: '20px',
                        }}
                    >
                        <div>Name: {warehouse?.name}</div>
                        <div>Short Name: {warehouse?.shortName}</div>
                        <div>
                            Warehouse: {warehouse?.address}
                            {warehouse?.address1
                                ? `, ${warehouse?.address1}`
                                : ''}
                            , {warehouse?.city}, {warehouse?.province},{' '}
                            {warehouse?.postCode} {warehouse?.country}
                        </div>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            Active:
                            {warehouse.isActive ? (
                                <CheckCircleOutlineIcon
                                    style={{ color: green[500] }}
                                />
                            ) : (
                                <BlockIcon style={{ color: red[500] }} />
                            )}
                        </div>
                    </Paper>
                    <div>
                        <BasicModal open={open} setOpen={setOpen}>
                            <AddNewWarehouseMap
                                warehouseId={warehouse.id}
                                setOpen={setOpen}
                            />
                        </BasicModal>
                        <Button
                            size="small"
                            variant="outlined"
                            onClick={() =>
                                checkUserRole(
                                    userInfo,
                                    roleList,
                                    roleListId.WAREHOUSE_MAP,
                                    roleType.CREATE
                                )
                                    ? setOpen(true)
                                    : alert('Not Permit User')
                            }
                        >
                            Add New Map
                        </Button>
                    </div>
                    {warehouseMapAisle?.length > 0 ? (
                        <div style={{ marginTop: '20px' }}>
                            {warehouseMapAisle?.map((aisle) => (
                                <WarehouseMapList
                                    key={aisle}
                                    aisle={aisle}
                                    warehouseId={warehouse.id}
                                />
                            ))}
                        </div>
                    ) : null}
                    <div></div>
                </Container>
            ) : null}
        </Layout>
    )
}

export default Warehouse
