import React from 'react'
import SupplierContactForm from './SupplierContactForm'
import { formTypeList } from '../../constants/form'

const SupplierContactNew = ({ supplierId, handleClose }) => {
    return (
        <div>
            <SupplierContactForm
                supplierId={supplierId}
                handleClose={handleClose}
                formType={formTypeList.NEW}
            />
        </div>
    )
}

export default SupplierContactNew
