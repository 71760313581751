import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { formTypeList } from '../../constants/form'
import { GET_ONE_WAREHOUSE_MAP } from '../../store/types'
import { getOneWarehouseMap } from '../../store/actions/warehouseAction'
import WarehouseMapForm from './WarehouseMapForm'

const EditWarehouseMap = ({ id, setOpen, warehouseId }) => {
    const dispatch = useDispatch()
    const { warehouseMap } = useSelector((state) => state.warehouse)
    useEffect(() => {
        dispatch(getOneWarehouseMap(id))
        return () => {
            dispatch({
                type: GET_ONE_WAREHOUSE_MAP,
                payload: null,
            })
        }
    }, [dispatch, id])
    return warehouseMap ? (
        <WarehouseMapForm
            formType={formTypeList.EDIT}
            setOpen={setOpen}
            propsState={warehouseMap}
            warehouseId={warehouseId}
        />
    ) : null
}

export default EditWarehouseMap
