import React from 'react'
import * as Yup from 'yup'
import { Button, Container } from '@mui/material'
import AppForm from '../form/AppForm'
import AppInput from '../form/AppInput'
import SubmitButton from '../form/SubmitButton'
import Header from '../form/Header'
import AppSelect from '../form/AppSelect'
import { useDispatch, useSelector } from 'react-redux'
import {
    addNewSupplier,
    updateSupplier,
} from '../../store/actions/supplierAction'
import { useNavigate } from 'react-router-dom'
import { formTypeList } from '../../constants/form'
import AppCheckbox from '../form/AppCheckbox'
import SupplierCotactList from './SupplierCotactList'
import selectList from '../../constants/selectList.json'
import { roleListId, roleType } from '../../config/roleList'
import { checkUserRole } from '../../common/checkUserRole'
import AppInputPic from '../form/AppInputPic'

const validationSchema = Yup.object().shape({
    name: Yup.string().required().label('Name'),
    businessNumber: Yup.string().required().label('Business Number'),
    email: Yup.string().required().email().label('Email'),
    phone: Yup.string().required().label('Phone'),
    ownerName: Yup.string().required().label('Owner Name'),
    address: Yup.string().required().label('Address'),
    city: Yup.string().required().label('City'),
    province: Yup.string().required().label('Province'),
    postCode: Yup.string().required().label('Postal Code'),
    country: Yup.string().required().label('Country'),
})

const SupplierForm = ({ formType, propsValue = null }) => {
    const readyOnlyStatus = formType === formTypeList.EDIT ? true : false
    const infoStatus = formType === formTypeList.READ ? true : false
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const { userInfo } = useSelector((state) => state.auth)
    const { roleList } = useSelector((state) => state.user)
    return (
        <>
            <Container>
                <Button onClick={() => navigate(-1)}>Back</Button>
                <Header
                    title={`${formType === formTypeList.NEW ? 'ADD' : ''}${
                        formType === formTypeList.EDIT ? 'EDIT' : ''
                    } SUPPLIER`}
                />
                <AppForm
                    initialValues={{
                        name: propsValue ? propsValue.name : '',
                        businessNumber: propsValue
                            ? propsValue.businessNumber
                            : '',
                        ownerName: propsValue ? propsValue.ownerName : '',
                        address: propsValue ? propsValue.address : '',
                        city: propsValue ? propsValue.city : '',
                        province: propsValue ? propsValue.province : '',
                        postCode: propsValue ? propsValue.postCode : '',
                        country: propsValue ? propsValue.country : '',
                        phone: propsValue ? propsValue.phone : '',
                        email: propsValue ? propsValue.email : '',
                        fax: propsValue ? propsValue.fax : '',
                        information: propsValue ? propsValue.information : '',
                        type: propsValue ? propsValue.type : '',
                        currency: propsValue
                            ? propsValue.currency
                            : selectList.currency.default,
                        picLink: propsValue ? propsValue.picLink : '',
                        isActive: propsValue ? propsValue.isActive : true,
                    }}
                    onSubmit={(values) => {
                        const submitValues =
                            formType === formTypeList.NEW
                                ? values
                                : { ...propsValue, ...values }
                        formType === formTypeList.NEW
                            ? dispatch(addNewSupplier(submitValues, navigate))
                            : dispatch(updateSupplier(submitValues, navigate))
                    }}
                    validationSchema={validationSchema}
                >
                    <AppInput
                        name="name"
                        label="Name"
                        type="text"
                        inputProps={{
                            maxLength: 100,
                            readOnly:
                                formType === formTypeList.READ
                                    ? true
                                    : readyOnlyStatus,
                        }}
                        required={true}
                    />
                    <AppInput
                        name="businessNumber"
                        label="Business Number"
                        type="text"
                        inputProps={{ maxLength: 50, readOnly: infoStatus }}
                        required={true}
                    />
                    <AppInput
                        name="ownerName"
                        label="Owner Name"
                        type="text"
                        inputProps={{ maxLength: 50, readOnly: infoStatus }}
                        required={true}
                    />
                    <AppInput
                        name="address"
                        label="Address"
                        type="text"
                        inputProps={{ maxLength: 250, readOnly: infoStatus }}
                        required={true}
                    />
                    <AppInput
                        name="city"
                        label="City"
                        type="text"
                        inputProps={{ maxLength: 40, readOnly: infoStatus }}
                        required={true}
                    />
                    <AppInput
                        name="province"
                        label="Province"
                        type="text"
                        inputProps={{ maxLength: 40, readOnly: infoStatus }}
                        required={true}
                    />
                    <AppInput
                        name="postCode"
                        label="Postal Code"
                        type="text"
                        inputProps={{ maxLength: 20, readOnly: infoStatus }}
                        required={true}
                    />
                    <AppInput
                        name="country"
                        label="Country"
                        type="text"
                        inputProps={{ maxLength: 40, readOnly: infoStatus }}
                        required={true}
                    />
                    <AppInput
                        name="email"
                        label="Email"
                        type="text"
                        inputProps={{
                            maxLength: 200,
                            readOnly:
                                formType === formTypeList.READ
                                    ? true
                                    : readyOnlyStatus,
                        }}
                        required={true}
                    />
                    <AppInput
                        name="phone"
                        label="Phone"
                        type="text"
                        inputProps={{ maxLength: 30, readOnly: infoStatus }}
                        required={true}
                    />
                    <AppInput
                        name="fax"
                        label="Fax"
                        type="text"
                        inputProps={{ maxLength: 30, readOnly: infoStatus }}
                    />
                    <AppInput
                        name="information"
                        label="Information"
                        multiline
                        rows={4}
                        inputProps={{ readOnly: infoStatus }}
                    />
                    <AppInput
                        name="type"
                        label="Type"
                        type="text"
                        inputProps={{ maxLength: 30, readOnly: infoStatus }}
                    />
                    <AppSelect
                        name="currency"
                        label="Currency"
                        options={selectList.currency.list}
                        inputProps={{ readOnly: infoStatus }}
                    />
                    <AppInputPic
                        name="picLink"
                        label="Supplier Logo"
                        type="text"
                        inputProps={{ maxLength: 255 }}
                    />
                    <AppCheckbox
                        name="isActive"
                        label="Is Active"
                        disabled={infoStatus}
                    />
                    {formType !== formTypeList.READ ? (
                        <SubmitButton title="Submit" />
                    ) : checkUserRole(
                          userInfo,
                          roleList,
                          roleListId.SUPPLIER_CONTACT,
                          roleType.READ
                      ) ? (
                        <SupplierCotactList
                            suppliercontacts={propsValue?.suppliercontacts}
                            supplierId={propsValue?.id}
                        />
                    ) : null}
                </AppForm>
            </Container>
        </>
    )
}

export default SupplierForm
