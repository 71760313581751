import {
    CHANGE_USERS_PAGE,
    GET_ALL_USERS,
    GET_ONE_USER,
    GET_ROLES_LIST,
    SEARCH_USERS,
} from '../types'

const initialState = {
    users: null,
    user: null,
    pages: '',
    page: '',
    count: '',
    keyword: '',
    rolesList: null,
}

const userReducer = (state = initialState, action) => {
    const { type, payload } = action
    switch (type) {
        case GET_ALL_USERS:
            return {
                ...state,
                users: payload.users,
                pages: payload.pages,
                page: payload.page,
                count: payload.count,
                keyword: payload.keyword,
                user: null,
            }
        case SEARCH_USERS:
            return {
                ...state,
                keyword: payload.keyword,
                page: 1,
            }
        case CHANGE_USERS_PAGE:
            return {
                ...state,
                page: payload,
            }
        case GET_ONE_USER:
            return {
                ...state,
                user: payload,
            }
        case GET_ROLES_LIST:
            return {
                ...state,
                roleList: payload,
            }
        default:
            return state
    }
}

export default userReducer
