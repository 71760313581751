import Sidebar from './Sidebar'
import { Box, createTheme, Stack, ThemeProvider } from '@mui/material'
import Navbar from './Navbar'
import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { useColorScheme } from '@mui/material/styles'

function Layout({ children }) {
    const navigate = useNavigate()
    const { mode, setMode } = useColorScheme()
    const { userInfo } = useSelector((state) => state.auth)
    // const { mode } = useSelector((state) => state.mode)
    useEffect(() => {
        if (!userInfo) {
            navigate('/login')
        }
    }, [userInfo, navigate])

    const darkTheme = createTheme({
        palette: {
            mode: mode,
        },
    })
    return (
        <ThemeProvider theme={darkTheme}>
            <Box bgcolor={'background.default'} color={'text.primary'}>
                <Navbar />
                <Stack
                    direction="row"
                    spacing={2}
                    justifyContent="space-between"
                    minHeight="95vh"
                >
                    <Sidebar
                        mode={mode}
                        setMode={setMode}
                        userInfo={userInfo}
                    />
                    <Box flex={4} p={{ xs: 0, md: 2 }}>
                        {children}
                    </Box>
                    {/* <Rightbar /> */}
                </Stack>
            </Box>
        </ThemeProvider>
    )
}

export default Layout
