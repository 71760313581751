import React from 'react'
import ProductDoorColorList from './ProductDoorColorList'

const ProductDoorColors = ({ doorColors }) => {
    return (
        <div style={{ marginTop: '20px' }}>
            {doorColors?.map((doorColor) => (
                <ProductDoorColorList
                    doorColor={doorColor}
                    key={doorColor.id}
                />
            ))}
        </div>
    )
}

export default ProductDoorColors
