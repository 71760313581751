import { combineReducers } from 'redux'
import loadingReducer from './loadingReducer'
import errorReducer from './errorReducer'
import authReducer from './authReducer'
import userReducer from './usersReducer'
import modeReducer from './modeReducer'
import supplierReducer from './supplierReducer'
import logReducer from './logReducer'
import itemReducer from './itemReducer'
import productCategoryReducer from './productCategoryReducer'
import doorCategoryReducer from './doorCategoryReducer'
import productReducer from './productReducer'
import productColorReducer from './productColorReducer'
import customerReducer from './customerReducer'
import warehouseReducer from './warehouseReducer'
import orderReducer from './orderReducer'
import baseTaxReducer from './baseTaxReducer'
import purchaseOrderReducer from './purchaseOrderReducer'
import shipmentReducer from './shipmentReducer'
import defectiveItemReducer from './defectiveItemReducer'
import orderPaymentReducer from './orderPaymentReducer'
import orderShipmentReducer from './orderShipmentReducer'
import itemRecordReducer from './itemRecordReducer'

export default combineReducers({
    loading: loadingReducer,
    errors: errorReducer,
    auth: authReducer,
    user: userReducer,
    mode: modeReducer,
    supplier: supplierReducer,
    logs: logReducer,
    item: itemReducer,
    productCategory: productCategoryReducer,
    doorCategory: doorCategoryReducer,
    product: productReducer,
    productColor: productColorReducer,
    customer: customerReducer,
    warehouse: warehouseReducer,
    order: orderReducer,
    baseTax: baseTaxReducer,
    purchaseOrder: purchaseOrderReducer,
    shipment: shipmentReducer,
    defectiveItem: defectiveItemReducer,
    orderPayment: orderPaymentReducer,
    orderShipment: orderShipmentReducer,
    itemRecord: itemRecordReducer,
})
