import React, { useCallback, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Accordion from '@mui/material/Accordion'
import AccordionSummary from '@mui/material/AccordionSummary'
import AccordionDetails from '@mui/material/AccordionDetails'
import Typography from '@mui/material/Typography'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import EditIcon from '@mui/icons-material/Edit'
import DeleteForeverIcon from '@mui/icons-material/DeleteForever'
import Stack from '@mui/material/Stack'
import ProductStyleList from '../productStyle/ProductStyleList'
import BasicModal from '../modal/BasicModal'
import Edit from './Edit'
import { deleteProductCategory } from '../../store/actions/productCategoryAction'
import { DELETE_CONFIRM } from '../../constants/form'
import { checkUserRole } from '../../common/checkUserRole'
import { roleListId, roleType } from '../../config/roleList'
import { Button } from '@mui/material'
import AddNew from '../productStyle/AddNew'

const ProductCategoryList = ({ productCategory }) => {
    const dispatch = useDispatch()
    const { userInfo } = useSelector((state) => state.auth)
    const { roleList } = useSelector((state) => state.user)
    const [editOpen, setEditOpen] = useState(false)
    const [styleOpen, setStyleOpen] = useState(false)

    const handleEdit = useCallback(() => {
        setEditOpen(true)
    }, [])

    const handleDelete = useCallback(
        (id) => {
            if (window.confirm(DELETE_CONFIRM)) {
                dispatch(deleteProductCategory(id))
            }
        },
        [dispatch]
    )

    return (
        <>
            <BasicModal open={editOpen} setOpen={setEditOpen}>
                <Edit id={productCategory.id} setOpen={setEditOpen} />
            </BasicModal>
            <BasicModal open={styleOpen} setOpen={setStyleOpen}>
                <AddNew
                    productCategoryId={productCategory.id}
                    setOpen={setStyleOpen}
                />
            </BasicModal>
            <Accordion>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                >
                    <Typography>{productCategory.name}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <div
                        style={{
                            marginBottom: '10px',
                            display: 'flex',
                            alignItems: 'center',
                        }}
                    >
                        <EditIcon
                            onClick={() =>
                                checkUserRole(
                                    userInfo,
                                    roleList,
                                    roleListId.PRODUCT_CATEGORY,
                                    roleType.EDIT
                                )
                                    ? handleEdit()
                                    : alert('Not Permit User')
                            }
                            style={{
                                marginRight: '20px',
                                cursor: 'pointer',
                            }}
                        />
                        {productCategory?.productstyles?.length === 0 && (
                            <DeleteForeverIcon
                                onClick={() =>
                                    checkUserRole(
                                        userInfo,
                                        roleList,
                                        roleListId.PRODUCT_CATEGORY,
                                        roleType.DELETE
                                    )
                                        ? handleDelete(productCategory.id)
                                        : alert('Not Permit User')
                                }
                                style={{
                                    color: 'red',
                                    cursor: 'pointer',
                                    marginRight: '20px',
                                }}
                            />
                        )}
                        <Button
                            color="secondary"
                            variant="outlined"
                            size="small"
                            onClick={() =>
                                checkUserRole(
                                    userInfo,
                                    roleList,
                                    roleListId.PRODUCT_STYLE,
                                    roleType.CREATE
                                )
                                    ? setStyleOpen(true)
                                    : alert('Not Permit User')
                            }
                        >
                            Add New Product Style
                        </Button>
                    </div>
                    <Stack spacing={2}>
                        {productCategory?.productstyles?.length > 0 &&
                            productCategory?.productstyles?.map(
                                (productStyle) => (
                                    <ProductStyleList
                                        key={productStyle.id}
                                        productStyle={productStyle}
                                    />
                                )
                            )}
                    </Stack>
                </AccordionDetails>
            </Accordion>
        </>
    )
}

export default ProductCategoryList
