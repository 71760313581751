import logoList from '../constants/logoList'

const CLIENT = process.env.REACT_APP_CLIENT || 'woodworth'

const Loader = () => {
    return (
        <div className="loading-page">
            {/* <img
                src="https://res.cloudinary.com/dan5ie5wm/image/upload/v1616609279/samples/loading_mqn5mz.gif"
                alt="loading"
                width="150px"
                height="150px"
            /> */}
            <div className="lds-ring">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <span className="lds-logo">
                    <img
                        src={logoList?.[CLIENT]?.LOGO}
                        alt="logo"
                        className="lds-logo-pic"
                    />
                </span>
            </div>
        </div>
    )
}

export default Loader
