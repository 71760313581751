import React, { useEffect, useState } from 'react'
import { useReactToPrint } from 'react-to-print'
import { Button } from '@mui/material'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import Layout from '../../components/Layout'
import ItemToPrint from '../../components/orderShipment/ItemToPrint'
import { getOneOrderShipment } from '../../store/actions/orderShipmentAction'

const PrintItems = () => {
    const [componentRef, setComponentRef] = useState(null)
    const navigate = useNavigate()
    const { id } = useParams()
    const dispatch = useDispatch()

    const { orderShipment } = useSelector((state) => state.orderShipment)

    useEffect(() => {
        if (id) dispatch(getOneOrderShipment(id))
        window.scrollTo(0, 0)
    }, [dispatch, id])

    const handlePrint = useReactToPrint({
        content: () => componentRef,
        onAfterPrint: () => {
            setComponentRef(null)
            navigate(-1)
        },
    })
    return (
        <Layout>
            <div style={{ marginTop: '20px' }}>
                <Button
                    variant="outlined"
                    color="grey"
                    onClick={() => navigate(-1)}
                >
                    Back
                </Button>
                <Button
                    style={{ marginLeft: '10px' }}
                    variant="outlined"
                    color="primary"
                    onClick={handlePrint}
                >
                    Print
                </Button>
            </div>
            <ItemToPrint ref={setComponentRef} order={orderShipment} />
        </Layout>
    )
}

export default PrintItems
