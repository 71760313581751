import React, { useCallback, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'
import * as XLSX from 'xlsx'
import Layout from '../../components/Layout'
import {
    Button,
    Container,
    Grid,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField,
} from '@mui/material'

const ItemList = ({ item, setPoItems }) => {
    const textFieldRef = useRef(null)
    const volumeFieldRef = useRef(null)
    const handlePcsPerCtnChange = useCallback(
        (event, id) => {
            setPoItems((prev) =>
                prev.map((oldItem) => {
                    const tempPcsPerCtn = event?.target?.value || 0
                    const tempCtn =
                        tempPcsPerCtn > 0
                            ? Math.ceil(item.quantity / tempPcsPerCtn)
                            : 0
                    return oldItem.id === id
                        ? {
                              ...oldItem,
                              pcsPerCtn: Number(tempPcsPerCtn),
                              ctn: tempCtn,
                          }
                        : oldItem
                })
            )
        },
        [item, setPoItems]
    )

    const handleVolumeChange = useCallback(
        (event, id) => {
            setPoItems((prev) =>
                prev.map((oldItem) => {
                    const tempVolume = event?.target?.value || 0
                    const tempTotalVolume = Number(
                        (tempVolume * item.quantity).toFixed(4)
                    )

                    return oldItem.id === id
                        ? {
                              ...oldItem,
                              unitVolume: Number(tempVolume),
                              totalVolume: tempTotalVolume,
                          }
                        : oldItem
                })
            )
        },
        [item, setPoItems]
    )

    const handleCommentChange = useCallback(
        (event, id) => {
            setPoItems((prev) =>
                prev.map((oldItem) => {
                    return oldItem.id === id
                        ? { ...oldItem, comment: event.target.value ?? '' }
                        : oldItem
                })
            )
        },
        [setPoItems]
    )

    const handleMouseOver = useCallback(() => {
        if (textFieldRef.current) {
            textFieldRef.current.select()
        }
    }, [textFieldRef])

    const handleMouseOverVolume = useCallback(() => {
        if (volumeFieldRef.current) {
            volumeFieldRef.current.select()
        }
    }, [volumeFieldRef])

    return (
        <TableRow>
            <TableCell>{item.id}</TableCell>
            <TableCell>{item.color}</TableCell>
            <TableCell>{item.sku}</TableCell>
            <TableCell>{item.quantity}</TableCell>
            <TableCell>
                <TextField
                    inputRef={textFieldRef}
                    onMouseOver={handleMouseOver}
                    size="small"
                    type="number"
                    style={{ width: '100px' }}
                    value={item.pcsPerCtn}
                    onChange={(e) => handlePcsPerCtnChange(e, item.id)}
                />
            </TableCell>
            <TableCell>{item.ctn}</TableCell>
            <TableCell>
                <TextField
                    inputRef={volumeFieldRef}
                    onMouseOver={handleMouseOverVolume}
                    size="small"
                    type="number"
                    style={{ width: '100px' }}
                    value={item.unitVolume}
                    onChange={(e) => handleVolumeChange(e, item.id)}
                />
            </TableCell>
            <TableCell>{item.totalVolume}</TableCell>
            <TableCell>
                <TextField
                    size="small"
                    type="text"
                    value={item.comment}
                    onChange={(e) => handleCommentChange(e, item.id)}
                />
            </TableCell>
        </TableRow>
    )
}
const PoItemData = ({ poItems, setPoItems }) => {
    return (
        <div>
            <TableContainer component={Paper}>
                <Table aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell>ID</TableCell>
                            <TableCell></TableCell>
                            <TableCell>SKU</TableCell>
                            <TableCell>Quantity</TableCell>
                            <TableCell>pcs/ctn</TableCell>
                            <TableCell>CTN</TableCell>
                            <TableCell>Unit Volume</TableCell>
                            <TableCell>Total Volume</TableCell>
                            <TableCell>Comment</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {poItems?.map((item) => (
                            <ItemList
                                item={item}
                                key={item.id}
                                setPoItems={setPoItems}
                            />
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    )
}

const PurchaseOrderDownload = () => {
    const navigate = useNavigate()
    const { purchaseOrder } = useSelector((state) => state.purchaseOrder)

    const [poItems, setPoItems] = useState(() => {
        return (
            purchaseOrder?.purchaseorderitems?.map((item, index) => {
                const values = {
                    id: index + 1,
                    color: item.color,
                    sku: item.sku,
                    quantity: item.quantity,
                    pcsPerCtn: item.item.pcsPerBox,
                    ctn: Math.ceil(item.quantity / item.item.pcsPerBox),
                    unitVolume: item.item.volume,
                    totalVolume: Number(
                        (item.item.volume * item.quantity).toFixed(4)
                    ),
                    comment: '',
                }
                return values
            }) || []
        )
    })

    const generatePO = useCallback(() => {
        const header = [
            ['UNICABINET INDUSTRY CO., LTD'],
            ['199 Moo 9, Lamtasao Sub-district, Wangnoi District,'],
            ['Ayutthaya Province, Thailand 13170'],
            ['Tel:+66 3535 5893', 'Email: pd01@unicabinetgroup.com'],
            [],
            ['Purchase Order'],
            ['Company Name', '', 'Email', '', 'Multiplier'],
            ['Contact Person', '', 'State', '', 'PO#'],
            ['Phone Number', '', 'Zip Code', '', 'Date'],
            ['Shipping Address', '', '', '', ''],
            [],
            [
                'NO.',
                '',
                'SKU',
                'Quantity',
                'pcs/ctn',
                'CTN',
                'Unit Volume',
                'Total Volume',
                'Comment',
            ],
        ]

        const data = poItems.map((item) => [
            item.id,
            item.color,
            item.sku,
            item.quantity,
            item.pcsPerCtn,
            item.ctn,
            item.unitVolume,
            item.totalVolume,
            item.comment,
        ])

        const worksheetData = [...header, ...data]

        const worksheet = XLSX.utils.aoa_to_sheet(worksheetData)

        worksheet['!cols'] = [
            { wpx: 100 },
            { wpx: 100 },
            { wpx: 100 },
            { wpx: 100 },
            { wpx: 100 },
            { wpx: 100 },
            { wpx: 100 },
            { wpx: 100 },
            { wpx: 100 },
            { wpx: 100 },
        ]

        // Create a workbook and add the worksheet
        const workbook = XLSX.utils.book_new()
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Purchase Order')

        // Export to Excel file
        XLSX.writeFile(workbook, `Purchase_Order_${Date.now()}.xlsx`)
    }, [poItems])

    return (
        <Layout>
            {purchaseOrder ? (
                <Container>
                    <Button onClick={() => navigate(-1)}>Back</Button>
                    <div>
                        <Button
                            variant="contained"
                            color="secondary"
                            onClick={generatePO}
                        >
                            download Factory PO
                        </Button>
                    </div>
                    <div style={{ marginTop: '20px' }} />
                    <Grid container rowSpacing={2}>
                        <Grid item xs={4}>
                            Supplier:
                        </Grid>
                        <Grid item xs={8}>
                            {purchaseOrder?.supplier?.name}
                        </Grid>
                        <Grid item xs={4}>
                            Order No:
                        </Grid>
                        <Grid item xs={8}>
                            {purchaseOrder.orderId}
                        </Grid>
                        <Grid item xs={4}>
                            Invoice No:
                        </Grid>
                        <Grid item xs={8}>
                            {purchaseOrder.invoiceNo}
                        </Grid>
                        <Grid item xs={4}>
                            PO No:
                        </Grid>
                        <Grid item xs={8}>
                            {purchaseOrder.po}
                        </Grid>
                    </Grid>
                    {poItems?.length > 0 && (
                        <div style={{ marginTop: '30px' }}>
                            <PoItemData
                                poItems={poItems}
                                setPoItems={setPoItems}
                            />
                        </div>
                    )}
                </Container>
            ) : null}
        </Layout>
    )
}

export default PurchaseOrderDownload
