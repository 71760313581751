import React from 'react'
import * as Yup from 'yup'
import { useDispatch } from 'react-redux'
import { Container, Grid } from '@mui/material'
import AppForm from '../form/AppForm'
import AppInput from '../form/AppInput'
import SubmitButton from '../form/SubmitButton'
import Header from '../form/Header'
import { formTypeList } from '../../constants/form'
import { updateShipmentItem } from '../../store/actions/shipmentAction'

const validationSchema = Yup.object().shape({
    stock: Yup.number().required().label('Received QTY'),
})

const ShipmentItemForm = ({ formType, setOpen, shipmentId, propsValue }) => {
    const dispatch = useDispatch()

    return (
        <Container>
            <Header
                title={`${formType === formTypeList.NEW ? 'ADD' : ''}${
                    formType === formTypeList.EDIT ? 'EDIT' : ''
                } RECEIVED QTY`}
            />
            <Grid container rowSpacing={2}>
                <Grid item xs={4}>
                    Color:
                </Grid>
                <Grid item xs={8}>
                    {propsValue?.purchaseorderitem?.color}
                </Grid>
                <Grid item xs={4}>
                    SKU:
                </Grid>
                <Grid item xs={8}>
                    {propsValue?.purchaseorderitem?.sku}
                </Grid>
                <Grid item xs={4}>
                    Item SKU:
                </Grid>
                <Grid item xs={8}>
                    {propsValue?.item?.itemSKU}
                </Grid>
                <Grid item xs={4}>
                    Shipped QTY:
                </Grid>
                <Grid item xs={8}>
                    {propsValue?.quantity}
                </Grid>
            </Grid>
            <div style={{ margin: '20px' }} />
            <AppForm
                initialValues={{
                    stock:
                        formType === formTypeList.NEW
                            ? propsValue.quantity
                            : propsValue.stock,
                }}
                onSubmit={(values) => {
                    const submitValues = {
                        id: propsValue.id,
                        stock: values.stock,
                        itemId: propsValue?.item?.id,
                    }
                    dispatch(
                        updateShipmentItem(submitValues, shipmentId, formType)
                    )
                    setOpen(false)
                }}
                validationSchema={validationSchema}
            >
                <AppInput
                    name="stock"
                    label="Received QTY"
                    type="number"
                    inputProps={{
                        maxLength: 10,
                    }}
                    required={true}
                />

                <SubmitButton title="Submit" />
            </AppForm>
        </Container>
    )
}

export default ShipmentItemForm
