import { useEffect } from 'react'
import { Button, Grid, MenuItem, Select, TextField } from '@mui/material'
import { useState } from 'react'
import { useDispatch } from 'react-redux'
import { SEARCH_ORDERS } from '../../store/types'
import { OrderStatusList, orderTypeList } from '../../constants/orderType'

const SearchOrder = ({ displayKeyword, searchStatus }) => {
    const dispatch = useDispatch()
    const [keyword, setKeyword] = useState('')
    const [status, setStatus] = useState('null')

    useEffect(() => {
        setKeyword(displayKeyword ?? '')
        setStatus(searchStatus ?? 'null')
    }, [displayKeyword, searchStatus])
    const handleSubmit = (e) => {
        e.preventDefault()
        dispatch({
            type: SEARCH_ORDERS,
            payload: {
                keyword,
                status,
            },
        })
    }
    return (
        <div className="search-container">
            <form onSubmit={handleSubmit} noValidate autoComplete="off">
                <Grid container justify="center" alignItems="center">
                    <Grid item xs={5}>
                        <TextField
                            // variant="outlined"
                            fullWidth
                            id="keyword"
                            label="Search..."
                            size="small"
                            value={keyword}
                            onChange={(e) => setKeyword(e.target.value)}
                        />
                    </Grid>
                    <Grid item xs={2}>
                        <Select
                            labelId={`select-label-status`}
                            fullWidth
                            name="status"
                            id="status"
                            size="small"
                            value={status}
                            // variant="standard"
                            onChange={(e) => setStatus(e.target.value)}
                        >
                            <MenuItem value="null">--All--</MenuItem>
                            {Object.values(OrderStatusList)?.map((option) => (
                                <MenuItem key={option} value={option}>
                                    {orderTypeList[option]}
                                </MenuItem>
                            ))}
                        </Select>
                    </Grid>
                    <Grid item style={{ marginLeft: '10px' }}>
                        <Button
                            variant="outlined"
                            color="primary"
                            type="submit"
                        >
                            Search
                        </Button>
                    </Grid>
                </Grid>
            </form>
        </div>
    )
}

export default SearchOrder
