import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import Layout from '../../components/Layout'
import ItemForm from '../../components/item/ItemForm'
import { formTypeList } from '../../constants/form'
import { getAllWarehouseMapsItem } from '../../store/actions/itemAction'

const AddItem = () => {
    const dispatch = useDispatch()
    useEffect(() => {
        dispatch(getAllWarehouseMapsItem())
    }, [dispatch])
    return (
        <Layout>
            <ItemForm formType={formTypeList.NEW} />
        </Layout>
    )
}

export default AddItem
