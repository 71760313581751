const purchaseOrderItemFormat = (items) => {
    if (!items || items.length === 0) {
        return []
    }

    return items.map((item) => {
        const splitItem = item.itemSKU.split('-')
        if (item.itemSKU.includes('-BOX')) {
            return {
                color: 'BOX',
                sku: splitItem[0],
                itemSKU: item.itemSKU,
                quantity: item.autoOrderQty,
                itemId: item.id,
                valid: item.autoOrderQty > 0,
            }
        }
        return {
            color: splitItem[0] || '',
            sku: splitItem[1] || '',
            itemSKU: item.itemSKU,
            quantity: item.autoOrderQty,
            itemId: item.id,
            valid: item.autoOrderQty > 0,
        }
    })
}
export default purchaseOrderItemFormat
