import React, { useMemo } from 'react'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Paper from '@mui/material/Paper'

const ItemList = ({ item }) => {
    const shippedQty = useMemo(() => {
        return (
            item?.shipmentorderitems?.reduce(
                (sum, item) => sum + item.quantity,
                0
            ) || 0
        )
    }, [item?.shipmentorderitems])

    const receivedQty = useMemo(() => {
        return (
            item?.shipmentorderitems?.reduce(
                (sum, item) => (item.confirm ? sum + item.stock : sum),
                0
            ) || 0
        )
    }, [item?.shipmentorderitems])

    return (
        <TableRow>
            <TableCell>{item.color}</TableCell>
            <TableCell>{item.sku}</TableCell>
            <TableCell>{item?.item?.itemSKU}</TableCell>
            <TableCell>{item.quantity}</TableCell>
            <TableCell>{shippedQty}</TableCell>
            <TableCell>{receivedQty}</TableCell>
        </TableRow>
    )
}

const PurchaseOrderItemList = ({ displayData }) => {
    return (
        <div style={{ marginTop: '30px' }}>
            <TableContainer component={Paper}>
                <Table aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell>Color</TableCell>
                            <TableCell>SKU</TableCell>
                            <TableCell>Item SKU</TableCell>
                            <TableCell>Order Qty</TableCell>
                            <TableCell>Shipped Qty</TableCell>
                            <TableCell>Received Qty</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {displayData?.map((item, index) => (
                            <ItemList item={item} key={index} />
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    )
}

export default PurchaseOrderItemList
