import {
    CHANGE_SUPPLIERS_PAGE,
    GET_ALL_SUPPLIERS,
    GET_ONE_SUPPLIER,
    GET_ONE_SUPPLIER_CONTACT,
    SEARCH_SUPPLIERS,
} from '../types'

const initialState = {
    suppliers: null,
    supplier: null,
    supplierContact: null,
    pages: '',
    page: '',
    count: '',
    keyword: '',
}

export default function supplierReducer(state = initialState, action) {
    const { type, payload } = action
    switch (type) {
        case GET_ALL_SUPPLIERS:
            return {
                ...state,
                suppliers: payload.suppliers,
                pages: payload.pages,
                page: payload.page,
                count: payload.count,
                keyword: payload.keyword,
                supplier: null,
            }
        case SEARCH_SUPPLIERS:
            return {
                ...state,
                keyword: payload.keyword,
                page: 1,
            }
        case CHANGE_SUPPLIERS_PAGE:
            return {
                ...state,
                page: payload,
            }
        case GET_ONE_SUPPLIER:
            return {
                ...state,
                supplier: payload,
            }
        case GET_ONE_SUPPLIER_CONTACT:
            return {
                ...state,
                supplierContact: payload,
            }
        default:
            return state
    }
}
