import { Button, Paper, TextField } from '@mui/material'
import { useDispatch } from 'react-redux'
import React, { useCallback, useState } from 'react'
import { updateOrderItemNote } from '../../store/actions/orderAction'

const OrderItemNoteForm = ({ propValues, handleClose }) => {
    const dispatch = useDispatch()
    const [note, setNote] = useState(propValues.note)
    const handleClick = useCallback(
        (changedNote) => {
            handleClose(false)
            const values = {
                note: changedNote,
                id: propValues.id,
                orderId: propValues.orderId,
            }
            dispatch(updateOrderItemNote(values))
        },
        [dispatch, propValues, handleClose]
    )
    return (
        <div
            style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
            }}
        >
            <Paper>
                <TextField
                    fullWidth
                    id={Math.random().toString()}
                    label="Note"
                    multiline
                    name="note"
                    rows={4}
                    onChange={(e) => setNote(e.target.value)}
                    defaultValue={note}
                />
                <Button
                    style={{ marginTop: '20px' }}
                    variant="outlined"
                    fullWidth
                    onClick={() => handleClick(note)}
                >
                    Submit
                </Button>
            </Paper>
        </div>
    )
}

export default OrderItemNoteForm
