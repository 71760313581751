import request from '../../config/request'
import { errorsReturn } from '../errorsReturn'
import { USER_LOGOIN_SUCCESS, USER_LOGOUT, USER_UPDATE_TOKEN } from '../types'
import { getErrors } from './errorActions'
import { controlLoading } from './loadingActions'

export const login = (values) => async (dispatch) => {
    try {
        dispatch(controlLoading(true))
        const config = {
            headers: {
                'Content-Type': 'application/json',
            },
        }
        const { data } = await request.post('auth/login', values, config)
        dispatch({
            type: USER_LOGOIN_SUCCESS,
            payload: {
                userInfo: data.data.user,
                userToken: data.data.token,
            },
        })
        dispatch(controlLoading(false))
    } catch (e) {
        errorsReturn(e, dispatch, controlLoading, getErrors, logout)
    }
}

export const logout = () => (dispatch) => {
    dispatch({ type: USER_LOGOUT })
}

export const renewUserToken = (values) => (dispatch) => {
    const newTokenValue = values.headers.authorization
    dispatch({
        type: USER_UPDATE_TOKEN,
        payload: newTokenValue,
    })
}
