import {
    CHANGE_PRODUCTS_PAGE,
    CREATE_PRODUCT_CATEGORY,
    GET_ALL_PRODUCTS,
    GET_ONE_PRODUCT,
    GET_PRODUCT_DOOR_COLORS,
    SEARCH_PRODUCTS,
} from '../types'

const initialState = {
    products: null,
    product: null,
    pages: null,
    page: null,
    count: 0,
    keyword: '',
    productCategory: null,
    doorColors: null,
}

const productReducer = (state = initialState, action) => {
    const { type, payload } = action
    switch (type) {
        case GET_ALL_PRODUCTS:
            return {
                ...state,
                products: payload.products,
                pages: payload.pages,
                page: payload.page,
                count: payload.count,
                keyword: payload.keyword,
                product: null,
            }
        case CREATE_PRODUCT_CATEGORY:
            return {
                ...state,
                productCategory: payload,
            }
        case SEARCH_PRODUCTS:
            return {
                ...state,
                keyword: payload.keyword,
                page: 1,
            }
        case CHANGE_PRODUCTS_PAGE:
            return {
                ...state,
                page: payload,
            }
        case GET_ONE_PRODUCT:
            return {
                ...state,
                product: payload,
            }
        case GET_PRODUCT_DOOR_COLORS:
            return {
                ...state,
                doorColors: payload,
            }
        default:
            return state
    }
}

export default productReducer
