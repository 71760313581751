import React from 'react'
import { useLocation } from 'react-router-dom'
import Layout from '../../components/Layout'
import { formTypeList } from '../../constants/form'
import PurchaseOrderForm from '../../components/purchaseOrder/PurchaseOrderForm'

const EditPurchaseOrder = () => {
    const location = useLocation()
    const { purchaseOrder } = location?.state
    return purchaseOrder ? (
        <Layout>
            <PurchaseOrderForm
                formType={formTypeList.EDIT}
                propsValue={purchaseOrder}
            />
        </Layout>
    ) : null
}

export default EditPurchaseOrder
