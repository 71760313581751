import { Component } from 'react'
import { connect } from 'react-redux'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

class Alert extends Component {
    componentDidUpdate(prevProps) {
        const { errors, messages } = this.props
        if (errors !== prevProps.errors) {
            if (errors.msg) toast.error(errors.msg)
        }

        if (messages !== prevProps.messages) {
            if (messages.message) toast.success(messages.message)
        }
    }
    render() {
        return (
            <div>
                <ToastContainer />
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    errors: state.errors,
    messages: state.messages,
})

export default connect(mapStateToProps, null)(Alert)
