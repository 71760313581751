import React from 'react'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Paper from '@mui/material/Paper'
import moment from 'moment'
import { Divider } from '@mui/material'
import { logTypeList, orderTypeList } from '../../constants/orderType'

const LogItem = ({ log }) => (
    <TableRow>
        <TableCell>{moment(log?.createdAt).format('lll')}</TableCell>
        <TableCell>{orderTypeList[parseInt(log.orderType, 10)]}</TableCell>
        <TableCell>{logTypeList[log?.updateType]}</TableCell>
        <TableCell>{log?.updateInfo}</TableCell>
        <TableCell>
            {log?.user?.firstName} {log?.user?.lastName}
        </TableCell>
    </TableRow>
)

const OrderLogs = ({ order }) => {
    return (
        <div style={{ marginTop: '50px' }}>
            {order?.orderlogs?.length > 0 && (
                <>
                    <Divider>Order Logs</Divider>
                    <TableContainer component={Paper}>
                        <Table aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell>Date</TableCell>
                                    <TableCell>Order Status</TableCell>
                                    <TableCell>Update Type</TableCell>
                                    <TableCell>Update Info</TableCell>
                                    <TableCell>User</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {order?.orderlogs?.map((log) => (
                                    <LogItem key={log.id} log={log} />
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </>
            )}
        </div>
    )
}

export default OrderLogs
