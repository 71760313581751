import React, { useCallback, useEffect, useState } from 'react'
import * as Yup from 'yup'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { Button, Container, InputLabel, MenuItem, Select } from '@mui/material'
import Grid from '@mui/material/Grid'
import AppForm from '../form/AppForm'
import AppInput from '../form/AppInput'
import AppSelect from '../form/AppSelect'
import SubmitButton from '../form/SubmitButton'
import Header from '../form/Header'
import { formTypeList } from '../../constants/form'
import { addNewProduct, updateProduct } from '../../store/actions/productAction'
import AppSelectWithId from '../form/AppSelectWithId'
import selectList from '../../constants/selectList.json'
import { sleepTime } from '../../common/sleepTime'
import AppCheckbox from '../form/AppCheckbox'

const validationSchema = Yup.object().shape({
    name: Yup.string().required().label('Name'),
    sku: Yup.string().required().label('Sku'),
    packageLong: Yup.number().required().label('Package Long'),
    packageWidth: Yup.number().required().label('Package Width'),
    packageHeight: Yup.number().required().label('Package Height'),
    volume: Yup.number().required().label('Volume'),
    weight: Yup.number().required().label('Item Weight'),
    weightUnit: Yup.string().required().label('Weight Unit'),
    barcode: Yup.string().label('Barcode'),
    description: Yup.string().label('Description'),
    doorDirection: Yup.boolean().label('Door Direction'),
    productStyleId: Yup.string().required().label('Product Style'),
    assemblyFee: Yup.number().label('Assembly Fee'),
})

const ProductForm = ({ formType, propsValue = null }) => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const { productCategory } = useSelector((state) => state.product)
    const [categoryList, setCategoryList] = useState([])
    const [categoryInitialState, setCategoryInitialState] = useState('')
    const [editStyleValue, setEditStyleValue] = useState('')
    const [styleList, setStyleList] = useState(() =>
        propsValue ? [{ id: propsValue.productStyleId, name: '' }] : []
    )

    useEffect(() => {
        setCategoryList(
            productCategory?.map((category) => {
                return { id: category.id, name: category.name }
            })
        )
        if (propsValue) {
            setCategoryInitialState(
                productCategory.find((category) =>
                    category.productstyles.find(
                        (style) => style.id === propsValue.productStyleId
                    )
                )?.id
            )
            setStyleList(
                productCategory
                    .find(
                        (category) =>
                            category.id ===
                            productCategory.find((category) =>
                                category.productstyles.find(
                                    (style) =>
                                        style.id === propsValue.productStyleId
                                )
                            )?.id
                    )
                    ?.productstyles?.map((style) => {
                        return { id: style.id, name: style.name }
                    })
            )
            setEditStyleValue(propsValue.productStyleId)
        }
    }, [productCategory, propsValue])

    const handleCategoryChange = useCallback(
        async (categoryId) => {
            setCategoryInitialState(categoryId)
            await sleepTime(0.5)
            setStyleList(
                productCategory
                    .find((category) => category.id === categoryId)
                    ?.productstyles?.map((style) => {
                        return { id: style.id, name: style.name }
                    })
            )
        },
        [productCategory]
    )

    return (
        <>
            <Container>
                <Button onClick={() => navigate(-1)}>Back</Button>
                <Header
                    title={`${formType === formTypeList.NEW ? 'ADD' : ''}${
                        formType === formTypeList.EDIT ? 'EDIT' : ''
                    } PRODUCT`}
                />
                <AppForm
                    initialValues={{
                        name: propsValue ? propsValue.name : '',
                        sku: propsValue ? propsValue.sku : '',
                        packageLong: propsValue ? propsValue.packageLong : '',
                        packageWidth: propsValue ? propsValue.packageWidth : '',
                        packageHeight: propsValue
                            ? propsValue.packageHeight
                            : '',
                        volume: propsValue ? propsValue.volume : '',
                        weight: propsValue ? propsValue.weight : '',
                        weightUnit: propsValue
                            ? propsValue.weightUnit
                            : selectList.weightUnit.default,
                        barcode: propsValue ? propsValue.barcode : '',

                        description: propsValue ? propsValue.description : '',
                        doorDirection: propsValue
                            ? propsValue.doorDirection
                            : false,
                        productStyleId: propsValue
                            ? propsValue.productStyleId
                            : '',
                        assemblyFee: propsValue ? propsValue.assemblyFee : 0,
                    }}
                    onSubmit={(values) => {
                        const submitValues =
                            formType === formTypeList.NEW
                                ? values
                                : { ...propsValue, ...values }
                        formType === formTypeList.NEW
                            ? dispatch(addNewProduct(submitValues, navigate))
                            : dispatch(updateProduct(submitValues, navigate))
                    }}
                    validationSchema={validationSchema}
                >
                    <div className="form-input">
                        <InputLabel id={`select-label-category`}>
                            Product Category
                        </InputLabel>
                        <Select
                            labelId={`select-label-category`}
                            fullWidth
                            name="productCategory"
                            id="productCategory"
                            value={categoryInitialState}
                            variant="standard"
                            onChange={(e) =>
                                handleCategoryChange(e.target.value)
                            }
                        >
                            {categoryList?.map((option) => (
                                <MenuItem key={option.id} value={option.id}>
                                    {option.name}
                                </MenuItem>
                            ))}
                        </Select>
                    </div>

                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <AppSelectWithId
                                name="productStyleId"
                                label="Product Style"
                                options={styleList}
                                categoryInitialState={categoryInitialState}
                                editStyleValue={editStyleValue}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <AppInput
                                name="name"
                                label="Product Name"
                                type="text"
                                inputProps={{ maxLength: 100 }}
                                required={true}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <AppInput
                                name="sku"
                                label="SKU"
                                type="text"
                                inputProps={{ maxLength: 50 }}
                                required={true}
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <AppInput
                                name="packageLong"
                                label="Package Long"
                                type="number"
                                inputProps={{ maxLength: 10 }}
                                required={true}
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <AppInput
                                name="packageWidth"
                                label="Package Width"
                                type="number"
                                inputProps={{ maxLength: 10 }}
                                required={true}
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <AppInput
                                name="packageHeight"
                                label="Package Height"
                                type="number"
                                inputProps={{ maxLength: 10 }}
                                required={true}
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <AppInput
                                name="volume"
                                label="Volume"
                                type="number"
                                inputProps={{ maxLength: 10 }}
                                required={true}
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <AppInput
                                name="weight"
                                label="Weight"
                                type="number"
                                inputProps={{ maxLength: 10 }}
                                required={true}
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <AppSelect
                                name="weightUnit"
                                label="Weight Unit"
                                options={selectList.weightUnit.list}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <AppInput
                                name="barcode"
                                label="Barcode"
                                type="text"
                                inputProps={{ maxLength: 100 }}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <AppInput
                                name="description"
                                label="Description"
                                multiline
                                rows={5}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <AppCheckbox
                                name="doorDirection"
                                label="Have Door Direction"
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <AppInput
                                name="assemblyFee"
                                label="Assembly Fee"
                                type="number"
                                inputProps={{ maxLength: 10 }}
                            />
                        </Grid>
                    </Grid>
                    <SubmitButton title="Submit" />
                </AppForm>
            </Container>
        </>
    )
}

export default ProductForm
