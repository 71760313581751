import { Button, TableCell, TableRow } from '@mui/material'
import { green, red } from '@mui/material/colors'
import BlockIcon from '@mui/icons-material/Block'
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline'
import { useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { roleListId, roleType } from '../../config/roleList'
import { checkUserRole } from '../../common/checkUserRole'

const UserList = ({ user }) => {
    const navigate = useNavigate()
    const { userInfo } = useSelector((state) => state.auth)
    const { roleList } = useSelector((state) => state.user)
    return (
        <>
            <TableRow>
                <TableCell>{user.email}</TableCell>
                <TableCell>
                    {user.firstName} {user.lastName}
                </TableCell>
                <TableCell>{user.phone}</TableCell>
                <TableCell align="center">
                    {user.isActive ? (
                        <CheckCircleOutlineIcon style={{ color: green[500] }} />
                    ) : (
                        <BlockIcon style={{ color: red[500] }} />
                    )}
                </TableCell>
                <TableCell align="center">
                    {user.isAdmin ? (
                        <CheckCircleOutlineIcon style={{ color: green[500] }} />
                    ) : null}
                </TableCell>
                <TableCell>
                    <Button
                        size="small"
                        variant="outlined"
                        color="primary"
                        onClick={() =>
                            checkUserRole(
                                userInfo,
                                roleList,
                                roleListId.USER,
                                roleType.EDIT
                            )
                                ? navigate(`/user/${user.id}`)
                                : alert('Not Permit User')
                        }
                    >
                        Info
                    </Button>
                </TableCell>
            </TableRow>
            {/* <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                className={classes.modal}
                open={open}
                onClose={handleClose}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={open}>
                    <div className={classes.paper}>
                        <ModalInfo user={user} handleClose={handleClose} />
                    </div>
                </Fade>
            </Modal> */}
        </>
    )
}

export default UserList
