export default function formatOrderAnalyticsData(data) {
    if (data.length === 0) return data

    const skuQuantities = {}

    const addToSKUCount = (itemSKU, quantity, stock, id, autoOrderQty) => {
        if (skuQuantities[id]) {
            skuQuantities[id].quantity += quantity
        } else {
            skuQuantities[id] = { quantity, stock, itemSKU, autoOrderQty }
        }
    }

    data.forEach((order) => {
        order.orderstyles.forEach((orderstyle) => {
            orderstyle.orderitems.forEach((orderitem) => {
                if (orderitem.isItem && orderitem.item) {
                    addToSKUCount(
                        orderitem.item.itemSKU,
                        orderitem.quantity,
                        orderitem.item.stock,
                        orderitem.item.id,
                        orderitem.item.autoOrderQty
                    )
                } else if (!orderitem.isItem && orderitem.productcolor) {
                    orderitem.productcolor.productitems.forEach(
                        (productitem) => {
                            addToSKUCount(
                                productitem.item.itemSKU,
                                productitem.quantity * orderitem.quantity,
                                productitem.item.stock,
                                productitem.item.id,
                                productitem.item.autoOrderQty
                            )
                        }
                    )
                }
            })
        })

        if (order.orderitemfinishedends) {
            order.orderitemfinishedends.forEach((finishedEnd) => {
                addToSKUCount(
                    finishedEnd.item.itemSKU,
                    finishedEnd.quantity,
                    finishedEnd.item.stock,
                    finishedEnd.item.id,
                    finishedEnd.item.autoOrderQty
                )
            })
        }
    })

    return Object.entries(skuQuantities).map(
        ([id, { quantity, stock, itemSKU, autoOrderQty }]) => ({
            id,
            itemSKU,
            quantity,
            stock,
            autoOrderQty,
        })
    )
}
