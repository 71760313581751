import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

export default function AppModal({
  cancelContent,
  submitContent,
  title,
  subtitle,
  cancelClick,
  submitClick,
  danger,
  open,
  handleClose,
}) {
  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description">
      <Box sx={style}>
        <Typography id="modal-modal-title" variant="h6" component="h2">
          {title}
        </Typography>
        {subtitle && (
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            {subtitle}
          </Typography>
        )}
        <div
          style={{
            width: '100%',
            marginTop: '20px',
            display: 'flex',
            justifyContent: 'space-evenly',
          }}>
          <Button onClick={cancelClick} variant="contained" color="secondary">
            {cancelContent}
          </Button>
          <Button
            onClick={submitClick}
            variant="contained"
            color={danger ? 'error' : 'success'}>
            {submitContent}
          </Button>
        </div>
      </Box>
    </Modal>
  );
}
