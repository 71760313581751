import { UPDATE_MODE, UPDATE_SETTING_OPEN, UPDATE_USER_OPEN } from '../types'

export const modeAction = (mode) => async (dispatch) => {
    const value = mode === 'light' ? 'dark' : 'light'
    dispatch({
        type: UPDATE_MODE,
        payload: value,
    })
}

export const userOpenAction = (status) => async (dispatch) => {
    dispatch({
        type: UPDATE_USER_OPEN,
        payload: !status,
    })
}

export const settingOpenAction = (status) => async (dispatch) => {
    dispatch({
        type: UPDATE_SETTING_OPEN,
        payload: !status,
    })
}
