import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { formTypeList } from '../../constants/form'
import { GET_ONE_PRODUCT_COLOR } from '../../store/types'
import ProductColorForm from './ProductColorForm'
import { getOneProductColor } from '../../store/actions/productColorAction'

const Edit = ({ id, setOpen }) => {
    const dispatch = useDispatch()
    const { productColor } = useSelector((state) => state.productColor)
    useEffect(() => {
        dispatch(getOneProductColor(id))
        return () => {
            dispatch({
                type: GET_ONE_PRODUCT_COLOR,
                payload: null,
            })
        }
    }, [dispatch, id])
    return productColor ? (
        <ProductColorForm
            formType={formTypeList.EDIT}
            setOpen={setOpen}
            propsValue={productColor}
        />
    ) : null
}

export default Edit
