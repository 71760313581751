import { Group, Home, ModeNight, Settings } from '@mui/icons-material'
import {
    Box,
    List,
    ListItem,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    Switch,
    Collapse,
    Badge,
} from '@mui/material'
import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'
import ExpandLess from '@mui/icons-material/ExpandLess'
import ExpandMore from '@mui/icons-material/ExpandMore'
import ManageSearchIcon from '@mui/icons-material/ManageSearch'
import InventoryIcon from '@mui/icons-material/Inventory'
import { useDispatch, useSelector } from 'react-redux'
import RecentActorsIcon from '@mui/icons-material/RecentActors'
import LocalMallIcon from '@mui/icons-material/LocalMall'
import GroupAddIcon from '@mui/icons-material/GroupAdd'
import ListAltIcon from '@mui/icons-material/ListAlt'
import SensorDoorIcon from '@mui/icons-material/SensorDoor'
import WarehouseIcon from '@mui/icons-material/Warehouse'
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart'
import DirectionsBoatFilledIcon from '@mui/icons-material/DirectionsBoatFilled'
import InvertColorsIcon from '@mui/icons-material/InvertColors'
import CategoryIcon from '@mui/icons-material/Category'
import SmsFailedIcon from '@mui/icons-material/SmsFailed'
import PaidIcon from '@mui/icons-material/Paid'
import LocalShippingIcon from '@mui/icons-material/LocalShipping'
import RequestQuoteIcon from '@mui/icons-material/RequestQuote'
import QueryStatsIcon from '@mui/icons-material/QueryStats'
import BarChartIcon from '@mui/icons-material/BarChart'
import { settingOpenAction, userOpenAction } from '../store/actions/modeAction'
import { getRoleList } from '../store/actions/usersAction'
import FactoryIcon from '@mui/icons-material/Factory'
import EventNoteIcon from '@mui/icons-material/EventNote'
import { roleListId, roleType } from '../config/roleList'
import { checkUserRole } from '../common/checkUserRole'
import { getNewOrderCount } from '../store/actions/orderAction'

const Sidebar = ({ mode, setMode, userInfo }) => {
    const dispatch = useDispatch()
    useEffect(() => {
        dispatch(getRoleList())
        dispatch(getNewOrderCount())
    }, [dispatch])
    const { userOpen, settingOpen } = useSelector((state) => state.mode)
    const userState = useSelector((state) => state.user)
    const { newOrderCount } = useSelector((state) => state.order)
    const { roleList } = userState
    return (
        <Box flex={1} p={2} sx={{ display: { xs: 'none', sm: 'block' } }}>
            <Box
                position="fixed"
                maxHeight="100vh"
                overflow="auto"
                style={{ paddingBottom: '100px' }}
            >
                <List>
                    <ListItem disablePadding>
                        <ListItemButton component={Link} to="/">
                            <ListItemIcon>
                                <Home />
                            </ListItemIcon>
                            <ListItemText primary="Homepage" />
                        </ListItemButton>
                    </ListItem>
                    {/* <ListItem disablePadding>
                        <ListItemButton component={Link} to="/roles">
                            <ListItemIcon>
                                <Article />
                            </ListItemIcon>
                            <ListItemText primary="Roles" />
                        </ListItemButton>
                    </ListItem> */}
                    <ListItemButton
                        onClick={() => dispatch(userOpenAction(userOpen))}
                    >
                        <ListItemIcon>
                            <Group />
                        </ListItemIcon>
                        <ListItemText primary="Users" />
                        {userOpen ? <ExpandLess /> : <ExpandMore />}
                    </ListItemButton>
                    <Collapse in={userOpen} timeout="auto" unmountOnExit>
                        <List component="div" disablePadding>
                            {checkUserRole(
                                userInfo,
                                roleList,
                                roleListId.USER,
                                roleType.READ
                            ) && (
                                <ListItemButton
                                    sx={{ pl: 4 }}
                                    component={Link}
                                    to="/users"
                                >
                                    <ListItemIcon>
                                        <RecentActorsIcon />
                                    </ListItemIcon>
                                    <ListItemText primary="All Users" />
                                </ListItemButton>
                            )}
                            {checkUserRole(
                                userInfo,
                                roleList,
                                roleListId.USER,
                                roleType.CREATE
                            ) && (
                                <ListItemButton
                                    sx={{ pl: 4 }}
                                    component={Link}
                                    to="/addnewuser"
                                >
                                    <ListItemIcon>
                                        <GroupAddIcon />
                                    </ListItemIcon>
                                    <ListItemText primary="Add New User" />
                                </ListItemButton>
                            )}
                        </List>
                    </Collapse>
                    {checkUserRole(
                        userInfo,
                        roleList,
                        roleListId.CUSTOMER,
                        roleType.READ
                    ) && (
                        <ListItem disablePadding>
                            <ListItemButton component={Link} to="/customers">
                                <ListItemIcon>
                                    <LocalMallIcon />
                                </ListItemIcon>
                                <ListItemText primary="Dealers" />
                            </ListItemButton>
                        </ListItem>
                    )}
                    {checkUserRole(
                        userInfo,
                        roleList,
                        roleListId.ORDER,
                        roleType.READ
                    ) && (
                        <ListItem disablePadding>
                            <ListItemButton component={Link} to="/orders">
                                <ListItemIcon>
                                    <Badge
                                        badgeContent={newOrderCount || 0}
                                        color="error"
                                    >
                                        <ListAltIcon />
                                    </Badge>
                                </ListItemIcon>
                                <ListItemText primary="Orders" />
                            </ListItemButton>
                        </ListItem>
                    )}
                    {checkUserRole(
                        userInfo,
                        roleList,
                        roleListId.ORDER_PAYMENT,
                        roleType.READ
                    ) && (
                        <ListItem disablePadding>
                            <ListItemButton
                                component={Link}
                                to="/orderpayments"
                            >
                                <ListItemIcon>
                                    <PaidIcon />
                                </ListItemIcon>
                                <ListItemText primary="Order Payments" />
                            </ListItemButton>
                        </ListItem>
                    )}
                    {checkUserRole(
                        userInfo,
                        roleList,
                        roleListId.ORDER_SHIPMENT,
                        roleType.READ
                    ) && (
                        <ListItem disablePadding>
                            <ListItemButton
                                component={Link}
                                to="/ordershipments"
                            >
                                <ListItemIcon>
                                    <LocalShippingIcon />
                                </ListItemIcon>
                                <ListItemText primary="Order Shipments" />
                            </ListItemButton>
                        </ListItem>
                    )}
                    {checkUserRole(
                        userInfo,
                        roleList,
                        roleListId.SUPPLIER,
                        roleType.READ
                    ) && (
                        <ListItem disablePadding>
                            <ListItemButton component={Link} to="/suppliers">
                                <ListItemIcon>
                                    <FactoryIcon />
                                </ListItemIcon>
                                <ListItemText primary="Supplier" />
                            </ListItemButton>
                        </ListItem>
                    )}
                    {checkUserRole(
                        userInfo,
                        roleList,
                        roleListId.PURCHASE_ORDER,
                        roleType.READ
                    ) && (
                        <ListItem disablePadding>
                            <ListItemButton
                                component={Link}
                                to="/purchaseorders"
                            >
                                <ListItemIcon>
                                    <ShoppingCartIcon />
                                </ListItemIcon>
                                <ListItemText primary="Purchase Order" />
                            </ListItemButton>
                        </ListItem>
                    )}
                    {checkUserRole(
                        userInfo,
                        roleList,
                        roleListId.SHIPMENT,
                        roleType.READ
                    ) && (
                        <ListItem disablePadding>
                            <ListItemButton component={Link} to="/shipments">
                                <ListItemIcon>
                                    <DirectionsBoatFilledIcon />
                                </ListItemIcon>
                                <ListItemText primary="Shipment" />
                            </ListItemButton>
                        </ListItem>
                    )}
                    {checkUserRole(
                        userInfo,
                        roleList,
                        roleListId.ITEM,
                        roleType.READ
                    ) && (
                        <ListItem disablePadding>
                            <ListItemButton component={Link} to="/items">
                                <ListItemIcon>
                                    <EventNoteIcon />
                                </ListItemIcon>
                                <ListItemText primary="Item" />
                            </ListItemButton>
                        </ListItem>
                    )}
                    {checkUserRole(
                        userInfo,
                        roleList,
                        roleListId.ITEM_ANALYTICS,
                        roleType.READ
                    ) && (
                        <ListItem disablePadding>
                            <ListItemButton
                                component={Link}
                                to="/itemanalytics"
                            >
                                <ListItemIcon>
                                    <BarChartIcon />
                                </ListItemIcon>
                                <ListItemText primary="Item Analytics" />
                            </ListItemButton>
                        </ListItem>
                    )}
                    {checkUserRole(
                        userInfo,
                        roleList,
                        roleListId.ITEM_ANALYTICS,
                        roleType.READ
                    ) && (
                        <ListItem disablePadding>
                            <ListItemButton
                                component={Link}
                                to="/orderanalytics"
                            >
                                <ListItemIcon>
                                    <QueryStatsIcon />
                                </ListItemIcon>
                                <ListItemText primary="Order Analytics" />
                            </ListItemButton>
                        </ListItem>
                    )}
                    {checkUserRole(
                        userInfo,
                        roleList,
                        roleListId.DEFECTIVE_ITEM,
                        roleType.READ
                    ) && (
                        <ListItem disablePadding>
                            <ListItemButton
                                component={Link}
                                to="/defectiveitems"
                            >
                                <ListItemIcon>
                                    <SmsFailedIcon />
                                </ListItemIcon>
                                <ListItemText primary="Defective Item" />
                            </ListItemButton>
                        </ListItem>
                    )}
                    {checkUserRole(
                        userInfo,
                        roleList,
                        roleListId.PRODUCT,
                        roleType.READ
                    ) && (
                        <ListItem disablePadding>
                            <ListItemButton component={Link} to="/products">
                                <ListItemIcon>
                                    <InventoryIcon />
                                </ListItemIcon>
                                <ListItemText primary="Product" />
                            </ListItemButton>
                        </ListItem>
                    )}
                    {checkUserRole(
                        userInfo,
                        roleList,
                        roleListId.PRODUCT_COLOR,
                        roleType.READ
                    ) && (
                        <ListItem disablePadding>
                            <ListItemButton
                                component={Link}
                                to="/productcolors"
                            >
                                <ListItemIcon>
                                    <InvertColorsIcon />
                                </ListItemIcon>
                                <ListItemText primary="Product Colors" />
                            </ListItemButton>
                        </ListItem>
                    )}
                    {checkUserRole(
                        userInfo,
                        roleList,
                        roleListId.LOG,
                        roleType.READ
                    ) && (
                        <ListItem disablePadding>
                            <ListItemButton component={Link} to="/logs">
                                <ListItemIcon>
                                    <ManageSearchIcon />
                                </ListItemIcon>
                                <ListItemText primary="Logs" />
                            </ListItemButton>
                        </ListItem>
                    )}
                    {checkUserRole(
                        userInfo,
                        roleList,
                        roleListId.WAREHOUSE,
                        roleType.READ
                    ) && (
                        <ListItem disablePadding>
                            <ListItemButton component={Link} to="/warehouses">
                                <ListItemIcon>
                                    <WarehouseIcon />
                                </ListItemIcon>
                                <ListItemText primary="Warehouses" />
                            </ListItemButton>
                        </ListItem>
                    )}

                    <ListItemButton
                        onClick={() => dispatch(settingOpenAction(settingOpen))}
                    >
                        <ListItemIcon>
                            <Settings />
                        </ListItemIcon>
                        <ListItemText primary="Settings" />
                        {settingOpen ? <ExpandLess /> : <ExpandMore />}
                    </ListItemButton>
                    <Collapse in={settingOpen} timeout="auto" unmountOnExit>
                        <List component="div" disablePadding>
                            <ListItemButton
                                sx={{ pl: 4 }}
                                component={Link}
                                to="/productcategory"
                            >
                                <ListItemIcon>
                                    <CategoryIcon />
                                </ListItemIcon>
                                <ListItemText primary="Product Category" />
                            </ListItemButton>
                            <ListItemButton
                                sx={{ pl: 4 }}
                                component={Link}
                                to="/doorcategory"
                            >
                                <ListItemIcon>
                                    <SensorDoorIcon />
                                </ListItemIcon>
                                <ListItemText primary="Door Category" />
                            </ListItemButton>
                            <ListItemButton
                                sx={{ pl: 4 }}
                                component={Link}
                                to="/basetaxes"
                            >
                                <ListItemIcon>
                                    <RequestQuoteIcon />
                                </ListItemIcon>
                                <ListItemText primary="Base Tax" />
                            </ListItemButton>

                            {checkUserRole(
                                userInfo,
                                roleList,
                                roleListId.ITEM,
                                roleType.APPROVAL
                            ) && (
                                <ListItemButton
                                    sx={{ pl: 4 }}
                                    component={Link}
                                    to="/itemrecords"
                                >
                                    <ListItemIcon>
                                        <RequestQuoteIcon />
                                    </ListItemIcon>
                                    <ListItemText primary="Item Change Price" />
                                </ListItemButton>
                            )}
                            {/* <ListItemButton
                                sx={{ pl: 4 }}
                                component={Link}
                                to="/addnewuser"
                            >
                                <ListItemIcon>
                                    <GroupAddIcon />
                                </ListItemIcon>
                                <ListItemText primary="Add New User" />
                            </ListItemButton> */}
                        </List>
                    </Collapse>

                    <ListItem disablePadding>
                        <ListItemButton>
                            <ListItemIcon>
                                <ModeNight />
                            </ListItemIcon>
                            <Switch
                                checked={mode === 'dark'}
                                value={mode}
                                onClick={() =>
                                    setMode(mode === 'light' ? 'dark' : 'light')
                                }
                            />
                        </ListItemButton>
                    </ListItem>
                </List>
            </Box>
        </Box>
    )
}

export default Sidebar
