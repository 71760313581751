import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { getOneSupplier } from '../../store/actions/supplierAction'
import Layout from '../../components/Layout'
import SupplierForm from '../../components/supplier/SupplierForm'

const EditSupplier = () => {
    const { id, formTypeStatus } = useParams()
    const dispatch = useDispatch()
    const { supplier } = useSelector((state) => state.supplier)
    useEffect(() => {
        dispatch(getOneSupplier(id))
    }, [dispatch, id])
    return supplier ? (
        <Layout>
            <SupplierForm formType={formTypeStatus} propsValue={supplier} />
        </Layout>
    ) : null
}

export default EditSupplier
