import { Button, TableCell, TableRow } from '@mui/material'
import { green, red } from '@mui/material/colors'
import BlockIcon from '@mui/icons-material/Block'
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline'
import { useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { roleListId, roleType } from '../../config/roleList'
import { checkUserRole } from '../../common/checkUserRole'

const CustomerList = ({ customer }) => {
    const navigate = useNavigate()
    const { userInfo } = useSelector((state) => state.auth)
    const { roleList } = useSelector((state) => state.user)
    return (
        <>
            <TableRow>
                <TableCell>{customer.company}</TableCell>
                <TableCell>
                    {customer.firstName} {customer.lastName}
                </TableCell>
                <TableCell>{customer.phone}</TableCell>
                <TableCell>{customer.email}</TableCell>
                <TableCell>{customer.webRole}</TableCell>
                <TableCell>{customer.defaultDiscountPercentage}%</TableCell>
                <TableCell>{customer.orderCount}</TableCell>
                <TableCell align="center">
                    {customer.verify ? (
                        <CheckCircleOutlineIcon style={{ color: green[500] }} />
                    ) : (
                        <BlockIcon style={{ color: red[500] }} />
                    )}
                </TableCell>
                <TableCell align="center">
                    {customer.isActive ? (
                        <CheckCircleOutlineIcon style={{ color: green[500] }} />
                    ) : (
                        <BlockIcon style={{ color: red[500] }} />
                    )}
                </TableCell>
                <TableCell>
                    <Button
                        size="small"
                        variant="outlined"
                        color="primary"
                        onClick={() =>
                            checkUserRole(
                                userInfo,
                                roleList,
                                roleListId.CUSTOMER,
                                roleType.READ
                            )
                                ? navigate(`/customer/${customer.id}`)
                                : alert('Not Permit User')
                        }
                    >
                        Info
                    </Button>
                </TableCell>
            </TableRow>
        </>
    )
}

export default CustomerList
