import React from 'react';

function ImgComponent({ picLink, width = '36px', height = '60px' }) {
  return (
    <img
      src={
        picLink ||
        'https://res.cloudinary.com/home-space/image/upload/v1633013512/Products/image-not-available_tnweud.png'
      }
      alt="item pic"
      style={{ height, width }}
      className="order-pic"
      onError={event => {
        const { target } = event;
        target.src =
          'https://res.cloudinary.com/home-space/image/upload/v1633013512/Products/image-not-available_tnweud.png';
      }}
    />
  );
}

export default ImgComponent;
