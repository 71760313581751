import { Button, TableCell, TableRow } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { roleListId, roleType } from '../../config/roleList'
import { checkUserRole } from '../../common/checkUserRole'
import { useCallback, useMemo } from 'react'
import { DELETE_CONFIRM } from '../../constants/form'
import { deletePurchaseOrder } from '../../store/actions/purchaseOrderAction'

const PurchaseOrderList = ({ purchaseOrder }) => {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const { userInfo } = useSelector((state) => state.auth)
    const { roleList } = useSelector((state) => state.user)

    const handleDelete = useCallback(
        (id) => {
            if (window.confirm(DELETE_CONFIRM)) {
                dispatch(deletePurchaseOrder(id))
            }
        },
        [dispatch]
    )

    const orderItemQty = useMemo(() => {
        return (
            purchaseOrder?.purchaseorderitems?.reduce(
                (sum, item) => sum + item.quantity,
                0
            ) || 0
        )
    }, [purchaseOrder])

    // const shippedItemQty = useMemo(() => {
    //     return (
    //         purchaseOrder?.shipments?.reduce((sum, item) => {
    //             const shippedTotal =
    //                 item?.shipmentorderitems?.reduce(
    //                     (total, shipment) => total + shipment.quantity,
    //                     0
    //                 ) || 0
    //             return sum + shippedTotal
    //         }, 0) || 0
    //     )
    // }, [purchaseOrder])

    // const receivedItemQty = useMemo(() => {
    //     return (
    //         purchaseOrder?.shipments?.reduce((sum, item) => {
    //             const shippedTotal =
    //                 item?.shipmentorderitems?.reduce(
    //                     (total, shipment) =>
    //                         shipment.confirm ? total + shipment.stock : total,
    //                     0
    //                 ) || 0
    //             return sum + shippedTotal
    //         }, 0) || 0
    //     )
    // }, [purchaseOrder])

    return (
        <>
            <TableRow>
                <TableCell>{purchaseOrder?.supplier?.name}</TableCell>
                <TableCell>{purchaseOrder.orderId}</TableCell>
                <TableCell>{purchaseOrder.invoiceNo}</TableCell>
                <TableCell>{purchaseOrder.po}</TableCell>
                <TableCell>{orderItemQty}</TableCell>
                {/* <TableCell>{shippedItemQty}</TableCell>
                <TableCell>{receivedItemQty}</TableCell> */}
                <TableCell>
                    <div className="button-box">
                        <Button
                            size="small"
                            variant="outlined"
                            color="primary"
                            onClick={() =>
                                checkUserRole(
                                    userInfo,
                                    roleList,
                                    roleListId.PURCHASE_ORDER,
                                    roleType.READ
                                )
                                    ? navigate(
                                          `/purchaseorder/${purchaseOrder.id}`
                                      )
                                    : alert('Not Permit User')
                            }
                        >
                            Info
                        </Button>
                        <Button
                            size="small"
                            variant="outlined"
                            color="secondary"
                            onClick={() =>
                                checkUserRole(
                                    userInfo,
                                    roleList,
                                    roleListId.PURCHASE_ORDER,
                                    roleType.EDIT
                                )
                                    ? navigate(`/editpurchaseorder`, {
                                          state: {
                                              purchaseOrder,
                                          },
                                      })
                                    : alert('Not Permit User')
                            }
                        >
                            Edit
                        </Button>
                        <Button
                            size="small"
                            variant="outlined"
                            color="error"
                            onClick={() =>
                                checkUserRole(
                                    userInfo,
                                    roleList,
                                    roleListId.PURCHASE_ORDER,
                                    roleType.DELETE
                                ) && purchaseOrder?.shipments?.length === 0
                                    ? handleDelete(purchaseOrder.id)
                                    : alert('Not Permit User')
                            }
                        >
                            Delete
                        </Button>
                    </div>
                </TableCell>
            </TableRow>
        </>
    )
}

export default PurchaseOrderList
