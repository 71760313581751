import React from 'react'
import { useDispatch } from 'react-redux'
import * as Yup from 'yup'
import { priceChangeType } from '../../constants/form'
import {
    updateOrderFinishedEndPrice,
    updateOrderItemAssemblyFee,
    updateOrderItemPrice,
} from '../../store/actions/orderAction'
import SubmitButton from '../form/SubmitButton'
import AppInput from '../form/AppInput'
import AppForm from '../form/AppForm'

const validationSchema = Yup.object().shape({
    price: Yup.number().required().label('New Price'),
})

const ChangePriceForm = ({ type, propValues, handleClose }) => {
    const dispatch = useDispatch()

    return (
        <div>
            <AppForm
                initialValues={{
                    price: '',
                }}
                onSubmit={(values) => {
                    const submitValues = { ...values, ...propValues }
                    switch (type) {
                        case priceChangeType.ITEM_PRICE:
                            dispatch(updateOrderItemPrice(submitValues))
                            break
                        case priceChangeType.FINISHED_END_PRICE:
                            dispatch(updateOrderFinishedEndPrice(submitValues))
                            break
                        case priceChangeType.ASSEMBLY_FEE:
                            dispatch(updateOrderItemAssemblyFee(submitValues))
                            break
                        default:
                            console.log('updated')
                    }
                    handleClose()
                }}
                validationSchema={validationSchema}
            >
                <AppInput
                    name="price"
                    label="New Price"
                    type="number"
                    inputProps={{
                        maxLength: 10,
                    }}
                    required={true}
                />
                <SubmitButton title="Submit" />
            </AppForm>
        </div>
    )
}

export default ChangePriceForm
