import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Layout from '../components/Layout'
import { Button, Container } from '@mui/material'
import { roleListId, roleType } from '../config/roleList'
import { checkUserRole } from '../common/checkUserRole'
import BasicModal from '../components/modal/BasicModal'
import WarehouseItem from '../components/warehouse/WarehouseItem'
import { getAllWarehouses } from '../store/actions/warehouseAction'
import AddNewWarehouse from '../components/warehouse/AddNewWarehouse'

const Warehouses = () => {
    const dispatch = useDispatch()
    const { warehouses } = useSelector((state) => state.warehouse)
    const { userInfo } = useSelector((state) => state.auth)
    const { roleList } = useSelector((state) => state.user)
    const [open, setOpen] = useState(false)

    useEffect(() => {
        dispatch(getAllWarehouses())
    }, [dispatch])

    return (
        <Layout>
            <Container>
                <BasicModal open={open} setOpen={setOpen}>
                    <AddNewWarehouse setOpen={setOpen} />
                </BasicModal>
            </Container>
            <Button
                color="info"
                variant="outlined"
                style={{ marginBottom: '20px' }}
                onClick={() =>
                    checkUserRole(
                        userInfo,
                        roleList,
                        roleListId.WAREHOUSE,
                        roleType.CREATE
                    )
                        ? setOpen(true)
                        : alert('Not Permit User')
                }
            >
                Add New Door Category
            </Button>
            <div style={{ padding: '20px' }} />
            {warehouses?.map((warehouse) => (
                <WarehouseItem key={warehouse.id} warehouse={warehouse} />
            ))}
        </Layout>
    )
}

export default Warehouses
