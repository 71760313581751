import request from '../../config/request'
import { errorsReturn } from '../errorsReturn'
import {
    GET_ALL_CUSTOMERS,
    GET_ONE_CUSTOMER,
    GET_USER_SELECT_LIST,
} from '../types'
import { logout, renewUserToken } from './authAction'
import { getErrors } from './errorActions'
import { controlLoading } from './loadingActions'

export const getAllCustomers =
    (keyword = '', pageNumber = '') =>
    async (dispatch, getState) => {
        if (pageNumber) {
            try {
                dispatch(controlLoading(true))
                const {
                    auth: { userToken },
                } = getState()
                let res = await request.get(
                    `customer/listall?keyword=${keyword}&pageNumber=${pageNumber}`,
                    {
                        headers: { Authorization: `Bearer ${userToken}` },
                    }
                )
                dispatch(renewUserToken(res))
                dispatch({
                    type: GET_ALL_CUSTOMERS,
                    payload: {
                        customers: res.data.data,
                        pages: res.data.pages,
                        page: res.data.page,
                        count: res.data.count,
                        keyword: keyword,
                    },
                })
                dispatch(controlLoading(false))
            } catch (e) {
                errorsReturn(e, dispatch, controlLoading, getErrors, logout)
            }
        }
    }

export const getOneCustomer = (id) => async (dispatch, getState) => {
    try {
        dispatch(controlLoading(true))
        const {
            auth: { userToken },
        } = getState()

        let res = await request.get(`customer/listone/${id}`, {
            headers: { Authorization: `Bearer ${userToken}` },
        })
        dispatch(renewUserToken(res))
        dispatch({ type: GET_ONE_CUSTOMER, payload: res.data.data })
        dispatch(controlLoading(false))
    } catch (e) {
        errorsReturn(e, dispatch, controlLoading, getErrors, logout)
    }
}

export const getAllUserList = () => async (dispatch, getState) => {
    try {
        dispatch(controlLoading(true))
        const {
            auth: { userToken },
        } = getState()

        let res = await request.get(`customer/userlist`, {
            headers: { Authorization: `Bearer ${userToken}` },
        })
        dispatch(renewUserToken(res))
        dispatch({ type: GET_USER_SELECT_LIST, payload: res.data.data })
        dispatch(controlLoading(false))
    } catch (e) {
        errorsReturn(e, dispatch, controlLoading, getErrors, logout)
    }
}

export const updateCustomer =
    (values, navigate) => async (dispatch, getState) => {
        try {
            dispatch(controlLoading(true))
            const {
                auth: { userToken },
            } = getState()
            let res = await request.put(`customer/edit/${values.id}`, values, {
                headers: { Authorization: `Bearer ${userToken}` },
            })
            dispatch(renewUserToken(res))
            dispatch(controlLoading(false))
            dispatch(getAllCustomers())
            navigate('/customers')
        } catch (e) {
            errorsReturn(e, dispatch, controlLoading, getErrors, logout)
        }
    }
