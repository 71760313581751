import request from '../../config/request'
import { errorsReturn } from '../errorsReturn'
import { GET_ONE_PRODUCT_STYLE } from '../types'
import { logout, renewUserToken } from './authAction'
import { getErrors } from './errorActions'
import { controlLoading } from './loadingActions'
import { getAllProductCategory } from './productCategoryAction'

export const getOneProductStyle = (id) => async (dispatch, getState) => {
    try {
        dispatch(controlLoading(true))
        const {
            auth: { userToken },
        } = getState()
        let res = await request.get(`productstyle/listone/${id}`, {
            headers: { Authorization: `Bearer ${userToken}` },
        })
        dispatch(renewUserToken(res))
        dispatch({
            type: GET_ONE_PRODUCT_STYLE,
            payload: res.data.data,
        })
        dispatch(controlLoading(false))
    } catch (e) {
        errorsReturn(e, dispatch, controlLoading, getErrors, logout)
    }
}

export const addNewProdcutStyle = (values) => async (dispatch, getState) => {
    try {
        dispatch(controlLoading(true))
        const {
            auth: { userToken },
        } = getState()
        let res = await request.post(`productstyle/create`, values, {
            headers: { Authorization: `Bearer ${userToken}` },
        })
        dispatch(renewUserToken(res))
        dispatch(controlLoading(false))
        dispatch(getAllProductCategory())
    } catch (e) {
        errorsReturn(e, dispatch, controlLoading, getErrors, logout)
    }
}

export const updateProductStyle = (values) => async (dispatch, getState) => {
    try {
        dispatch(controlLoading(true))
        const {
            auth: { userToken },
        } = getState()
        let res = await request.put(
            `productstyle/update/${values.id}`,
            values,
            {
                headers: { Authorization: `Bearer ${userToken}` },
            }
        )
        dispatch(renewUserToken(res))
        dispatch(controlLoading(false))
        dispatch(getAllProductCategory())
    } catch (e) {
        errorsReturn(e, dispatch, controlLoading, getErrors, logout)
    }
}

export const deleteProductStyle = (id) => async (dispatch, getState) => {
    try {
        dispatch(controlLoading(true))
        const {
            auth: { userToken },
        } = getState()
        let res = await request.delete(`productstyle/delete/${id}`, {
            headers: { Authorization: `Bearer ${userToken}` },
        })
        dispatch(renewUserToken(res))
        dispatch(controlLoading(false))
        dispatch(getAllProductCategory())
    } catch (e) {
        errorsReturn(e, dispatch, controlLoading, getErrors, logout)
    }
}
