import React, { useState } from 'react'
import TableContainer from '@mui/material/TableContainer'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableHead from '@mui/material/TableHead'
import { Button, TableCell, TableRow } from '@mui/material'
import Paper from '@mui/material/Paper'
import Modal from '@mui/material/Modal'
import SupplierContactNew from './SupplierContactNew'
import CancelPresentationIcon from '@mui/icons-material/CancelPresentation'
import SupplierContactEdit from './SupplierContactEdit'
import { useColorScheme } from '@mui/material/styles'
import { useDispatch, useSelector } from 'react-redux'
import { deleteSupplierContact } from '../../store/actions/supplierAction'
import { roleListId, roleType } from '../../config/roleList'
import { checkUserRole } from '../../common/checkUserRole'

const SupplierContact = ({ contact, supplierId }) => {
    const dispatch = useDispatch()
    const { userInfo } = useSelector((state) => state.auth)
    const { roleList } = useSelector((state) => state.user)
    const { mode } = useColorScheme()
    const [open, setOpen] = useState(false)
    const handleOpen = () => setOpen(true)
    const handleClose = () => setOpen(false)
    return (
        <>
            <TableRow>
                <TableCell>{contact.name}</TableCell>
                <TableCell>{contact.email}</TableCell>
                <TableCell>{contact.phone}</TableCell>
                <TableCell>{contact.position}</TableCell>
                <TableCell>{contact.currentStatus}</TableCell>
                <TableCell>
                    <div className="button-box">
                        {checkUserRole(
                            userInfo,
                            roleList,
                            roleListId.SUPPLIER_CONTACT,
                            roleType.EDIT
                        ) ? (
                            <Button
                                size="small"
                                variant="outlined"
                                color="secondary"
                                onClick={handleOpen}
                            >
                                Edit
                            </Button>
                        ) : null}
                        {checkUserRole(
                            userInfo,
                            roleList,
                            roleListId.SUPPLIER_CONTACT,
                            roleType.DELETE
                        ) ? (
                            <Button
                                size="small"
                                variant="outlined"
                                color="error"
                                onClick={() => {
                                    if (window.confirm('are you sure?')) {
                                        dispatch(
                                            deleteSupplierContact(
                                                contact.id,
                                                supplierId
                                            )
                                        )
                                    }
                                }}
                            >
                                Delete
                            </Button>
                        ) : null}
                    </div>
                </TableCell>
            </TableRow>
            <Modal
                open={open}
                // onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <div className="modal-container">
                    <div
                        className={`modal-content ${
                            mode === 'light'
                                ? 'modal-content-light'
                                : 'modal-content-dark'
                        }`}
                    >
                        <div style={{ textAlign: 'right' }}>
                            <CancelPresentationIcon
                                onClick={handleClose}
                                style={{ cursor: 'pointer' }}
                            />
                        </div>
                        <SupplierContactEdit
                            id={contact.id}
                            handleClose={handleClose}
                            supplierId={supplierId}
                        />
                    </div>
                </div>
            </Modal>
        </>
    )
}

const SupplierCotactList = ({ suppliercontacts, supplierId }) => {
    const { userInfo } = useSelector((state) => state.auth)
    const { roleList } = useSelector((state) => state.user)
    const { mode } = useColorScheme()
    const [open, setOpen] = useState(false)
    const handleOpen = () => setOpen(true)
    const handleClose = () => setOpen(false)
    return (
        <>
            <h3
                style={{
                    marginTop: '10px',
                    marginBottom: '10px',
                    width: '100%',
                    textAlign: 'center',
                }}
            >
                Supplier Contacts
            </h3>
            {checkUserRole(
                userInfo,
                roleList,
                roleListId.SUPPLIER_CONTACT,
                roleType.CREATE
            ) ? (
                <div style={{ marginTop: '10px', marginBottom: '10px' }}>
                    <Button
                        color="info"
                        variant="outlined"
                        onClick={handleOpen}
                    >
                        Add New Contact
                    </Button>
                </div>
            ) : null}
            <Modal
                open={open}
                // onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <div className="modal-container">
                    <div
                        className={`modal-content ${
                            mode === 'light'
                                ? 'modal-content-light'
                                : 'modal-content-dark'
                        }`}
                    >
                        <div style={{ textAlign: 'right' }}>
                            <CancelPresentationIcon
                                onClick={handleClose}
                                style={{ cursor: 'pointer' }}
                            />
                        </div>
                        <SupplierContactNew
                            supplierId={supplierId}
                            handleClose={handleClose}
                        />
                    </div>
                </div>
            </Modal>
            <TableContainer component={Paper}>
                <Table aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell>Name</TableCell>
                            <TableCell>Email</TableCell>
                            <TableCell>Phone</TableCell>
                            <TableCell>Position</TableCell>
                            <TableCell>Status</TableCell>
                            <TableCell style={{ width: '50px' }}></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {suppliercontacts?.map((contact) => (
                            <SupplierContact
                                contact={contact}
                                key={contact.id}
                                supplierId={supplierId}
                            />
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </>
    )
}

export default SupplierCotactList
