import React from 'react'
import Layout from '../../components/Layout'
import { formTypeList } from '../../constants/form'
import PurchaseOrderForm from '../../components/purchaseOrder/PurchaseOrderForm'

const AddPurchaseOrder = () => {
    return (
        <Layout>
            <PurchaseOrderForm formType={formTypeList.NEW} />
        </Layout>
    )
}

export default AddPurchaseOrder
