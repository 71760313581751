import * as React from 'react'
import Box from '@mui/material/Box'
import Modal from '@mui/material/Modal'
import { useColorScheme } from '@mui/material/styles'
import CloseIcon from '@mui/icons-material/Close'

export default function BasicModal({ open, setOpen, children }) {
    const { mode } = useColorScheme()

    const isDarkMode = mode === 'dark'

    return (
        <div>
            <Modal
                open={open}
                // onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box
                    sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        minWidth: 500,
                        width: '60%',
                        bgcolor: 'background.paper',
                        border: '2px solid #000',
                        boxShadow: 24,
                        p: 4,
                        color: isDarkMode ? '#f4f4f4' : '#444',
                    }}
                >
                    <div style={{ textAlign: 'right' }}>
                        <CloseIcon
                            style={{ cursor: 'pointer' }}
                            onClick={() => setOpen(false)}
                        />
                    </div>
                    {children}
                </Box>
            </Modal>
        </div>
    )
}
