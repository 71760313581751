import React from 'react'
import * as Yup from 'yup'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { Button, Container } from '@mui/material'
import AppForm from '../form/AppForm'
import AppInput from '../form/AppInput'
import SubmitButton from '../form/SubmitButton'
import Header from '../form/Header'

import { formTypeList } from '../../constants/form'
import {
    addNewPurchaseOrder,
    updatePurchaseOrder,
} from '../../store/actions/purchaseOrderAction'
import AppSelectNameId from '../form/AppSelectNameId'

const validationSchema = Yup.object().shape({
    invoiceNo: Yup.string().required().label('Invoice No'),
    po: Yup.string().required().label('PO No'),
    supplierId: Yup.string().required().label('Supplier'),
})

const PurchaseOrderForm = ({ formType, propsValue = null }) => {
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const { suppliers } = useSelector((state) => state.purchaseOrder)

    return (
        <Container>
            <Button onClick={() => navigate(-1)}>Back</Button>
            <Header
                title={`${formType === formTypeList.NEW ? 'ADD' : ''}${
                    formType === formTypeList.EDIT ? 'EDIT' : ''
                } PURCHASE ORDER`}
            />
            <AppForm
                initialValues={{
                    invoiceNo: propsValue ? propsValue.invoiceNo : '',
                    po: propsValue ? propsValue.po : '',
                    supplierId: propsValue
                        ? propsValue?.supplier?.id
                        : suppliers?.[0]?.id,
                }}
                onSubmit={(values) => {
                    const submitValues =
                        formType === formTypeList.NEW
                            ? values
                            : { ...propsValue, ...values }
                    formType === formTypeList.NEW
                        ? dispatch(addNewPurchaseOrder(submitValues, navigate))
                        : dispatch(updatePurchaseOrder(submitValues, navigate))
                }}
                validationSchema={validationSchema}
            >
                <AppSelectNameId
                    name="supplierId"
                    label="Supplier"
                    options={suppliers}
                />
                <AppInput
                    name="invoiceNo"
                    label="Invoice No"
                    type="text"
                    inputProps={{
                        maxLength: 50,
                    }}
                    required={true}
                />
                <AppInput
                    name="po"
                    label="PO No"
                    type="text"
                    inputProps={{
                        maxLength: 50,
                    }}
                    required={true}
                />
                <SubmitButton title="Submit" />
            </AppForm>
        </Container>
    )
}

export default PurchaseOrderForm
