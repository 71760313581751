import React, { useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import Layout from '../../components/Layout'
import {
    getOneProduct,
    getProductDoorColors,
} from '../../store/actions/productAction'
import { Button, Container } from '@mui/material'
import { GET_ONE_PRODUCT } from '../../store/types'
import ProductDetail from './ProductDetail'
import ProductDoorColors from '../../components/product/ProductDoorColors'

const Product = () => {
    const { id } = useParams()
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const { product, doorColors } = useSelector((state) => state.product)
    useEffect(() => {
        dispatch(getOneProduct(id))
        dispatch(getProductDoorColors())
        return () => {
            dispatch({ type: GET_ONE_PRODUCT, payload: null })
        }
    }, [dispatch, id])
    return (
        <Layout>
            {product ? (
                <>
                    <Container>
                        <Button onClick={() => navigate(-1)}>Back</Button>
                        <ProductDetail product={product} />
                        {doorColors && (
                            <ProductDoorColors doorColors={doorColors} />
                        )}
                    </Container>
                </>
            ) : null}
        </Layout>
    )
}

export default Product
