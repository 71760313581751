import React from 'react'
import { TableCell, TableRow } from '@mui/material'

const OrderItemList = ({ item, showStock }) => {
    return (
        <TableRow>
            <TableCell>{item.itemSKU}</TableCell>
            <TableCell>{item.quantity}</TableCell>
            {showStock && (
                <>
                    <TableCell>{item.stock}</TableCell>
                    <TableCell>
                        {item?.location?.map((local) => (
                            <div key={Math.random()}>
                                {local.location} ({local.warehouse.shortName})
                            </div>
                        ))}
                    </TableCell>
                </>
            )}
        </TableRow>
    )
}

export default OrderItemList
