import { useEffect, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Layout from '../../components/Layout'
import PagnationComponent from '../../components/PagnationComponent'
import { Container, Typography } from '@mui/material'
import {
    CHANGE_ORDER_SHIPMENTS_PAGE,
    SEARCH_ORDER_SHIPMENTS,
} from '../../store/types'
import BasicSearch from '../../components/form/BasicSearch'
import { getAllOrderShipments } from '../../store/actions/orderShipmentAction'
import OrderShipmentInfo from '../../components/orderShipment/OrderShipmentInfo'

const OrderShipments = () => {
    const dispatch = useDispatch()

    const { orderShipments, pages, page, count, keyword } = useSelector(
        (state) => state.orderShipment
    )

    const pageNumber = page || 1
    useEffect(() => {
        dispatch(getAllOrderShipments(keyword, pageNumber))
    }, [dispatch, keyword, pageNumber])

    const changePage = useCallback(
        (page) => {
            dispatch({ type: CHANGE_ORDER_SHIPMENTS_PAGE, payload: page })
        },
        [dispatch]
    )

    return (
        <Layout>
            <Container>
                <BasicSearch
                    displayKeyword={keyword}
                    type={SEARCH_ORDER_SHIPMENTS}
                />
                <Typography
                    variant="body2"
                    gutterBottom
                    style={{ paddingBottom: '10px', paddingTop: '10px' }}
                >
                    Count: {count}
                </Typography>
                {orderShipments && (
                    <OrderShipmentInfo orders={orderShipments} />
                )}
                <PagnationComponent
                    page={pageNumber}
                    pages={pages}
                    func={changePage}
                />
            </Container>
        </Layout>
    )
}

export default OrderShipments
