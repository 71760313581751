import React, { useMemo } from 'react'
import {
    Page,
    Text,
    View,
    Document,
    StyleSheet,
    Image,
} from '@react-pdf/renderer'
import logoList from '../../constants/logoList'
import { doorDirectionList } from '../../constants/orderType'

const CLIENT = process.env.REACT_APP_CLIENT || 'woodworth'
// Define the styles
const styles = StyleSheet.create({
    page: {
        padding: 30,
    },
    header: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginBottom: 20,
    },
    logo: {
        width: 70,
        height: 'auto',
    },
    contact: {
        textAlign: 'right',
    },
    textMiddle: {
        fontSize: CLIENT === 'woodworth' ? 10 : 14,
    },
    title: {
        textAlign: 'center',
        fontSize: 16,
        marginBottom: 10,
    },
    section: {
        marginBottom: 10,
    },
    row: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginBottom: 5,
    },
    label: {
        fontWeight: 'bold',
    },
    checkbox: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
    },
    checkboxLabel: {
        marginLeft: 5,
    },
    checkboxLabelLeft: {
        marginLeft: 10,
    },
    image: {
        width: 100,
        height: 100,
        marginBottom: 10,
    },
    smallText: {
        fontSize: 10,
    },
    largerText: {
        fontSize: 10,
        marginBottom: 5,
        // paddingVertical: 5,
    },
    checkboxPic: {
        width: 15,
        height: 15,
    },
})

const checkboxList = {
    UNCHECKED:
        'https://res.cloudinary.com/homespace/image/upload/v1718156886/logo/Screenshot_2024-06-11_at_9.47.32_PM_jcicew.png',
    CHECKED:
        'https://res.cloudinary.com/homespace/image/upload/v1718156887/logo/Screenshot_2024-06-11_at_9.47.50_PM_cczqja.png',
}

// Create the PDF document component
const PrintPDFLabel = ({ order }) => {
    const filterOrder = useMemo(() => {
        return order?.orderstyles
            ?.map((orderStyle) => {
                return {
                    ...orderStyle,
                    orderitems: orderStyle.orderitems.filter(
                        (item) => item.assemblyRequest
                    ),
                }
            })
            .filter((orderStyleNew) => orderStyleNew.orderitems.length > 0)
    }, [order])

    return (
        <Document>
            {filterOrder?.map((orderStyle) =>
                orderStyle?.orderitems?.map((orderItem) => (
                    <Page size="A6" style={styles.page} key={Math.random()}>
                        {CLIENT === 'woodworth' ? (
                            <>
                                <View style={styles.header}>
                                    <Image
                                        style={styles.logo}
                                        src={logoList?.[CLIENT]?.PDF}
                                    />
                                    <View style={styles.contact}>
                                        <Text style={styles.textMiddle}>
                                            woodworthcabinetry.com
                                        </Text>
                                        <Text style={styles.textMiddle}>
                                            9056708787
                                        </Text>
                                        <Text style={styles.textMiddle}>
                                            Unit 2, 854 Westport Crescent,
                                        </Text>
                                        <Text style={styles.textMiddle}>
                                            Mississauga, ON L5T 1N5
                                        </Text>
                                    </View>
                                </View>
                                <Text style={styles.title}>
                                    WOODWORTH CABINETRY
                                </Text>
                            </>
                        ) : (
                            <>
                                <View style={styles.header}>
                                    <Image
                                        style={styles.logo}
                                        src={logoList?.[CLIENT]?.PDF}
                                    />
                                    <View style={styles.contact}>
                                        <Text style={styles.textMiddle}>
                                            https://dw-cabinetry-dallas.com/
                                        </Text>
                                        <Text style={styles.textMiddle}>
                                            (214) 242-8975
                                        </Text>
                                        <Text style={styles.textMiddle}>
                                            10676 King William Dr
                                        </Text>
                                        <Text style={styles.textMiddle}>
                                            Dallas, TX 75220 USA
                                        </Text>
                                    </View>
                                </View>
                                <Text style={styles.title}>DW-CABINETRY</Text>
                            </>
                        )}
                        {orderItem?.productcolor?.picLink ? (
                            <Image
                                style={styles.image}
                                src={orderItem?.productcolor?.picLink}
                            />
                        ) : null}
                        <View style={styles.section}>
                            <Text style={styles.largerText}>
                                Product SKU: {orderItem?.skuNo} (
                                {orderItem?.productcolor?.product?.packageLong}X
                                {orderItem?.productcolor?.product?.packageWidth}
                                X
                                {
                                    orderItem?.productcolor?.product
                                        ?.packageHeight
                                }
                                )
                            </Text>
                            <Text style={styles.largerText}>
                                Quantity: {orderItem?.quantity}
                            </Text>
                            <Text style={styles.largerText}>
                                Color: {orderStyle?.doorcolor?.name} (
                                {orderStyle?.doorcolor?.colorCode})
                            </Text>
                            <View style={styles.checkbox}>
                                <Text style={styles.largerText}>Hinged: </Text>
                                <View style={styles.checkboxLabelLeft} />
                                <Image
                                    style={styles.checkboxPic}
                                    src={
                                        orderItem?.doorDirection ===
                                        doorDirectionList.LEFT
                                            ? checkboxList.CHECKED
                                            : checkboxList.UNCHECKED
                                    }
                                />
                                <View style={styles.checkboxLabel} />
                                <Text style={styles.largerText}>L</Text>

                                <View style={styles.checkboxLabelLeft} />
                                <Image
                                    style={styles.checkboxPic}
                                    src={
                                        orderItem?.doorDirection ===
                                        doorDirectionList.RIGHT
                                            ? checkboxList.CHECKED
                                            : checkboxList.UNCHECKED
                                    }
                                />
                                <View style={styles.checkboxLabel} />
                                <Text style={styles.largerText}>R</Text>
                            </View>
                        </View>
                        <View style={styles.section}>
                            <Text style={styles.largerText}>
                                Product State:{' '}
                            </Text>
                            <Text style={styles.largerText}>
                                Order Number: {order.poNumber}
                            </Text>
                            <Text style={styles.largerText}>
                                Delivery Date:
                            </Text>
                        </View>
                    </Page>
                ))
            )}
        </Document>
    )
}

export default PrintPDFLabel
