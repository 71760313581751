import React, { useCallback, useEffect, useState } from 'react'
import Layout from '../components/Layout'
import BasicModal from '../components/modal/BasicModal'
import { useDispatch, useSelector } from 'react-redux'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Paper from '@mui/material/Paper'
import { deleteBaseTax, getAllBaseTaxes } from '../store/actions/baseTaxAction'
import AddNewBaseTax from '../components/baseTax/AddNewBaseTax'
import { Button, Container } from '@mui/material'
import { checkUserRole } from '../common/checkUserRole'
import { roleListId, roleType } from '../config/roleList'
import EditBaseTax from '../components/baseTax/EditBaseTax'
import { DELETE_CONFIRM } from '../constants/form'

const TaxItem = ({ tax }) => {
    const dispatch = useDispatch()
    const { userInfo } = useSelector((state) => state.auth)
    const { roleList } = useSelector((state) => state.user)
    const [open, setOpen] = useState(false)

    const handleDelete = useCallback(
        (id) => {
            if (window.confirm(DELETE_CONFIRM)) {
                dispatch(deleteBaseTax(id))
            }
        },
        [dispatch]
    )
    return (
        <TableRow>
            <TableCell>{tax?.taxLabel}</TableCell>
            <TableCell>{tax?.taxRate}%</TableCell>
            <TableCell style={{ width: '250px' }}>
                <BasicModal open={open} setOpen={setOpen}>
                    <EditBaseTax setOpen={setOpen} id={tax.id} />
                </BasicModal>
                <Button
                    size="small"
                    onClick={() =>
                        checkUserRole(
                            userInfo,
                            roleList,
                            roleListId.BASE_TAX,
                            roleType.EDIT
                        )
                            ? setOpen(true)
                            : alert('Not Permit User')
                    }
                    variant="outlined"
                    color="info"
                >
                    Edit
                </Button>
                <Button
                    size="small"
                    onClick={() =>
                        checkUserRole(
                            userInfo,
                            roleList,
                            roleListId.BASE_TAX,
                            roleType.DELETE
                        )
                            ? handleDelete(tax.id)
                            : alert('Not Permit User')
                    }
                    variant="outlined"
                    color="error"
                >
                    Delete
                </Button>
            </TableCell>
        </TableRow>
    )
}

const BaseTaxes = () => {
    const dispatch = useDispatch()
    const { baseTaxes } = useSelector((state) => state.baseTax)
    const { userInfo } = useSelector((state) => state.auth)
    const { roleList } = useSelector((state) => state.user)
    const [open, setOpen] = useState(false)
    useEffect(() => {
        dispatch(getAllBaseTaxes())
    }, [dispatch])

    return (
        <Layout>
            <Container>
                <BasicModal open={open} setOpen={setOpen}>
                    <AddNewBaseTax setOpen={setOpen} />
                </BasicModal>

                <Button
                    color="info"
                    variant="outlined"
                    style={{ marginBottom: '20px' }}
                    onClick={() =>
                        checkUserRole(
                            userInfo,
                            roleList,
                            roleListId.BASE_TAX,
                            roleType.CREATE
                        )
                            ? setOpen(true)
                            : alert('Not Permit User')
                    }
                >
                    Add New Base Tax
                </Button>

                {baseTaxes?.length > 0 && (
                    <div>
                        <TableContainer component={Paper}>
                            <Table aria-label="table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Detail</TableCell>
                                        <TableCell>Tax Rate</TableCell>
                                        <TableCell
                                            style={{ width: '50px' }}
                                        ></TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {baseTaxes?.map((tax) => (
                                        <TaxItem key={tax.id} tax={tax} />
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </div>
                )}
            </Container>
        </Layout>
    )
}

export default BaseTaxes
