import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import SubmitButton from '../components/form/SubmitButton'
import AppForm from '../components/form/AppForm'
import AppInput from '../components/form/AppInput'
import * as Yup from 'yup'
import { login } from '../store/actions/authAction'
import { useDispatch, useSelector } from 'react-redux'
import { useColorScheme } from '@mui/material/styles'
import logoList from '../constants/logoList'

const CLIENT = process.env.REACT_APP_CLIENT || 'woodworth'

const validationSchema = Yup.object().shape({
    email: Yup.string().required().email().label('Email'),
    password: Yup.string()
        .required()
        .min(4, 'must over 4 characters')
        .label('Password'),
})

const Login = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const { setMode } = useColorScheme()
    const { userInfo } = useSelector((state) => state.auth)

    useEffect(() => {
        if (userInfo) {
            navigate('/')
        }
    }, [userInfo, navigate])

    useEffect(() => {
        setMode('light')
    }, [setMode])
    return (
        <div className="login-container">
            <div>
                <img
                    src={logoList?.[CLIENT]?.LOGO}
                    alt="logo"
                    className="login-logo"
                />
            </div>

            <div className="login-box">
                <h3 style={{ textAlign: 'center', padding: '20px' }}>
                    {process.env.REACT_APP_CLIENT === 'dw' ? 'D' : ''}WLINK
                    SYSTEM LOGIN
                </h3>
                <AppForm
                    initialValues={{ email: '', password: '' }}
                    onSubmit={(values) => {
                        dispatch(login(values))
                    }}
                    validationSchema={validationSchema}
                >
                    <AppInput
                        name="email"
                        label="Email"
                        type="text"
                        required={true}
                    />
                    <AppInput
                        name="password"
                        label="Password"
                        type="password"
                        required={true}
                    />
                    <SubmitButton title="Login" />
                    {/* <div className="login-bottom">
                        <div className="login-link">
                            <Link to="/register">Become a Dealer</Link>
                        </div>
                        <div>
                            <Link to="/forgetpassword">Forget password</Link>
                        </div>
                    </div> */}
                </AppForm>
            </div>
        </div>
    )
}

export default Login
