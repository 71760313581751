import { Container, TextField } from '@mui/material'
import React, { useEffect } from 'react'
import * as Yup from 'yup'
import { useDispatch } from 'react-redux'
import AppForm from '../form/AppForm'

import AppInput from '../form/AppInput'
import SubmitButton from '../form/SubmitButton'

import {
    addNewWarehouseMap,
    updateWarehouseMap,
} from '../../store/actions/warehouseAction'
import Header from '../form/Header'
import { formTypeList } from '../../constants/form'
import { useFormikContext } from 'formik'

const validationSchema = Yup.object().shape({
    aisle: Yup.string().required().min(1).max(20).label('Aisle'),
    bin: Yup.string().required().min(1).max(20).label('Bin'),
    shelf: Yup.string().required().min(1).max(20).label('Shelf'),
})

const Location = ({ name }) => {
    const { setFieldValue, values } = useFormikContext()
    useEffect(() => {
        if (values.aisle && values.bin && values.shelf) {
            const setValue = `${values.aisle}-${values.bin}-${values.shelf}`
            setFieldValue(name, setValue)
        } else {
            setFieldValue(name, '')
        }
        // eslint-disable-next-line
    }, [values, name])
    return (
        <div className="form-input">
            <TextField
                fullWidth
                readOnly
                name={name}
                type="text"
                id={name}
                label="Location"
                variant="standard"
                value={values[name]}
            />
        </div>
    )
}

const WarehouseMapForm = ({
    formType,
    setOpen,
    warehouseId,
    propsState = null,
}) => {
    const dispatch = useDispatch()
    return (
        <Container>
            <Header
                title={`${formType === formTypeList.NEW ? 'ADD' : ''}${
                    formType === formTypeList.EDIT ? 'EDIT' : ''
                } WAREHOUSE MAP`}
            />
            <AppForm
                initialValues={{
                    aisle: propsState ? propsState.aisle : '',
                    bin: propsState ? propsState.bin : '',
                    shelf: propsState ? propsState.shelf : '',
                    location: propsState ? propsState.location : '',
                }}
                onSubmit={async (values) => {
                    if (formType === formTypeList.NEW) {
                        dispatch(addNewWarehouseMap({ ...values, warehouseId }))
                    } else {
                        const updatedValues = {
                            ...propsState,
                            ...values,
                            warehouseId,
                        }
                        dispatch(updateWarehouseMap(updatedValues))
                    }
                    setOpen(false)
                }}
                validationSchema={validationSchema}
            >
                <Location name="location" />
                <AppInput
                    name="aisle"
                    label="Aisle"
                    type="text"
                    required
                    inputprops={{ maxLength: 20 }}
                />
                <AppInput
                    name="bin"
                    label="Bin"
                    type="text"
                    required
                    inputprops={{ maxLength: 20 }}
                />
                <AppInput
                    name="shelf"
                    label="Shelf"
                    type="text"
                    inputprops={{ maxLength: 20 }}
                />
                <SubmitButton title="Submit" />
            </AppForm>
        </Container>
    )
}

export default WarehouseMapForm
