import { GET_ALL_BASE_TAXES, GET_ONE_BASE_TAX } from '../types'

const initialState = {
    baseTaxes: null,
    baseTax: null,
}

const baseTaxReducer = (state = initialState, action) => {
    const { type, payload } = action
    switch (type) {
        case GET_ALL_BASE_TAXES:
            return {
                ...state,
                baseTaxes: payload,
            }
        case GET_ONE_BASE_TAX:
            return {
                ...state,
                baseTax: payload,
            }
        default:
            return state
    }
}

export default baseTaxReducer
