import React, { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import Layout from '../../components/Layout'
import { getAllPurchaseOrderSuppliers } from '../../store/actions/purchaseOrderAction'
import PurchaseOrderFromAnalyticsForm from '../../components/purchaseOrder/PurchaseOrderFromAnalyticsForm'

const AddPurchaseOrderFromAnalytics = () => {
    const location = useLocation()
    const dispatch = useDispatch()
    const { formatItems } = location.state || []
    useEffect(() => {
        dispatch(getAllPurchaseOrderSuppliers())
    }, [dispatch])

    return (
        <Layout>
            <PurchaseOrderFromAnalyticsForm purchaseOrderItems={formatItems} />
        </Layout>
    )
}

export default AddPurchaseOrderFromAnalytics
