const doorDirectionList = {
  LEFT: 'Left',
  RIGHT: 'Right',
};

export default doorDirectionList;

export const finishedEndList = {
  LEFT: 'Left',
  RIGHT: 'Right',
  BOTH: 'Both',
};
