import React, { useCallback, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Paper from '@mui/material/Paper'
import { styled } from '@mui/material/styles'
import Accordion from '@mui/material/Accordion'
import AccordionSummary from '@mui/material/AccordionSummary'
import AccordionDetails from '@mui/material/AccordionDetails'
import Typography from '@mui/material/Typography'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import EditIcon from '@mui/icons-material/Edit'
import DeleteForeverIcon from '@mui/icons-material/DeleteForever'
import Stack from '@mui/material/Stack'
import BasicModal from '../modal/BasicModal'
import EditWarehouseMap from './EditWarehouseMap'
import { roleListId, roleType } from '../../config/roleList'
import { DELETE_CONFIRM } from '../../constants/form'
import { deleteWarehouseMap } from '../../store/actions/warehouseAction'
import { checkUserRole } from '../../common/checkUserRole'

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    // textAlign: 'center',
    color: theme.palette.text.secondary,
}))

const WarehouseMapShelfList = ({ shelf, warehouseId }) => {
    const dispatch = useDispatch()
    const { userInfo } = useSelector((state) => state.auth)
    const { roleList } = useSelector((state) => state.user)
    const [editOpen, setEditOpen] = useState(false)

    const handleEdit = useCallback(() => {
        setEditOpen(true)
    }, [])

    const handleDelete = useCallback(
        (id) => {
            if (window.confirm(DELETE_CONFIRM)) {
                dispatch(deleteWarehouseMap(id, warehouseId))
            }
        },
        [dispatch, warehouseId]
    )

    return (
        <>
            <BasicModal open={editOpen} setOpen={setEditOpen}>
                <EditWarehouseMap
                    id={shelf.id}
                    warehouseId={warehouseId}
                    setOpen={setEditOpen}
                />
            </BasicModal>
            <Stack spacing={2} style={{ marginBottom: '10px' }}>
                <Item>
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                        }}
                    >
                        <div>{shelf.shelf}</div>
                        <div>
                            <EditIcon
                                onClick={() =>
                                    checkUserRole(
                                        userInfo,
                                        roleList,
                                        roleListId.WAREHOUSE_MAP,
                                        roleType.EDIT
                                    )
                                        ? handleEdit()
                                        : alert('Not Permit User')
                                }
                                style={{
                                    marginRight: '20px',
                                    cursor: 'pointer',
                                }}
                            />

                            <DeleteForeverIcon
                                onClick={() =>
                                    checkUserRole(
                                        userInfo,
                                        roleList,
                                        roleListId.WAREHOUSE_MAP,
                                        roleType.DELETE
                                    )
                                        ? handleDelete(shelf.id)
                                        : alert('Not Permit User')
                                }
                                style={{
                                    color: 'red',
                                    cursor: 'pointer',
                                    marginRight: '20px',
                                }}
                            />
                        </div>
                    </div>
                    {shelf?.items?.length > 0 && (
                        <div>
                            Items:{' '}
                            {shelf?.items
                                ?.map((sku) => sku.itemSKU)
                                ?.join(', ')}
                        </div>
                    )}
                </Item>
            </Stack>
        </>
    )
}

const WarehouseMapBinList = ({ aisle, bin, warehouseId }) => {
    const { warehouse } = useSelector((state) => state.warehouse)
    const shelfList = useMemo(() => {
        return warehouse.warehousemaps
            .filter((item) => item.aisle === aisle && item.bin === bin)
            .sort((a, b) => a.shelf - b.shelf)
    }, [aisle, bin, warehouse])
    return (
        <Accordion>
            <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
            >
                <Typography>{bin}</Typography>
            </AccordionSummary>
            <AccordionDetails>
                {shelfList?.map((shelf) => (
                    <WarehouseMapShelfList
                        key={shelf.id}
                        shelf={shelf}
                        warehouseId={warehouseId}
                    />
                ))}
            </AccordionDetails>
        </Accordion>
    )
}

const WarehouseMapList = ({ aisle, warehouseId }) => {
    const { warehouse } = useSelector((state) => state.warehouse)
    const binList = useMemo(() => {
        return [
            ...new Set(
                warehouse.warehousemaps
                    .filter((item) => item.aisle === aisle)
                    .map((item) => item.bin)
                    .sort((a, b) => a - b)
            ),
        ]
    }, [aisle, warehouse])
    return (
        <>
            <Accordion>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                >
                    <Typography>{aisle}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    {binList?.length > 0
                        ? binList.map((bin) => (
                              <WarehouseMapBinList
                                  key={bin}
                                  bin={bin}
                                  aisle={aisle}
                                  warehouseId={warehouseId}
                              />
                          ))
                        : null}
                </AccordionDetails>
            </Accordion>
        </>
    )
}

export default WarehouseMapList
