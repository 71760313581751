export const checkUserRole = (
    userInfo,
    roleList,
    requestField,
    requestRole
) => {
    return userInfo?.roles
        ?.split(',')
        ?.includes(
            roleList?.filter((role) => role.roleId === requestField)[0][
                requestRole
            ]
        )
}
